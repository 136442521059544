import * as S from './Leaderboard.styles';
import {
	FirstMedal,
	SecondMedal,
	ThirdMedal,
} from 'shared/assets/images/Decoration';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	setHeader,
	setShowNicknameModal,
	toggleAskedForNicknameHandler,
} from 'shared/store/global/globalActions';
import {
	setPeriod,
	setType,
} from 'shared/store/leaderboard/leaderboardActions';
import useWindowResize from 'shared/hooks/useWindowResize';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import moment from 'moment';
import { Col } from 'react-bootstrap';
import PickerModal from '../Modals/PickerModal/PickerModal';
import { RankDown, RankUp } from 'shared/assets/images/Decoration';
import PagesEnum from 'shared/Enum/pages';
import { PeriodTypeEnum } from 'shared/Enum/PeriodTypeEnum';
import { useQueryClient } from 'react-query';
import { useLeaderboard } from 'shared/hooks';
import { AppSpinner } from '../AppSpinner/AppSpinner';
import ErrorPage from '../ErrorPage';
import { Warnning } from 'shared/assets/images/ErrorPage';
import retryIcon from 'shared/assets/images/ErrorPage/retry.svg';
import { useNavigate, useParams } from 'react-router-dom';
import NicknameModal from '../Modals/NicknameModal/NicknameModal';

export const Leaderboard = () => {
	// hooks
	const dispatch = useDispatch();
	const queryClient = useQueryClient();
	const { league } = useParams();
	const navigate = useNavigate();
	const { isMobile } = useWindowResize();
	const { initialLeagues, axiosInstance, askedForNickname, showNicknameModal } =
		useSelector(({ global }) => global);
	const langState = useSelector(({ lang }) => lang);
	const { period, type } = useSelector(({ leaderboard }) => leaderboard);
	const pickerRef = useRef(null); //the current opened picker

	// states
	// const [showNicknameModal, setShowNicknameModal] = useState(false);
	const [selectedPicker, setSelectedPicker] = useState('Season');
	const [modalOptions, setModalOptions] = useState(null);
	const [selectedPeriod, setSelectedPeriod] = useState();
	const [periodDisplayName, setPeriodDisplayName] = useState('Season');
	const [relevantLeaderboard, setRelevantLeaderboard] = useState(null);
	const [periodState, setPeriodState] = useState(null);
	const [typeState, setTypeState] = useState(null);
	const leaderboardParams = {
		clientId: process.env.NX_CLIENT_ID,
		period: periodState,
		type: typeState,
	};

	// query hooks
	const {
		data: leaderboard,
		isLoading: leaderboardLoading,
		error,
		refetch,
	} = useLeaderboard(axiosInstance, leaderboardParams);

	// constants
	const leaderboardLang = langState?.pages?.leaderboard;
	const username = sessionStorage.getItem('username');
	const user = queryClient.getQueryData(['user', username])?.user;
	const jwt = localStorage.getItem('jwt-token');
	const leagues = queryClient.getQueryData(['leagues',jwt]);
	const pickers = leaderboardLang?.tableFilters;

	// functions
	const handlePickerChange = (pickerName) => {
		switch (pickerName) {
			case PeriodTypeEnum.MONTH:
				setModalOptions(
					relevantLeaderboard?.leaguePeriods?.months?.map((m) => ({
						displayValue: moment(m).format('MMMM'),
						value: m,
					}))
				);
				break;
			case PeriodTypeEnum.ROUND:
				setModalOptions(
					relevantLeaderboard?.leaguePeriods?.rounds?.map((r) => ({
						displayValue: `${r.name} - ${moment(r.roundStart).format('DD.MM.YYYY')}`,
						value: r.number,
					}))
				);
				break;
			default:
				return;
		}
	};

	const onModalPick = (selectedOption) => {
		setSelectedPeriod(selectedOption.value);
		setPeriodDisplayName(selectedOption.displayValue);
		setSelectedPicker(pickerRef.current.name);
	};

	const getMedalImg = (rank) => {
		switch (rank) {
			case 1:
				return FirstMedal;
			case 2:
				return SecondMedal;
			case 3:
				return ThirdMedal;
			default:
				return null;
		}
	};

	// life cycle
	useEffect(() => {
		dispatch(setType(selectedPicker?.toUpperCase()));
		if (selectedPeriod) {
			dispatch(setPeriod(selectedPeriod));
		}
	}, [dispatch, pickers, selectedPeriod, selectedPicker]);

	useEffect(() => {
		if (user && !user.nickname && askedForNickname) {
			dispatch(setShowNicknameModal(true));
			dispatch(toggleAskedForNicknameHandler(false));
		}
	}, [dispatch, askedForNickname, user]);

	useEffect(() => {
		if (leaderboard) {
			const firstLeague = leagues && Object.keys(leagues)[0];
			setRelevantLeaderboard(leaderboard[league ?? firstLeague]);
		}
	}, [leaderboard, league, leagues]);

	useEffect(() => {
		setPeriodState(period);
		setTypeState(type);
	}, [period, type]);

	useEffect(() => {
		if (pickers?.length) {
			setPeriodState(pickers?.at(-1)?.name);
			setTypeState(pickers?.at(-1)?.name);
			setSelectedPicker(pickers?.at(-1)?.name);
			setPeriodDisplayName(pickers?.at(-1)?.displayName);
		}
	}, [pickers]);

	useEffect(() => {
		// Init
		dispatch(
			setHeader({
				title: leaderboardLang?.title,
				page: PagesEnum.leaderboard,
				description: '',
				leagues: initialLeagues,
				hideBurger: true,
			})
		);
	}, [dispatch, initialLeagues, leaderboardLang?.title, pickers]);

	useLayoutEffect(() => {
		if (!league) {
			navigate(`${initialLeagues[0].to}`);
		}
	}, [initialLeagues, league, navigate]);

	// jsx
	if (leaderboardLoading || !relevantLeaderboard) {
		return <AppSpinner />;
	}

	if (error || !leaderboard) {
		return (
			<ErrorPage
				ErrorSvg={Warnning}
				title={'Page not found'}
				description={
					'The page you are looking for doesn’t exist or an other error occurred.'
				}
				btnTitle={'Try Again'}
				btnIcon={retryIcon}
				clickHandler={refetch}
			/>
		);
	}

	return (
		<>
			<S.LeaderboardContainer>
				<S.StyledRow $isMobile={isMobile}>
					<S.PickersWrapper>
						{pickers?.map(
							(picker) =>
								picker?.name && (
									<S.Picker
										key={picker.name}
										isSelected={selectedPicker === picker.name}
										onClick={() => {
											if (picker.hasPicker) {
												handlePickerChange(picker.name);
												pickerRef.current = picker;
											} else {
												setSelectedPicker(picker.name);
												setSelectedPeriod(picker.name);
												setPeriodDisplayName(picker.displayName);
											}
										}}
									>
										<span>{picker.displayName}</span>
										{picker.hasPicker && (
											<S.ArrowWrapper>
												<ArrowDropDownIcon />
											</S.ArrowWrapper>
										)}
									</S.Picker>
								)
						)}
					</S.PickersWrapper>
					{relevantLeaderboard?.selfRanking && (
						<>
							<S.MyRankTitle>{leaderboardLang?.myRankTitle}</S.MyRankTitle>
							<S.LeaderboardTable>
								<Col md={2}>
									<S.RankAndMedal>
										{relevantLeaderboard.selfRanking.ranking}
										{relevantLeaderboard.selfRanking.ranking < 3 && (
											<img
												src={getMedalImg(relevantLeaderboard.selfRanking.ranking)}
												alt="medal"
											/>
										)}
									</S.RankAndMedal>
								</Col>
								<Col md={8}>
									<S.Text>{`${relevantLeaderboard.selfRanking.User?.nickname}`}</S.Text>
								</Col>
								<Col
									md={2}
									style={{
										textAlign: 'end',
										display: 'flex',
										justifyContent: 'flex-end',
										alignItems: 'center',
									}}
								>
									<S.Text>{relevantLeaderboard.selfRanking.points} </S.Text>
									{relevantLeaderboard.selfRanking.upOrDown === 'up' ? (
										<RankUp />
									) : (
										<RankDown />
									)}
								</Col>
							</S.LeaderboardTable>
						</>
					)}

					<S.LeaderboardTable bgColor="transparent">
						<S.Members>
							{`${relevantLeaderboard?.usersCount} ${leaderboardLang?.table.sumOfMembersText}`}{' '}
						</S.Members>
						<S.Season>{periodDisplayName}</S.Season>
					</S.LeaderboardTable>

					<S.LeaderboardTable
						style={{
							borderBottomLeftRadius: 'unset',
							borderBottomRightRadius: 'unset',
						}}
					>
						<Col xs={'auto'} md={'auto'}>
							<S.GreyTitle>{leaderboardLang?.table.rankColumnTitle}</S.GreyTitle>
						</Col>
						<Col xs={7} md={10}>
							<S.GreyTitle>{leaderboardLang?.table.usernameColumnTitle}</S.GreyTitle>
						</Col>
						<Col xs={'auto'} md={'auto'}>
							<S.GreyTitle>{leaderboardLang?.table.pointsColumnTitle}</S.GreyTitle>
						</Col>
					</S.LeaderboardTable>
					<S.StyledHr />
					<S.LeaderboardTable
						style={{
							borderTopLeftRadius: 'unset',
							borderTopRightRadius: 'unset',
							flexDirection: 'column',
							marginBottom: '1.25rem',
						}}
					>
						{relevantLeaderboard?.users?.map((val, index) => (
							<S.UsersRankRow
								key={val.UserRankings[0].id}
								$isFirst={index === 0}
								$isLast={index === relevantLeaderboard?.users.length - 1}
							>
								<Col
									xs={2}
									style={{
										textAlign: isMobile ? 'center' : '',
									}}
								>
									<S.RankAndMedal>
										{val?.UserRankings[0].ranking}
										{index < 3 && (
											<img src={getMedalImg(val?.UserRankings[0].ranking)} alt="medal" />
										)}
									</S.RankAndMedal>
								</Col>
								<Col xs={7} md={8}>
									<div>{`${val?.nickname ?? 'no nickname'}`}</div>
								</Col>
								<Col
									xs={3}
									md={2}
									style={{
										textAlign: 'end',
									}}
								>
									{val?.UserRankings[0].points}
									{val?.UserRankings[0].upOrDown === 'up' ? <RankUp /> : <RankDown />}
								</Col>
							</S.UsersRankRow>
						))}
					</S.LeaderboardTable>
				</S.StyledRow>
				<PickerModal
					options={modalOptions}
					selectedOption={selectedPeriod}
					onCloseModal={() => {
						setModalOptions(null);
						pickerRef.current = null;
					}}
					onSelectOption={onModalPick}
				/>
			</S.LeaderboardContainer>
			<NicknameModal
				setIsOpen={(bool) => dispatch(setShowNicknameModal(bool))}
				isOpen={showNicknameModal}
				leagueName={league}
			/>
		</>
	);
};
