import { Check, PriorityHigh } from '@mui/icons-material';
import { TextField } from '@mui/material';
import styled from 'styled-components';
import Button from '../../Button';

export const Paper = styled.div`
	position: absolute;
	/* width: ${({ $isMobile }) =>
		$isMobile ? 'calc(100% - 1.6rem)' : 'calc(50% - 1.6rem)'}; */
	max-width: 400px;
	background: ${({ theme }) => theme.nickNameModalPalette.backgroundColor};
	box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%),
		0px 1px 14px 0px rgb(0 0 0 / 12%);
	border-radius: 15px;
	padding: 1.4rem;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-family: sans-serif;
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export const ExitBtn = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 1rem;
	right: 1rem;
	height: 1rem;
	width: 1rem;
	background-color: transparent;
	color: ${({ theme }) => theme.nickNameModalPalette.exitButtonColor};
	border: none;
`;

export const Title = styled.div`
	font-size: 1.6rem;
	color: ${({ theme }) => theme.nickNameModalPalette.titleColor};
	font-weight: 600;
`;

export const Description = styled.div`
	color: ${({ theme }) => theme.nickNameModalPalette.subtitleColor};
`;

export const TextInput = styled(TextField)`
	// label placeholder
	.MuiFormLabel-root.MuiInputLabel-root {
		color: ${({ theme }) => theme.nickNameModalPalette.input.labelTextColor};
		opacity: 0.6;
		border-radius: 1rem;
	}
	// focused Label
	.MuiFormLabel-root.MuiInputLabel-root.Mui-focused {
		color: ${({ theme }) =>
			theme.nickNameModalPalette.input.labelFocusedTextColor};
	}

	& .MuiOutlinedInput-root {
		&.Mui-focused fieldset {
			border-radius: 1rem;
			border-color: ${({ theme }) =>
				theme.nickNameModalPalette.input.inputFocusedBorderColor};
		}
	}
	// unfocused Colors
	fieldset {
		border-radius: 1rem;
		border-color: ${({ theme }) =>
			theme.nickNameModalPalette.input.inputBorderColor};
	}
	// typing text color
	.MuiInputBase-input.MuiOutlinedInput-input {
		color: ${({ theme }) => theme.nickNameModalPalette.input.inputTextColor};
	}

	.MuiOutlinedInput-input:-webkit-autofill {
		border-radius: 1rem;
	}

	// fieldset focused
	.MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
		.MuiOutlinedInput-notchedOutline {
		border-color: ${({ theme }) =>
			theme.nickNameModalPalette.input.inputFocusedBorderColor};
	}
`;

export const StatusBoxWrapper = styled.div``;

export const StatusBoxContent = styled.div`
	color: ${({ error, theme }) =>
		error
			? theme.red
			: theme.nickNameModalPalette.approvedNicknameIndicator.approvedTextColor};
`;

export const ApprovedIcon = styled(Check)`
	border-radius: 50%;
	background-color: ${({ theme }) =>
		theme.nickNameModalPalette.approvedNicknameIndicator
			.approvedIconBackgroundColor};
	color: ${({ theme }) =>
		theme.nickNameModalPalette.approvedNicknameIndicator.approvedIconColor};
	margin-right: 0.6rem;
	height: 1.5rem;
	width: 1.5rem;
	padding: 0.1rem;
`;

export const ErrorIcon = styled(PriorityHigh)`
	border-radius: 50%;
	background-color: #e00000;
	color: white;
	margin-right: 0.6rem;
	height: 1.5rem;
	width: 1.5rem;
	padding: 0.1rem;
`;

export const NoticeBox = styled.div`
	color: ${({ theme }) => theme.nickNameModalPalette.warningTextColor};
	font-size: 0.9rem;
	line-height: 1.2rem;
`;

export const SubmitBtn = styled(Button)`
	background-color: ${({ theme }) =>
		theme.nickNameModalPalette.submitButton.backgroundColor.active};
	border: none;
	color: ${({ theme }) =>
		theme.nickNameModalPalette.submitButton.textColor.active};
	border-radius: 20px;
	margin: 0 auto;
	display: block;
	width: 15.6rem;
	height: 3rem;
	max-width: calc(100% - 1rem);
	&:disabled {
		background-color: ${({ theme }) =>
			theme.nickNameModalPalette.submitButton.backgroundColor.inActive};
		color: ${({ theme }) =>
			theme.nickNameModalPalette.submitButton.textColor.inActive};
	}
`;
