import { Check, PriorityHigh } from '@mui/icons-material';
import { TextField } from '@mui/material';
import styled from 'styled-components';
import Button from '../../Button';

export const Paper = styled.div`
	position: absolute;
	/* width: ${({ $isMobile }) =>
		$isMobile ? 'calc(100% - 1.6rem)' : 'calc(50% - 1.6rem)'}; */
	/* max-width: 50%; */
	/* height: 50%; */
	/* width: 50%; */
	max-width: 400px;
	background: linear-gradient(
		224.23deg,
		#dccf93 0%,
		#ecdfa3 18.84%,
		#f4e8b0 40.95%,
		#d1c689 65.43%,
		#b3a76c 85.93%,
		#968b51 100.88%
	);
	box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%),
		0px 1px 14px 0px rgb(0 0 0 / 12%);
	border-radius: 15px;
	padding: 1.4rem;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-family: sans-serif;
	display: flex;
	flex-direction: column;
	gap: 20px;
	text-align: center;
`;

export const ExitBtn = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 1rem;
	right: 1rem;
	height: 1rem;
	width: 1rem;
	background-color: transparent;
	color: #000000;
	border: none;
`;

export const Title = styled.div`
	font-size: 1.6rem;
	color: #000000;
	font-weight: 600;
`;

export const Description = styled.div`
	color: #000000;
`;

export const TextInput = styled(TextField)`
	// label placeholder
	.MuiFormLabel-root.MuiInputLabel-root {
		color: #000000;
		opacity: 0.6;
		border-radius: 1rem;
	}
	// focused Label
	.MuiFormLabel-root.MuiInputLabel-root.Mui-focused {
		color: #000000;
	}

	& .MuiOutlinedInput-root {
		&.Mui-focused fieldset {
			border-radius: 1rem;
			border-color: #000000;
		}
	}
	// unfocused Colors
	fieldset {
		border-radius: 1rem;
		border-color: #000000;
	}
	// typing text color
	.MuiInputBase-input.MuiOutlinedInput-input {
		color: #000000;
	}

	.MuiOutlinedInput-input:-webkit-autofill {
		border-radius: 1rem;
	}

	// fieldset focused
	.MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
		.MuiOutlinedInput-notchedOutline {
		border-color: #000000;
	}
`;

export const StatusBoxWrapper = styled.div``;

export const StatusBoxContent = styled.div`
	color: ${({ error, theme }) => (error ? '#AF1C1C' : '#40AC25')};
`;

export const ApprovedIcon = styled(Check)`
	border-radius: 50%;
	background-color: #40ac25;
	color: #ffffff;
	margin-right: 0.6rem;
	height: 1.5rem;
	width: 1.5rem;
	padding: 0.1rem;
`;

export const ErrorIcon = styled(PriorityHigh)`
	border-radius: 50%;
	background-color: #e00000;
	color: white;
	margin-right: 0.6rem;
	height: 1.5rem;
	width: 1.5rem;
	padding: 0.1rem;
`;

export const NoticeBox = styled.div`
	color: #000000;
	font-size: 0.9rem;
	line-height: 1.2rem;
`;

export const SubmitBtn = styled(Button)`
	background-color: #ffffff;
	border: none;
	color: #000000;
	border-radius: 20px;
	margin: 0 auto;
	display: block;
	width: 15.6rem;
	height: 3rem;
	max-width: calc(100% - 1rem);
	&:disabled {
		background-color: #ffffff4d;
		color: #a89d62;
	}
`;
