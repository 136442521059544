const PagesEnum = {
	welcome: 'welcome',
	signUp: 'signup',
	login: 'login',
	predictions: 'predictions',
	results: 'results',
	bracket: 'bracket',
	termsAndCond: 'termsAndCond',
	howToPlay: 'howToPlay',
	parlays: 'parlays',
	leaderboard: 'leaderboard',
	errorPage: 'error',
};

module.exports = PagesEnum;
