export const StyledSvgIcon = (props) => {
	const {
		isActive,
		SvgIcon,
		defaultColor = '#fff',
		activeColor,
		stroke,
	} = props;
	return stroke ? (
		<SvgIcon stroke={isActive ? activeColor : defaultColor} />
	) : (
		<SvgIcon fill={isActive ? activeColor : defaultColor} />
	);
};

export default StyledSvgIcon;
