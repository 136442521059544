import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import Vs from '../../../../../shared/assets/images/Decoration/vs.svg';

export const MatchContainer = styled(Col)`
	margin-bottom: 1.5rem;
	padding: 0 0.5rem;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

export const Match = styled.div`
	min-height: 8.75rem;
	width: ${({ isMobile }) => (isMobile ? 'auto' : '100%')};
	${({ isMobile }) => {
		return { minWidth: '21.25rem' };
	}};
	height: 100%;
	overflow: hidden;
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: ${({ theme }) => theme.appSectionsBg};
	border-radius: 0.625rem;
	padding: unset;
	margin: unset;
	opacity: ${(props) => (props.postponed ? '0.7' : '1')};
`;

export const BetControllerContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	gap: 1rem;
	margin: 0 5px;
`;

export const VsContainer = styled.div`
	height: 6.5rem;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

export const VsTextWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	gap: 2.5rem;
`;

export const VsText = styled.div`
	font-size: 1rem;
	font-weight: bold;
	line-height: 1.25rem;
	color: ${({ theme }) => theme.vsContainerPalette.vsColor};

	&:after {
		position: absolute;
		top: 0;
		bottom: 0;
		background-image: url(${Vs});
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
	}
`;

export const VsDecoration = styled.img`
	position: absolute;
`;

export const Date = styled.div`
	font-size: 0.875em;
	font-weight: 400;
	color: ${({ theme }) => theme.mainTypography};
	opacity: ${({ disableOpacity }) => (disableOpacity ? 1 : 0.6)};
`;
