import styled from 'styled-components';

export const Postponed = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	z-index: 1020;
	right: 0;
	left: 0;
	bottom: 1.5rem;
`;

export const Square = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-bottom: 1rem;
	margin-top: 1rem;
	padding: 0 1rem;
	background: ${({ backgroundColor }) =>
		backgroundColor ? backgroundColor : 'rgba(224, 0, 0, 0.05)'};
	border: 1px solid
		${({ borderColor }) => (borderColor ? borderColor : '#e00000')};
	transform: rotate(-8.86deg);
`;

export const Text = styled.div`
	font-size: 1em;
	font-weight: 800;
	color: ${({ color }) => (color ? color : '#e00000')};
	text-transform: uppercase;
	@media all and (min-width: 1000px) {
		font-size: 1.5em;
	}
`;
