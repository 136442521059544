import * as S from './RadioBtns.styles';
import { Controller, useFormState } from 'react-hook-form';

const RadioBtns = ({ name, label, type, rule, radioInputs }) => {
	const formState = useFormState();
	return (
		<Controller
			name={name}
			render={({ field: { onChange, onBlur, value, ref } }) => (
				<S.RadioBtns
					onChange={onChange}
					onBlur={onBlur}
					defaultValue={'female'}
					value={value}
					ref={ref}
					label={label}
					type={type}
					helpertext={formState.errors[name]?.message}
				>
					{radioInputs.map((radioInput) => (
						<S.RadioBtnsLabel
							key={radioInput}
							control={<S.RadioBtn required={true} value={radioInput} />}
							label={radioInput}
							helpertext={formState.errors[name]?.message}
						/>
					))}
				</S.RadioBtns>
			)}
		/>
	);
};

export default RadioBtns;
