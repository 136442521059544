import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	text-align: center;
`;

export const Ul = styled.ul`
	height: inherit;
	text-align: center;
`;
