import { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setHeader } from 'shared/store/global/globalActions';
import * as S from './HowToPlayWrapper.styles';

export const HowToPlayWrapper = ({ ItemsComponent, HowToPlayTitle }) => {
	const dispatch = useDispatch();
	useLayoutEffect(() => {
		dispatch(setHeader({ hideHeader: true }));
	}, [dispatch]);
	return (
		<S.Wrapper>
			<S.Title>{HowToPlayTitle ? HowToPlayTitle : 'How To Play'}</S.Title>
			<ItemsComponent />
		</S.Wrapper>
	);
};

export default HowToPlayWrapper;
