import styled, { createGlobalStyle } from 'styled-components';
import {
	TGlobalStyle,
	TAppContainer,
	THeader,
	TSideNav,
	TMain,
} from '../types/app';

export const GlobalStyle = createGlobalStyle<TGlobalStyle>`
@import url('normalize.css');

html {
	min-height: 100%;
	min-width: 100%;
	box-sizing: border-box;
}

body {
	font-family: ${({ appFontFamily }) => (appFontFamily ? appFontFamily : '')};
	overflow-x: hidden;
	margin: unset;
}

.app {
	width: 100%;
}

/* Smooth Scroll  */

/* width */
::-webkit-scrollbar {
	width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #1e1e1e;
}

::-webkit-scrollbar-button {
	display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #bcb4b4;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #bcb4b4;
}
`;

export const GridContainer = styled.div<TAppContainer>`
	height: ${({ screenHeight }) => `${screenHeight}px`};
	display: grid;
	grid-template-columns: 6.875rem 1fr;
	grid-template-rows: auto 1fr;
	grid-template-areas:
		'sidenav header'
		'sidenav main';
	background-color: ${({ theme }) => theme.appBg};
	font-family: ${({ appFontFamily }) => (appFontFamily ? appFontFamily : '')};
`;

export const FlexContainer = styled.div<TAppContainer>`
	font-family: ${({ appFontFamily }) => (appFontFamily ? appFontFamily : '')};
	height: ${({ screenHeight }) => `${screenHeight}px`};
	display: flex;
	flex-direction: column;
	background-color: ${({ theme }) => theme.appBg};
`;

export const Header = styled.div<THeader>`
	z-index: 1300;
	${(props) =>
		!props.$isMobile
			? {
					'grid-area': 'header',
			  }
			: {
					position: 'sticky',
					top: 0,
					width: '100%',
			  }};
`;

export const SideNav = styled.div<TSideNav>`
	${(props) =>
		!props.$isMobile && {
			'grid-area': 'sidenav',
		}}
`;

export const Main = styled.div<TMain>`
	display: flex;
	flex: 1;
	background-color: ${({ theme }) => theme.appBg};
	overflow-x: hidden;
	scroll-behavior: smooth;
	padding: ${({ isHeader, isBracket }) => {
		if (isBracket) {
			return 'unset';
		}
		return isHeader ? '1rem 0' : 'unset';
	}};
`;

export const Footer = styled.div`
	z-index: 1010;
	position: sticky;
	bottom: 0;
`;
