import useWindowResize from 'shared/hooks/useWindowResize';
import * as S from './Share.styles';
import MobileShare from './MobileShare';
import DesktopShare from './DesktopShare';
import { Platforms } from 'shared/utils/platform-share-handler';

export const Share = (props) => {
const { isMobile } = useWindowResize();
	return isMobile ? (
		<MobileShare platforms={Platforms} {...props} />
	) : (
		<DesktopShare platforms={Platforms} {...props} />
	);
};
