import { produce } from 'immer';
import { SET_LANGUAGE } from './langActions';

type TInitialState = {
	[key: string]: string;
};

const initialState = {}

const langReducer = produce((draft: TInitialState = initialState, action) => {
	// console.log('Dispatched: ', action.type);
	// console.log('Payload:', action.payload);
	switch (action.type) {
		case SET_LANGUAGE:
			draft = action.payload;
			return draft;
		default:
			return draft;
	}
});

export default langReducer;
