import * as S from './CampaignLeague.styles';
import moment from 'moment';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import PagesEnum from 'shared/Enum/pages';
import {
	setHeader,
	setSavedPredictionsHandler,
	toggleHasRoundStarterHandler,
} from 'shared/store/global/globalActions';
import Matches from '../Matches';
import { AppSpinner } from '../AppSpinner/AppSpinner';
import { useLeagues } from 'shared/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import ErrorPage from '../ErrorPage';
import { Warnning } from 'shared/assets/images/ErrorPage';
import retryIcon from 'shared/assets/images/ErrorPage/retry.svg';

export const CampaignLeague = ({
	axios,
	hidePredictionCounterOnMobile,
	disableOpacity,
	submitBgColor,
	submitTextColor,
	disableSubmitBtnBorder
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { league } = useParams();
	const queryClient = useQueryClient();
	const { initialLeagues, axiosInstance } = useSelector(({ global }) => global);
	const langState = useSelector(({ lang }) => lang);
	const username = sessionStorage.getItem('username');
	const jwt = localStorage.getItem('jwt-token');
	const {
		data: leagues,
		error,
		isFetching,
	} = queryClient.getQueryState(['leagues', jwt]);
	// const {
	// 	data: leagues,
	// 	isStale,
	// 	isLoading: leaguesLoading,
	// 	error,
	// 	refetch,
	// } = useLeagues(axiosInstance, process.env.NX_CLIENT_ID);

	const headerDescription = langState?.components?.header?.description;

	const [relevantRound, setRelevantRound] = useState(null);

	useEffect(() => {
		if (leagues) {
			const fixLeagueParam = Object.keys(leagues).filter(
				(l) => league?.includes(l) || l?.includes(league)
			);
			const firstLeague = Object.keys(leagues)[0];
			const relevantLeague = fixLeagueParam?.length
				? fixLeagueParam[0]
				: firstLeague;
			setRelevantRound(leagues[relevantLeague]?.relevantRound);
			navigate(relevantLeague);
		}
	}, [league, leagues, navigate]);

	// const { relevantRound } = round;

	// set initially saved predictions
	useEffect(() => {
		const userPredictions = relevantRound?.submission?.matchPredictions.map(
			({ matchId, home_score, away_score }) => ({
				matchId,
				home_score,
				away_score,
			})
		);
		if (league && userPredictions?.length) {
			dispatch(setSavedPredictionsHandler(league, userPredictions));
		}
	}, [dispatch, league, relevantRound?.submission?.matchPredictions]);

	// set initially header & hasRoundStarted
	useEffect(() => {
		if (relevantRound) {
			const predictionsHeader = {
				title: relevantRound.round.name,
				page: PagesEnum.predictions,
				description: headerDescription,
			};
			if (initialLeagues?.length) {
				predictionsHeader.leagues = initialLeagues;
			}
			dispatch(setHeader(predictionsHeader));
			const hasStarted = Boolean(
				moment() > moment(relevantRound.round.roundStart)
			);
			dispatch(toggleHasRoundStarterHandler(hasStarted));
		}
	}, [dispatch, headerDescription, initialLeagues, relevantRound]);

	useEffect(() => {
		if (!league && initialLeagues?.length) {
			navigate(`${initialLeagues[0].to}`);
		}
	}, [initialLeagues, league, navigate]);

	if (isFetching || !relevantRound) {
		return <AppSpinner />;
	}

	if (error || !leagues) {
		return (
			<ErrorPage
				ErrorSvg={Warnning}
				title={'Page not found'}
				description={
					'The page you are looking for doesn’t exist or an other error occurred.'
				}
				btnTitle={'Try Again'}
				btnIcon={retryIcon}
				// clickHandler={refetch}
				clickHandler={queryClient.refetchQueries(['leagues', username])}
			/>
		);
	}

	// TODO: Error page from Yulia
	// if (!relevantRound) {
	// 	if (isStale) {
	// 		return (
	// 			<div>
	// 				<div>Something went wrong, try again later</div>
	// 				<button
	// 					onClick={async () => {
	// 						await queryClient.refetchQueries(['leagues']);
	// 					}}
	// 				>
	// 					Refetch
	// 				</button>
	// 			</div>
	// 		);
	// 	}
	// 	// queryClient.refetchQueries(['leagues'], { stale: true });
	// 	return <AppSpinner />;
	// }

	return (
		<Matches
			leagueName={league || Object.keys(leagues)[0]}
			relevantRound={relevantRound}
			userPredictions={relevantRound?.submission?.matchPredictions}
			hidePredictionCounterOnMobile={hidePredictionCounterOnMobile}
			disableOpacity={disableOpacity}
			submitBgColor={submitBgColor}
			submitTextColor={submitTextColor}
			disableSubmitBtnBorder={disableSubmitBtnBorder}
		/>
	);
};
export default CampaignLeague;
