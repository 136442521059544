import { AxiosInstance } from 'axios';
import { useMutation } from 'react-query';

type TBody = {
  outcomes: Array<String>
};

type TParams = {
	axiosInstance?: AxiosInstance;
	body?: TBody;
	betslipUrl: string;
};

export const fetchBetslipUrl = async (
  params: TParams
) => {
  try {
    const { axiosInstance, body, betslipUrl } = params;
    return await axiosInstance?.post(`/api/${betslipUrl}`, body);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const useMutateBetslip = () => {
  return useMutation(fetchBetslipUrl, {
    mutationKey: 'mutateBetslip'
  });
};

export default useMutateBetslip;
