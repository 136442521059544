import { AxiosInstance } from 'axios';
import moment from 'moment';
import { useMutation, useQueryClient } from 'react-query';

type TParams = {
  axiosInstance: AxiosInstance,
  campaignRounds: Array<object>
};

export const getOdds = async (params: TParams) => {
  try {
    const { axiosInstance, campaignRounds } = params;
    if (!axiosInstance) return;
    const { data } = await axiosInstance.post(`/api/get-client-odds-playtech-demo`, { campaignRounds });
    return data
  } catch (err) {
    console.log(err);
    throw err;
  }
}

const useMutateOdds = () => {
  const queryClient = useQueryClient();
  return useMutation(getOdds, {
    mutationKey: 'mutateOdds',
    onError: (err) => console.error("[useMutateOdds]: fetch failed ", err),
    // TODO: change any's
    onSuccess: (data: any) => {
      if (data) {
        for (const league in data) {
          if (data[league]?.length) {
            data[league] = data[league].filter(
              (odd: { match_start: moment.MomentInput; markets: any[]; }) =>
                moment(odd.match_start).isAfter(moment()) &&
                !odd.markets.some((market: { possibleOutcomes: string | any[]; }) => market.possibleOutcomes.length <= 0)
            )
              .sort((a: { match_start: string | number | Date; }, b: { match_start: string | number | Date; }) => new Date(a.match_start).getTime() - new Date(b.match_start).getTime());
          }
        }
        queryClient.setQueryData('odds', data)
      }
    }
  })
};

export default useMutateOdds;
