import * as S from './SocialIcons.styles';
import { platformShareHandler } from 'shared/utils/platform-share-handler';

const SocialIcons = ({ payload, platforms }) => {
	
	return (
		<S.Container>
			{platforms.map((platform, index) => (
				<div key={index}>
					<S.IconsWrapper
						onClick={() => {
							payload
								? platformShareHandler(platform.tag, payload)
								: platformShareHandler(
										platform.tag,
										'https://demo.football.hook-apps.com'
								  );
						}}
					>
						<S.Icon src={platform.icon} alt={platform.name} />
					</S.IconsWrapper>
					<S.Text>{platform.name}</S.Text>
				</div>
			))}
		</S.Container>
	);
};

export default SocialIcons;
