import styled from 'styled-components';

export const Wrapper = styled.div`
	flex: 1;
	margin: 1rem;
`;

export const Title = styled.h1`
color: ${({theme})=>theme.secondaryTypography};
	width: 100%;
	text-align: center;
`;
