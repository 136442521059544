import * as S from './MatchIndicator.styles';
import SuccessPointsEnum from '../../../../../shared/Enum/success-points';
import { useTheme } from 'styled-components';

const MatchIndicator = ({
  texts,
  points,
}) => {
  const isExact = points === SuccessPointsEnum.CorrectScore;
	const theme = useTheme()
  return (
    <S.MatchIndicator isExact={isExact}>
      <div style={{ color: theme.matchIndicator.textColor.pointsNum, fontSize: '1.2rem', fontWeight: 600 }}>{points}</div>
      <div style={{ color: theme.matchIndicator.textColor.pointsText, fontSize: '.5rem', fontWeight: 200 }}>{texts.title}</div>
      <div style={{ color: theme.matchIndicator.textColor.correctText, fontSize: '.6rem', fontWeight: 400 }}>
        <span>{isExact ? texts.exact : texts.correct}</span>
      </div>
    </S.MatchIndicator>
  );
};

export default MatchIndicator;
