import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PagesEnum from 'shared/Enum/pages';
import { useMutateRegister } from 'shared/hooks';
import { updateHeader } from 'shared/store/global/globalActions';
import Form from '../Form';
import SignUpForm from '../Form/forms/SignUpForm';
import * as S from './SignUpScreen.styles';
import ReactGA from 'react-ga';

export const SignUpScreen = ({ isMobile, clientLogo }) => {
	//hooks
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { mutateAsync: mutateRegister } = useMutateRegister();
	const { axiosInstance, device } = useSelector(({ global }) => global);

	// states
	const [errorMessage, setErrorMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	//constans
	const signupFormDefaultValues = {
		firstName: '',
		lastName: '',
		email: '',
		password: '',
	};

	//functions
	const onSubmit = async (formData) => {
		setIsLoading(true);
		const data = await mutateRegister({
			axiosInstance,
			body: {
				formData,
			},
		});
		if (typeof data === 'string') {
			//means the response returned with error message
			let errMsg = data;
			if (data === 'password invalid') {
				errMsg = (
					<div style={{ textAlign: 'start', whiteSpace: 'nowrap' }}>
						* Password must contain at least 6 chars <br />
						* Password must contain at least 1 Uppercase char
						<br />
						* Password must contain at least 1 Lowercase char
						<br />* Password must contain numbers
					</div>
				);
			}
			setErrorMessage(errMsg);
			setIsLoading(false);
			return;
		}
		if (data?.data?.message) {
			// ReactGA.event({
			// 	category: `Registration Success - ${device}`,
			// 	action: `${formData?.email} - Registered`,
			// });
			navigate(`/${PagesEnum.login}`);
			setIsLoading(false);
		}
	};

	// Life cycle
	useEffect(() => {
		dispatch(updateHeader({ hideHeader: true }));
	}, [dispatch]);

	//jsx
	return (
		<S.Container>
			<S.Header>
				<S.Logo isMobile={isMobile} src={clientLogo} />
				<S.Description>Sign-Up!</S.Description>
			</S.Header>
			<Form handleSubmit={onSubmit} formDefaultValues={signupFormDefaultValues}>
				<SignUpForm isLoading={isLoading} error={errorMessage} />
			</Form>
		</S.Container>
	);
};

export default SignUpScreen;
