import { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as S from './MobileSideNavWrapper.styles';
import { toggleSideNavHandler } from 'shared/store/global/globalActions';

const MobileSideNavWrapper = ({
	headerRef,
	MobileSideNavComponent,
	CustomWrapper,
	isSticky,
	headerHeight,
}) => {
	const [navHeight, setNavHeight] = useState(null);
	const [isShareModalVisible, setIsShareModalVisible] = useState(false);
	const sideNavRef = useRef(null);

	const toggleSideNav = useSelector((state) => state.global.sideNavToggle);

	const dispatch = useDispatch();

	const Wrapper = CustomWrapper ? CustomWrapper : S.MobileSideNavWrapper;

	useEffect(() => {
		if (headerRef && headerRef.current) {
			setNavHeight(headerRef.current.clientHeight);
		}
	}, [headerRef]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (sideNavRef.current && !sideNavRef.current.contains(event.target)) {
				dispatch(toggleSideNavHandler(false));
			}
		};
		document.addEventListener('click', handleClickOutside);

		if (isShareModalVisible) {
			document.removeEventListener('click', handleClickOutside);
		}
		// Bind the event listener
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('click', handleClickOutside);
		};
	}, [dispatch, sideNavRef, isShareModalVisible]);

	const toggleShareModalHandler = () => {
		setIsShareModalVisible((prevState) => !prevState);
	};

	return (
		<Wrapper
			headerHeight={headerHeight}
			isSticky={isSticky}
			ref={sideNavRef}
			toggle={toggleSideNav}
			navHeight={navHeight}
		>
			<S.Ul className="navbar-nav">
				<MobileSideNavComponent
					showShare={isShareModalVisible}
					toggleShowShare={toggleShareModalHandler}
				/>
			</S.Ul>
		</Wrapper>
	);
};

export default MobileSideNavWrapper;
