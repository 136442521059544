import {
  SET_CAMPAIGN,
  SET_LEADERBOARD,
  SET_PERIOD,
  SET_TYPE,
} from './leaderboardActions';
import { produce } from 'immer';

type TInitialState = {
  campaignId?: number;
  period: string;
  type: string;
};

const initialState: TInitialState = {
  period: 'SEASON',
  type: 'SEASON',
};

const leaderboardReducer = produce(
  (draft: TInitialState = initialState, action) => {
    // console.log('Dispatched: ', action.type);
    // console.log('Payload:', action.payload);
    switch (action.type) {
      case SET_LEADERBOARD:
        draft = action.payload;
        return draft;
      case SET_TYPE:
        draft.type = action.payload;
        return draft;
      case SET_PERIOD:
        draft.period = action.payload;
        return draft;
      case SET_CAMPAIGN:
        draft.campaignId = action.payload;
        return draft;
      default:
        return draft;
    }
  }
);

export default leaderboardReducer;