import styled from 'styled-components';

export const HowToPlay = styled.div`
  text-align: center;
  /* margin: auto; */
  font-size: 1rem;
  /* margin-top: 1.5em; */
  color: ${({theme})=> theme.desktopHeader.howtoplayLink };
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
