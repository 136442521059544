import styled from 'styled-components';

export const MobileSideNavWrapper = styled.div`
	display: flex;
	justify-content: center;
	transition: ${(props) =>
		props.toggle ? 'all 400ms ease-out' : 'all 400ms ease-in'};
	position: absolute;
	background-color: ${({theme})=> theme.desktopNavBg};
	left: ${(props) => (props.toggle ? '0' : '-6rem')};
	bottom: 0;
	top: 0;
	box-shadow: 5px 0px 10px rgba(180, 180, 180, 0.25);
	margin-top: -30;
	width: 6rem;
	visibility: ${(props) => (props.toggle ? 'visible' : 'hidden')};
	z-index: 1000;
`;

export const Ul = styled.ul`
	position: fixed;
	display: flex;
	justify-content: center;
	text-align: center;
	margin-top: 1rem;
`;
