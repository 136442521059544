import { SET_ROUND_ID, SET_SUBMISSION_ID } from './parlaysActions';
import { produce } from 'immer';

type TInitialState = {
	submissionId: number | null;
	roundId: number | null;
};

const initialState: TInitialState = {
	submissionId: null,
	roundId: null,
};

const leaderboardReducer = produce(
	(draft: TInitialState = initialState, action) => {
		// console.log('Dispatched: ', action.type);
		// console.log('Payload:', action.payload);
		switch (action.type) {
			case SET_ROUND_ID:
				draft.roundId = action.payload;
				return draft;
			case SET_SUBMISSION_ID:
				draft.submissionId = action.payload;
				return draft;
			default:
				return draft;
		}
	}
);

export default leaderboardReducer;
