import { AxiosInstance } from 'axios';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { setUserHandler } from 'shared/store/global/globalActions';

export const fetchUserProfile = async (axiosInstance: AxiosInstance) => {
	try {
		const { data } = await axiosInstance.get('/auth/profile');
		return data;
	} catch (err) {
		console.log('fetchUserProfile', err);
		throw err;
	}
};

const useProfile = (axiosInstance: AxiosInstance, username: string) => {
	const dispatch = useDispatch();

	return useQuery(['user', username], () => fetchUserProfile(axiosInstance), {
		retry: 1,
		enabled: Boolean(username && axiosInstance),
		onError: (err) => console.error('[useProfile]: fetch failed ', err),
		onSuccess: (data) => {
			dispatch(setUserHandler(data.user));
		},
	});
};

export default useProfile;