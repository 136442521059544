import { Backdrop, Fade, Modal } from '@mui/material';
import StepperRegisteation from '../../../Form/forms/StepperRegisteation';
import ReactPortal from '../../../ReactPortal/ReactPortal';
import * as S from './RegisterModal.styles';
import Form from '../../../Form';
import { useState } from 'react';
import AuthModalHeader from '../AuthModalHeader';
import AuthModalFooter from '../AuthModalFooter';
import { useTheme } from 'styled-components';
import ReactGA from 'react-ga';
import {
	useCheckUsername,
	useCreateMember,
	useLoginMember,
} from 'shared/hooks/delaSport';
import { useSelector } from 'react-redux';
import { useAuth } from 'shared/hooks';
import { getDevice } from 'shared/utils/getDevice';
const registerationInputs = {
	email: '',
	userName: '',
	password: '',
	gender: '',
	firstName: '',
	lastName: '',
};

export const RegisterModal = ({ isOpen, setIsOpen, onLeave, texts }) => {
	const theme = useTheme();
	const { axiosInstance } = useSelector(({ global }) => global);

	// states
	const [errorMessage, setErrorMessage] = useState(null);
	const [activeStep, setActiveStep] = useState(0);
	const [successUsername, setSuccessUsername] = useState(null);

	const { mutateAsync: mutateCreateMember, isLoading } = useCreateMember();
	const { mutateAsync: mutateLoginMember, isLoading: loginLoading } =
		useLoginMember();
	const { mutateAsync: mutateCheckUsername, isLoading: usernameLoading } =
		useCheckUsername();
	const { isLoading: authLoading, data: authData } = useAuth(
		axiosInstance,
		process.env.NX_serverKey,
		successUsername,
		process.env.NX_originServer
	);

	const firstStepValidationHandler = (formData) => {
		return !formData?.email || !formData?.userName || !formData?.password;

		//!some validation here
	};

	const onFooterBtnClick = () => {
		return;
	};

	const handleNext = () => {
		setActiveStep((activeStep) => activeStep + 1);
	};

	const handleBack = () => {
		setActiveStep((activeStep) => activeStep - 1);
	};

	const checkUsernameAvailable = async (formData) => {
		try {
			setErrorMessage(null);
			const { data } = await mutateCheckUsername({
				axiosInstance,
				body: {
					formData,
				},
			});
			if (data?._status === 'success') {
				if (data?.payload?.available) {
					setErrorMessage(null);
					handleNext();
				} else {
					setErrorMessage('Username is not available');
				}
			} else {
				setErrorMessage(data?.payload?._message);
			}
		} catch (error) {
			console.log('checkUsernameAvailable error', error);
		}
	};

	const createMember = async (formData) => {
		try {
			const regex = new RegExp('\\D');
			if (!regex.test(formData?.cellPhone)) {
				return 'error';
			}
			setErrorMessage(null);
			const { data } = await mutateCreateMember({
				axiosInstance,
				body: {
					formData,
				},
			});
			if (data?._status === 'success') {
				if (JSON.parse(process.env.NX_GA)) {
					const device = getDevice();
					ReactGA.event({
						category: `Registration Success - ${device}`,
						action: `${formData?.email || formData?.userName} - Registered`,
					});
				}
				setErrorMessage(null);
				const { data: loginData } = await mutateLoginMember({
					axiosInstance,
					body: {
						formData,
					},
				});
				if (loginData?._status === 'success') {
					sessionStorage.setItem('username', formData.userName);
					setSuccessUsername(formData.userName);
					handleNext();
				} else {
					setSuccessUsername(null);
				}
			} else {
				setErrorMessage(data?.payload?._message);
				if (
					data?.payload?._message.includes('email') ||
					data?.payload?._message.includes('username')
				) {
					handleBack();
				}
			}
		} catch (error) {
			console.log('createMember error', error);
		}
	};

	const handleSubmit = async (formData) => {
		switch (+activeStep) {
			case 0: {
				await checkUsernameAvailable(formData);
				break;
			}
			case 1: {
				await createMember(formData);
				break;
			}
			case 2: {
				handleNext();
				break;
			}
			default:
				break;
		}
	};

	const handleError = (err) => {
		console.log(err);
	};

	return (
		<ReactPortal wrapperId={'register-modal'}>
			<Modal
				disablePortal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					overflowY: 'scroll',
				}}
				open={isOpen}
				onClose={() => setIsOpen(false)}
			>
				<Fade in={isOpen}>
					<S.Paper>
						<AuthModalHeader
							HeaderButtonText={'Have a coupon code to activate?'}
							titleText={'SIGN UP NOW'}
							handleBack={handleBack}
							activeStep={activeStep}
							onClose={() => setIsOpen(false)}
							style={{ padding: '0 10px' }}
						/>
						<Form
							formDefaultValues={registerationInputs}
							handleSubmit={handleSubmit}
							handleError={handleError}
						>
							<StepperRegisteation
								activeStep={activeStep}
								handleBack={handleBack}
								isLoading={isLoading || usernameLoading}
								errorMessage={errorMessage}
								// handleNext={handleNext}
							/>
						</Form>
						<AuthModalFooter
							marginTop={'2rem'}
							bgColor={theme.modalPalette.authFooterBgColor}
						>
							<S.FooterText>
								{'By proceeding you confirm you are 18 years of age or older.'}
							</S.FooterText>
							<S.FooterButton onClick={onFooterBtnClick}>
								{/* ADD a proper svg  */}
								{/* <StyledSvgIcon
									defaultColor={theme.primaryWhite}
									SvgIcon={BackArrowSmall}
								/> */}
								<S.FooterButtonText>{'Live Chat'}</S.FooterButtonText>
							</S.FooterButton>
						</AuthModalFooter>
					</S.Paper>
				</Fade>
			</Modal>
		</ReactPortal>
	);
};

export default RegisterModal;
