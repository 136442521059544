import { Modal } from '@mui/material';
import styled from 'styled-components';

export const PickerModal = styled(Modal)`
	.MuiBackdrop-root {
		background-color: ${({ theme }) =>
			theme.leaderboardPicker.pickerModal.backDropBgColor};
	}
`;

export const Paper = styled.div`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 13.9rem;
	height: 18.6rem;
	background-color: ${({ theme }) => theme.leaderboardPicker.backgroundColor};
	box-shadow: 0px 4px 10px rgba(153, 153, 153, 0.25);
	border-radius: 0.7rem;
	padding: 2rem 0.37;
	text-align: center;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

export const OptionsList = styled.div`
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	&::-webkit-scrollbar-track {
		background-color: transparent;
	}

	&::-webkit-scrollbar {
		width: 3px;
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: ${({ theme }) =>
			theme.leaderboardPicker.pickerModal.scrollBarColor};
		border-radius: 2px;
	}
`;

export const PickerButton = styled.button`
	border: none;
	background-color: ${({ theme, isSelected }) =>
		isSelected ? theme.leaderboardPicker.activeBackgroundColor : 'transparent'};
	/* color: inherit; */
	color: ${({ theme, isSelected }) =>
		isSelected
			? theme.leaderboardPicker.activeColor
			: theme.leaderboardPicker.defaultColor};
	font-weight: inherit;
	padding: 0.6rem;
	width: 100%;
`;
