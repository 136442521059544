import { InputLabel } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import styled from 'styled-components';

export const DatePicker = styled(DesktopDatePicker)`
	.MuiOutlinedInput-input:-webkit-autofill {
		border-radius: 1.85rem;
	}
	.MuiOutlinedInput-root {
		width: 100%;
	}
	fieldset {
		border-radius: 1.85rem;
	}
	background-color: ${({ theme }) => theme.formPalette.inputBgColor};
	border-radius: 1.85rem;
`;

export const Label = styled(InputLabel)``;
