import lottie from "lottie-web";
import { useEffect, useRef } from "react";
import copyAnimation from "./copy_animation.json"


const CopyAnimation = () => {
  const anime = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: anime.current,
      renderer: "svg",
      loop: false,
      animationData: copyAnimation,
    });
    return () => lottie.destroy();
  }, []);
  return (
    <div
     style={{ marginTop: "0.6rem" }}
    >
      <div
        ref={anime}
        style={{
          width: "3.1rem",
          height: "100%",
        }}
      />
    </div>
  );
};

export default CopyAnimation;
