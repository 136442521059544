import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as S from './LoginForm.styles';
import FormField from '../components/FormField';
import Button from '../../../Button';
import PagesEnum from 'shared/Enum/pages';
import { AppSpinner } from '../../../AppSpinner/AppSpinner';
import { Switch } from '@mui/material';

export const LoginForm = ({ isLoading = false, error }) => {
	//hooks
	const langState = useSelector(({ lang }) => lang);
	const theme = useTheme();

	//constans
	const loginTexts = langState?.pages?.login;
	const loginInputs = langState?.pages?.login?.inputs;
	return (
		<S.Container>
			{loginInputs?.map((input) => (
				<FormField
					key={input.id}
					name={input.name}
					label={input.label}
					variant="outlined"
					type={input.type}
					rule={{ required: 'This Field Is Required' }}
				/>
			))}
			<S.Row>
				<div>
					<Switch style={{ color: theme.black }} />
					<p style={{ display: 'inline', fontSize: 12, marginLeft: 5 }}>
						{loginTexts?.switchText}
					</p>
				</div>
				<Link
					style={{
						display: 'inline',
						fontSize: 12,
						float: 'right',
						marginTop: 10,
						color: theme.black,
					}}
					to="/forgot-password"
				>
					{/* //TODO: add feature */}
					{/* <S.ForgotPassword>{loginTexts?.forgotPassBtn}</S.ForgotPassword> */}
				</Link>
			</S.Row>
			<S.OptionRow>
				<span style={{ display: 'inline' }}>New here?</span>
				<Link to={`/${PagesEnum.signUp}`} style={{ color: theme.primary }}>
					<span style={{ display: 'inline' }}>Signup</span>
				</Link>
			</S.OptionRow>
			<S.OptionRow>
				<span style={{ display: 'inline' }}>or continue as a</span>
				<Link to={`/${PagesEnum.predictions}`} style={{ color: theme.primary }}>
					<span style={{ display: 'inline' }}>Guest</span>
				</Link>
			</S.OptionRow>
			<S.ErrorMessage>{error}</S.ErrorMessage>
			<Button
				title={isLoading ? <AppSpinner color={theme.black} /> : 'Log-In'}
				disabled={isLoading}
				bgColor={theme.primary}
				textColor={theme.white}
				width={'12rem'}
				style={{
					height: '3rem',
					padding: '0.7rem 4rem',
					margin: '1.5rem auto 0',
				}}
				type="submit"
			/>
		</S.Container>
	);
};

export default LoginForm;
