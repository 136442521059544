import styled from 'styled-components';
import { Container } from 'react-bootstrap';

export const Team = styled(Container)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	max-width: 6.5rem;
	position: relative;
`;

export const StyledImage = styled.img`
	width: 100%;
`;

export const TeamName = styled.div`
	display: flex;
	justify-content: center;
	width: 80px;
	/* max-width: 100%; */
	height: 0;
	position: relative;
	text-align: center;
  align-self: center;
`;

export const Text = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: ${({theme}) => theme.secondaryTypography};
  font-size: 0.8rem;
  line-height: 1.2rem;
  font-weight: 500;
  width: 100%;
  text-align: center;
  margin-bottom: 0;
`;
