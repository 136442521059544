import { matchStatusEnum } from 'shared/utils/match-status';
import { V, X } from 'shared/assets/images/Decoration';
import * as S from './ScoreBox.styles';
import { useEffect, useState } from 'react';
import { useWindowResize } from 'shared/hooks';
import { useTheme } from 'styled-components';
import StyledSvgIcon from '../StyledSvgIcon/StyledSvgIcon';

const ScoreBox = ({
	match,
	userSubmission,
	hasMatchEnded,
	predictionText = 'Predicted',
	resultsText = 'Final Score',
}) => {
	const { status_code, matchScore } = match;

	const [matchStatusDisplay, setMatchStatusDisplay] = useState('');
	const theme = useTheme();

	const { isMobile } = useWindowResize();

	useEffect(() => {
		//only for live match
		switch (status_code) {
			case matchStatusEnum.HalfTime:
				setMatchStatusDisplay('Halftime');
				break;
			default:
				setMatchStatusDisplay('Live Score');
		}
	}, [status_code]);

	const isCorrectPrediction =
		userSubmission?.home_score === matchScore.home_score &&
		userSubmission?.away_score === matchScore.away_score;

	return (
		<S.ScoreBox $isMobile={isMobile}>
			{(!!userSubmission?.home_score || userSubmission?.home_score === 0) && (
				<div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
					<S.Text size={12}>{predictionText}</S.Text>
					<S.BoxWrapper>
						<S.Box>
							<S.DivText
								size={18}
								weight={'bold'}
								color={
									hasMatchEnded && isCorrectPrediction
										? theme.scoreBoxPalette.correctScoreColor
										: theme.scoreBoxPalette.wrongScoreColor
								}

							>
								{userSubmission?.home_score}
							</S.DivText>
						</S.Box>
						<S.SvgContainer>
							{hasMatchEnded && (
								<StyledSvgIcon stroke
									defaultColor={
										isCorrectPrediction
											? theme.scoreBoxPalette.correctScoreColor
											: theme.scoreBoxPalette.wrongScoreColor
									}
									SvgIcon={isCorrectPrediction ? V : X}
								/>
							)}
						</S.SvgContainer>
						<S.Box>
							<S.DivText
								size={18}
								weight={'bold'}
								color={
									hasMatchEnded && isCorrectPrediction
										? theme.scoreBoxPalette.correctScoreColor
										: theme.scoreBoxPalette.wrongScoreColor
								}
							>
								{userSubmission?.away_score}
							</S.DivText>
						</S.Box>
					</S.BoxWrapper>
				</div>
			)}

			<div>
				{hasMatchEnded && <S.Text size={12}>{resultsText}</S.Text>}
				{!hasMatchEnded && <S.Text size={12}>{matchStatusDisplay}</S.Text>}

				<S.BoxWrapper>
					<S.Box>
						<S.DivText size={18} weight={'bold'}>
							{matchScore.home_score}
						</S.DivText>
					</S.Box>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '100%',
						}}
					>
						<S.Text size={24}>-</S.Text>
					</div>
					<S.Box>
						<S.DivText size={18} weight={'bold'}>
							{matchScore.away_score}
						</S.DivText>
					</S.Box>
				</S.BoxWrapper>
			</div>
		</S.ScoreBox>
	);
};

export default ScoreBox;
