import {
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useState,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import PagesEnum from 'shared/Enum/pages.js';
import MarketNames from 'shared/Enum/market-names';
import {
	setHeader,
	setSelectedMarketTab,
} from 'shared/store/global/globalActions';
import HeaderTabs from '../Header/HeaderTabs/HeaderTabs.jsx';
import * as S from './Parlays.styles.js';
import ParlaysMatch from './ParlaysMatch.jsx';
import { useTheme } from 'styled-components';
import {
	useDebounce,
	useMutateBetslip,
	useOdds,
	useWindowResize,
} from 'shared/hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ErrorPage from '../ErrorPage/ErrorPage.jsx';
import { Warnning } from 'shared/assets/images/ErrorPage';
import retryIcon from 'shared/assets/images/ErrorPage/retry.svg';
import { AppSpinner } from '../AppSpinner/AppSpinner.jsx';
import ReactGA from 'react-ga';
import { TextField } from '@mui/material';
import { getDevice } from 'shared/utils/getDevice.js';

export const Parlays = () => {
	// hooks
	const dispatch = useDispatch();
	const { league } = useParams();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const theme = useTheme();
	const { isMobile } = useWindowResize();
	const langState = useSelector(({ lang }) => lang);
	const { mutateAsync: asyncMutateBetslip } = useMutateBetslip();
	const { pathname } = useLocation();
	const {
		initialLeagues,
		selectedLeagueTab,
		selectedMarketTab,
		axiosInstance,
		device,
	} = useSelector(({ global }) => global);

	// states
	const [factor, setFactor] = useState({});
	const [disabled, setDisabled] = useState(true);
	const [relevantOdds, setRelevantOdds] = useState(null);
	const [betAmount, setBetAmount] = useState(10);

	// constants
	const parlaysLang = langState?.pages?.parlays;
	const oddsNotFoundTexts = langState?.pages?.errorPages?.oddsNotFound;
	const username = sessionStorage.getItem('username');
	const jwt = localStorage.getItem('jwt-token');
	const leagues = queryClient.getQueryData(['leagues', jwt]);

	const marketName = MarketNames[selectedMarketTab + 1];
	const marketsRoutes = useMemo(
		() =>
			relevantOdds &&
			relevantOdds[0]?.markets
				.sort((a, b) => a.marketType - b.marketType)
				.map((market) => ({
					title: parlaysLang?.marketNames[market.marketType],
					to: `${league}/${MarketNames[market.marketType].name.replaceAll(
						' ',
						'-'
					)}`,
				})),
		[league, parlaysLang?.marketNames, relevantOdds]
	);
	const campaignRounds = useMemo(() => {
		return (
			leagues &&
			league &&
			Object.keys(leagues)?.map((league) => {
				const relevantRound = leagues[league]?.relevantRound;
				if (relevantRound) {
					const returnedObj = {
						leagueName: league,
						campaignRoundId: relevantRound.round.id,
					};
					if (relevantRound.submission?.id) {
						returnedObj.submissionId = relevantRound.submission.id;
					}
					return returnedObj;
				}
				return null;
			})
		);
	}, [league, leagues]);

	const {
		data: odds,
		isLoading: oddsLoading,
		isFetching,
		isSuccess,
	} = useOdds(axiosInstance, campaignRounds, process.env.NX_oddsEndpoint);
	// const odds = queryClient.getQueryData('odds');

	// functions
	const setSelectedTab = (value) => {
		dispatch(setSelectedMarketTab(value));
	};

	/**
	 * controlling the factor object
	 * which is containing for each league and market
	 * the factor number and the outcomes to send(selected)
	 */
	const factorHandler = useCallback(() => {
		const newFactor = {};
		for (const odd of relevantOdds) {
			const { markets } = odd;
			for (const market of markets) {
				if (market.marketType === selectedMarketTab + 1) {
					const { possibleOutcomes } = market;
					for (const outcome of possibleOutcomes) {
						if (outcome.isSelected) {
							if (!newFactor[league] || !newFactor[league][selectedMarketTab]) {
								newFactor[league] = {
									[selectedMarketTab]: {
										outcomesToSend: [outcome.id],
										factor: outcome.odds,
									},
								};
							} else {
								let num = Number(
									(newFactor[league][selectedMarketTab].factor *= outcome.odds)
								); // The Number() only visualizes the type and is not needed
								let roundedString = num.toFixed(2);
								let roundedFactor = Number(roundedString);
								newFactor[league][selectedMarketTab].factor = roundedFactor;
								newFactor[league][selectedMarketTab].outcomesToSend.push(outcome.id);
							}
						}
					}
				}
			}
		}
		setFactor((prevFactor) => {
			if (
				!prevFactor ||
				!prevFactor[league] ||
				!Object.keys(prevFactor[league]).length
			) {
				return { ...newFactor };
			}
			prevFactor[league][selectedMarketTab] =
				newFactor[league] && newFactor[league][selectedMarketTab]
					? newFactor[league][selectedMarketTab]
					: { outcomesToSend: [], factor: 0 };
			return { ...prevFactor };
		});
	}, [relevantOdds, league, selectedMarketTab]);

	const goToBetslip = useDebounce(async () => {
		try {
			const outcomesToSend = factor[league][selectedMarketTab]?.outcomesToSend;
			if (outcomesToSend?.length) {
				const { data } = await asyncMutateBetslip({
					betslipUrl: process.env.NX_betslipEndpoint,
					axiosInstance: axiosInstance,
					body: {
						outcomes: outcomesToSend,
					},
				});
				// TODO: add redirection for mobiles + analytics
				if (JSON.parse(process.env.NX_GA)) {
					const device = getDevice();
					ReactGA.event({
						category: `Bet now pressed - ${device}`,
						action: `${username || 'guest'} - pressed Bet now`,
					});
				}
				if (data?.urlForRedirect) {
					window.location.assign(data.urlForRedirect);
					window.top.location.href = data.urlForRedirect;
					window.parent.location = data.urlForRedirect;
					window.location.replace(data.urlForRedirect);
					return;
				}
				// let windowName = 'userConsole';
				// let popUp = window.open(
				// 	data.urlForRedirect,
				// 	windowName,
				// 	'width=1000, height=700, left=24, top=24, scrollbars, resizable'
				// );
				// if (popUp == null || typeof popUp == 'undefined') {
				// 	alert('Please disable your pop-up blocker and try again.');
				// } else {
				// 	popUp.focus();
				// }
				// window.open(data.urlForRedirect, '_blank');
			} else {
				console.log('empty outcomes');
			}
		} catch (err) {
			console.log(err);
		}
	});

	// Life cycle
	useEffect(() => {
		dispatch(
			setHeader({
				title: parlaysLang?.title,
				page: PagesEnum.parlays,
				description: '',
				leagues: initialLeagues,
				hideBurger: true,
			})
		);
	}, [dispatch, initialLeagues, league, parlaysLang?.title]);

	useEffect(() => {
		/**
		 * trigger the factor handler
		 */
		if (relevantOdds) {
			factorHandler();
		}
	}, [factorHandler, relevantOdds]);

	useEffect(() => {
		/**
		 * building market routes for the market tabs by the league
		 */
		if (marketsRoutes?.length) {
			marketsRoutes.forEach(({ to }, i) => {
				if (to === `${league}/${pathname.split('/')[3]}`) {
					dispatch(setSelectedMarketTab(i));
				}
			});
		}
	}, [dispatch, league, marketsRoutes, pathname]);

	useEffect(() => {
		/**
		 * handling button disabled status by the selected outcomes boolean
		 */
		let isSelectedOutcomes = true;
		if (factor && factor[league] && factor[league][selectedMarketTab]) {
			isSelectedOutcomes = Boolean(
				!factor[league][selectedMarketTab].outcomesToSend?.length
			);
		}
		setDisabled(isSelectedOutcomes);
	}, [dispatch, factor, league, selectedMarketTab]);

	useEffect(() => {
		if (odds && league) {
			// const firstLeague = leagues && Object.keys(leagues)[selectedLeagueTab];
			setRelevantOdds([...odds[league]]);
		}
	}, [league, leagues, odds, selectedLeagueTab]);

	useLayoutEffect(() => {
		if (!league) {
			navigate(`${initialLeagues[0].to}`);
		}
	}, [initialLeagues, league, navigate]);

	// jsx

	if (oddsLoading || !relevantOdds) {
		return <AppSpinner />;
	}

	if (isSuccess && (!odds || !relevantOdds.length)) {
		return (
			<S.ParlaysContainer fluid>
				<ErrorPage
					ErrorSvg={Warnning}
					title={oddsNotFoundTexts?.title}
					description={oddsNotFoundTexts?.description}
					btnTitle={oddsNotFoundTexts?.btnTitle}
					btnIcon={retryIcon}
					clickHandler={() => queryClient.refetchQueries('odds')}
					isFetching={isFetching}
				/>
			</S.ParlaysContainer>
		);
	}

	return (
		<S.ParlaysContainer $isMobile={isMobile}>
			{/* <Banner banner={}/> */}
			<S.ParlaysBody>
				<HeaderTabs
					selectedTab={selectedMarketTab}
					setSelectedTab={setSelectedTab}
					routes={marketsRoutes}
					variant={'fullWidth'}
				/>
				<S.AmountsWrapper>
					<S.PotentialGainText>{parlaysLang?.potentialGain}</S.PotentialGainText>
					<S.AccumulatorText>
						{parlaysLang?.accumulator} @{' '}
						<span style={{ color: theme?.accumulator?.numbers }}>
							{factor[league] && factor[league][selectedMarketTab]?.factor
								? factor[league][selectedMarketTab].factor.toFixed(2)
								: Number(0).toFixed(2)}
						</span>
					</S.AccumulatorText>
					<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
						<S.ReturnsText>{parlaysLang?.currencySymbol}</S.ReturnsText>
						<TextField
							inputProps={{
								disableUnderline: true,
							}}
							sx={{
								width: '10ch',
								display: 'flex',
								alignItems: 'center',
								background: theme?.accumulator?.inputBg
									? theme?.accumulator?.inputBg
									: 'rgba(0, 0, 0, 0.6)',
								borderRadius: '.625rem .625rem .4375rem .4375rem',
								'& legend': { display: 'none' },
								'& fieldset': { top: 0 },
								'.MuiFormLabel-root.MuiInputLabel-root': {
									color: theme?.accumulator?.numbers,
									'&.Mui-focused fieldset': {
										borderColor: 'white',
									},
								},
								'& .MuiOutlinedInput-root': {
									height: '40px',
									color: theme?.accumulator?.numbers,
								},
								'& .MuiOutlinedInput-input': {
									textAlign: 'start !important',
								},
								'& input[type=number]': {
									'-moz-appearance': 'textfield',
									padding: '0 10px',
								},
								'& input[type=number]::-webkit-outer-spin-button': {
									'-webkit-appearance': 'none',
									margin: 0,
								},
								'& input[type=number]::-webkit-inner-spin-button': {
									'-webkit-appearance': 'none',
									margin: 0,
								},
								'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
									{
										borderColor: '#4B4B4B',
									},
							}}
							variant="outlined"
							value={betAmount || ''}
							onChange={(e) => {
								setBetAmount(+e.target.value);
							}}
							type="number"
						/>
						<S.ReturnsText>
							{parlaysLang?.returns} {parlaysLang?.currencySymbol}{' '}
							<span style={{ color: theme?.accumulator?.numbers }}>
								{factor[league] && factor[league][selectedMarketTab]?.factor
									? (
											factor[league][selectedMarketTab].factor *
											betAmount *
											1.07
									  ).toFixed(2)
									: Number(0).toFixed(2)}
							</span>
						</S.ReturnsText>
					</div>
					{/* <S.WinAmountText>
						Win $
						{factor[league] && factor[league][selectedMarketTab]?.factor
							? Math.floor(factor[league][selectedMarketTab].factor * betAmount * 1.07)
							: 0}
					</S.WinAmountText> */}
				</S.AmountsWrapper>
				<S.SubmitButtonWrapper>
					<S.SubmitButton
						title={parlaysLang?.betNowBtn || ''}
						textColor={theme.submitButton.textColor.active}
						bgColor={theme.submitButton.backgroundColor.active}
						onClick={goToBetslip}
						disabled={disabled}
						$isMobile={isMobile}
					/>
				</S.SubmitButtonWrapper>
				<Row
					style={{
						textAlign: 'center',
						margin: 'unset',
						'&::before': {
							backgroundColor: 'red',
							borderBottom: `white solid 1px`,
						},
						'&::after': {
							borderBottom: `white solid 1px`,
						},
					}}
				>
					<Col xs={4} md={5} />
					{marketName.answers.map((answer) => (
						<Col style={{ color: theme.secondaryTypography }} key={answer}>
							{answer}
						</Col>
					))}
				</Row>
				<S.FullWidthHr />
				{relevantOdds.map((odd, index) => {
					const selectedMarket = odd.markets.find(
						(market) => market.marketType === selectedMarketTab + 1
					);
					return (
						<ParlaysMatch
							key={odd.id}
							odd={odd}
							market={selectedMarket}
							leagueName={league}
							isLast={index === relevantOdds.length - 1}
							factorHandler={factorHandler}
							campaignRounds={campaignRounds}
						/>
					);
				})}
				<S.SubmitButtonWrapper>
					<S.SubmitButton
						title={parlaysLang?.betNowBtn || ''}
						textColor={theme.submitButton.textColor.active}
						bgColor={theme.submitButton.backgroundColor.active}
						onClick={goToBetslip}
						disabled={disabled}
						$isMobile={isMobile}
					/>
				</S.SubmitButtonWrapper>
				{/* <S.SubmitButtonWrapper>
					<S.SubmitButton
						title={'Refetch'}
						textColor={theme.black}
						bgColor={theme.primary}
						onClick={async () => await queryClient.refetchQueries()}
						disabled={false}
						$isMobile={isMobile}
					/>
				</S.SubmitButtonWrapper> */}
			</S.ParlaysBody>
		</S.ParlaysContainer>
	);
};
