import styled from 'styled-components';

export const RoundIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.span`
  font-size: 1em;
  color: ${({theme}) => theme.mainTypography};
  line-height: 1rem;
  margin-bottom: 0.25rem;
  text-align: center;
  margin-top: 1rem;
  font-weight: 500;
`;

export const Indicator = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
`;
