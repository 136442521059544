import { Link } from 'react-router-dom';
import * as S from './DesktopSideNavWrapper.styles';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';

const DesktopSideNavWrapper = ({ clientLogo, DesktopSideNavComponent }) => {
	const theme = useTheme();
	const { header } = useSelector(({ global }) => global);
	return (
		<S.DesktopSideNavWrapper data-html2canvas-ignore className="nav-container">
			<S.Ul className="navbar-nav" $headerHeigth={header?.height}>
				<li
					className="nav-item"
					style={{ height: header?.height ? header.height + 'px' : '140px' }}
				>
					{clientLogo ? (
						<Link to={'/'} className="nav-link">
							<S.Image src={clientLogo} alt="clientLogo" />
						</Link>
					) : null}
				</li>
				<DesktopSideNavComponent
					defaultColor={theme.desktopNavItems.defaultColor}
					activeColor={theme.desktopNavItems.activeColor}
				/>
			</S.Ul>
		</S.DesktopSideNavWrapper>
	);
};

export default DesktopSideNavWrapper;
