import axios, { AxiosInstance } from 'axios';
import { useMutation, useQuery } from 'react-query';

type TBody = {
	formData: {
		firstName: string;
		lastName: string;
		email: string;
		password: string;
		userName: string;
		cellPhone: number;
		gender: string;
	}
}

type TParams = {
	axiosInstance?: AxiosInstance;
	body?: TBody;
	endpoint?: string;
};

type TError = {
	response: {
		data: {
			error: string
		}
	}
}

export const submitCreateMember = async (params: TParams) => {
	try {
		const { axiosInstance, body } = params;
		const data = await axiosInstance?.post(`auth/create-delasport-member`, {
			formData: body?.formData,
		});
		return data;
	} catch (err) {
		let _e = err as TError;
		let errorMessage = _e.response.data.error;
		return errorMessage;
	}
};

const useCreateMember = () => {
	return useMutation(submitCreateMember, { mutationKey: 'mutateCreateMember' });
};

export default useCreateMember;
