import { useTheme } from 'styled-components';
import * as S from './Postponed.styles';

const Postponed = ({ text }) => {
	const theme = useTheme();
	return (
		<S.Postponed>
			<S.Square
				backgroundColor={theme?.postponed?.background}
				borderColor={theme?.postponed?.border}
			>
				<S.Text color={theme?.postponed?.color}>{text}</S.Text>
			</S.Square>
		</S.Postponed>
	);
};

export default Postponed;
