import styled from 'styled-components';

export const DesktopSideNavWrapper = styled.div`
	width: 100%;
	height: 100vh;
	background-color: ${({ theme }) => theme.desktopNavBg};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	position: sticky;
	top: 0;
	left: 0;
	overflow: hidden;
`;

export const Ul = styled.ul`
	height: inherit;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	/* margin-top: ${({ $headerHeigth }) =>
		$headerHeigth ? $headerHeigth + 'px' : '140px'}; */
	/* gap: 50px; */
	overflow-y: scroll;
`;

export const Image = styled.img`
	width: 6rem;
`;
