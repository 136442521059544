export const SET_LEADERBOARD = 'SET_LEADERBOARD';
export const SET_CAMPAIGN = 'SET_CAMPAIGN';
export const SET_TYPE = 'SET_TYPE';
export const SET_PERIOD = 'SET_PERIOD';

export const setLeaderboard = (leaderboard = {}) => {
  return { type: SET_LEADERBOARD, payload: leaderboard };
};
export const setCampaign = (campaignId = 0) => {
  return { type: SET_CAMPAIGN, payload: campaignId };
};
export const setType = (type = 'SEASON') => {
  return { type: SET_TYPE, payload: type };
};
export const setPeriod = (period = 'SEASON') => {
  return { type: SET_PERIOD, payload: period };
};
