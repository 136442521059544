import { TextField, InputLabel } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import styled from 'styled-components';

export const TextInput = styled(TextField)`
	.MuiOutlinedInput-input:-webkit-autofill {
		border-radius: 1.85rem;
		margin: 0;
	}
	fieldset {
		border-radius: 1.85rem;
	}
	background-color: ${({ theme }) => theme.formPalette.inputBgColor};
	border-radius: 1.85rem;
`;

export const Label = styled(InputLabel)`
	font-size: 12px;
`;
