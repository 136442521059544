import FormField from '../../components/FormField';
import * as S from './FirstStep.style';

const FirstStep = () => {
	return (
		<div style={{ marginTop: '25px' }}>
			<FormField
				name={'email'}
				label={'E-Mail'}
				variant="outlined"
				type={'text'}
				rule={{ required: 'This Field Is Required' }}
			/>
			<FormField
				name={'userName'}
				label={'User Name'}
				variant="outlined"
				type="text"
				rule={{ required: 'This Field Is Required' }}
			/>
			<FormField
				name="password"
				label="Password"
				variant="outlined"
				type="password"
				rule={{ required: 'This Field Is Required', minLength: 6 }}
			/>
		</div>
	);
};

export default FirstStep;
