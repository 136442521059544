import { Col, Container } from 'react-bootstrap';
import styled from 'styled-components';

export const DesktopHeader = styled(Container)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	background-color: ${({theme})=> theme.desktopHeader.backgroundColor};
`;

export const ShadowBoxContainer = styled.div`
	z-index: 1000;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
`;

export const Title = styled.div`
	color: ${({theme})=> theme.desktopHeader.title };
	text-align: center;
	font-weight: 700;
	font-size: 1.375em;
	line-height: 1.625rem;
`;

export const Description = styled.div`
	text-align: center;
	font-weight: 500;
	font-size: 1em;
	line-height: 1.2rem;
	color: ${({ theme }) => theme.desktopHeader.description};
`;
