import { InputLabel } from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';
import styled from 'styled-components';

export const PhoneInput = styled(MuiTelInput)`
	.MuiOutlinedInput-input:-webkit-autofill {
		border-radius: 1.85rem;
	}
	fieldset {
		border-radius: 1.85rem;
	}
	background-color: ${({ theme }) => theme.formPalette.inputBgColor};
	border-radius: 1.85rem;
	.MuiTelInput-Flag {
		border-radius: 360px;
	}
`;

export const Label = styled(InputLabel)``;
