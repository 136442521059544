import styled from 'styled-components';

export const BetController = styled.div`
  width: 2em;
  padding-top: 0.5em;
`;

export const ArrowButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
  flex-direction: column;
	position: relative;
	height: 2em;
	width: 2em;
	margin: 0.3em 0;
	/* background-image: url('./images/match-box/activeBTN.svg');
  background-repeat: no-repeat;
  background-position: center; */
	border: none;
	background-color: transparent;
	outline: none;
`;

export const Image = styled.img`
  // TODO: convert to flex
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  margin: 0 auto;
`;

export const Prediction = styled.div`
// Two background-color
  background-color: ${({theme})=> theme.white};
  background-color: ${(props) => (props.disabled ? props.theme.grey : 'inherit')};
  height: 2rem;
  width: 2rem;
  border-radius: 0.5rem;
  border: 1px solid #dadada;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PredictionNumber = styled.div`
  display: block;
  font-weight: 700;
  color: ${({theme})=> theme.mainTypography};
`;
