import styled from 'styled-components';
import Button from '../Button';
import bgImage from 'shared/assets/images//ErrorPage/sockerBg.svg';

export const Layout = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-image: url(${bgImage}); // TODO: add mobile version bg image
	background-size: cover;
`;
export const ErrorBox = styled.div`
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const Title = styled.h1`
	font-style: normal;
	font-weight: 700;
	font-size: 1.5em;
	line-height: 3.3rem;
	color: ${({ theme }) => theme.black};
`;

export const Description = styled.h4`
	font-style: normal;
	font-weight: 400;
	font-size: 1.125em;
	line-height: 1.3rem;

	color: ${({ theme }) => theme.black};
`;

export const ActionBtn = styled(Button)`
	margin-top: 2rem;
	padding: 1.5rem 1rem;
	background-color: ${({ theme }) => theme.black};
	color: ${({ theme }) => theme.white};
	min-width: 8rem;
	width: auto;
	display: flex;
	align-items: center;
	justify-content: space-around;
`;
