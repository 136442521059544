import * as S from './DesktopHeader.styles';
import HeaderTabs from '../HeaderTabs';
import Banner from '../../Banner';
import HowToPlayLink from '../../HowToPlayLink';
import { AppSpinner } from '../../AppSpinner/AppSpinner';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedLeagueTab } from 'shared/store/global/globalActions';

const DesktopHeader = ({
	banner,
	leagues,
	title,
	description,
	howToPlayColor,
	hideHowToPlay = false,
}) => {
	const dispatch = useDispatch();
	const { selectedLeagueTab } = useSelector(({ global }) => global);

	const setSelectedTab = (value) => {
		dispatch(setSelectedLeagueTab(value));
	};

	//TODO: header tabs active colors
	return (
		<S.ShadowBoxContainer>
			<S.DesktopHeader fluid>
				<div style={{ flex: 3 }}></div>
				<div style={{ flex: 3 }}>
					<S.Title>{title || <AppSpinner />}</S.Title>
					<S.Description>{description || ''}</S.Description>
				</div>
				<div
					style={{
						flex: 3,
						display: 'flex',
						justifyContent: 'flex-end',
						minHeight: '50px',
					}}
				>
					{!hideHowToPlay && <HowToPlayLink howToPlayColor={howToPlayColor} />}
				</div>
			</S.DesktopHeader>
			{banner && <Banner banner={banner} />}
			{!!leagues?.length && (
				<HeaderTabs
					selectedTab={selectedLeagueTab}
					setSelectedTab={setSelectedTab}
					routes={leagues}
					variant={'scrollable'}
				/>
			)}
		</S.ShadowBoxContainer>
	);
};

export default DesktopHeader;
