import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 1.5rem;
`;

export const Row = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const ForgotPassword = styled.button`
	background-color: transparent;
	color: inherit;
	border: none;
	padding: 0;
	font: 'inherit';
	cursor: pointer;
	outline: inherit;
`;

export const ErrorMessage = styled.div`
	text-align: center;
	font-size: 1rem;
	color: ${({ theme }) => theme.red};
`;

export const OptionRow = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.6rem;
`;
