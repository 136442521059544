import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const WorldCupWrapper = styled.div`
	height: 100%;
	width: 100%;
	position: relative;
`;

export const WorldCupHeader = styled.header`
	z-index: 1;
	position: absolute;
	top: 20px;
	left: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1.2rem 0.6rem;
`;

export const StyledLink = styled(Link)`
	position: absolute;
	left: 0.6rem;
`;

export const Iframe = styled.iframe`
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	z-index: 0;
`;
