import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	/* overflow-y: hidden; */
	margin-bottom: unset;
`;
//*Header
export const Header = styled.div`
	padding: 0.5rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: ${({ theme }) => theme.black};
`;

export const Logo = styled.img`
	position: relative;
	width: ${({ isMobile }) => (isMobile ? '45%' : '20%')};
`;

export const Description = styled.h1`
	font-weight: bold;
	font-size: 1.6em;
	margin-top: 0.5rem;
	color: ${({ theme }) => theme.white};
`;
//************************/
