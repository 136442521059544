import { Spinner } from 'react-bootstrap';
import * as S from './Loading.styles';
import { useTheme } from 'styled-components';

export const Loading = (props) => {
  const theme = useTheme()
  const { color } = props;
  return (
    <S.Conatiner>
      <Spinner
        animation="border"
        style={{color: theme.secondary}}
      />
    </S.Conatiner>
  );
};
