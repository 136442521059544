import { Fade} from '@mui/material';
import isNullOrUndefined from 'shared/utils/is-null-or-undefined';
import ReactPortal from '../../ReactPortal/ReactPortal';
import * as S from './PickerModal.styles';
import { useTheme } from 'styled-components';

const PickerModal = ({
	options,
	onCloseModal,
	onSelectOption,
	selectedOption,
}) => {
	const theme = useTheme();
	return (
		<ReactPortal wrapperId={'picker-modal'}>
			<S.PickerModal
				disablePortal
				open={!!options}
				onClose={onCloseModal}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<Fade in={!!options}>
					<S.Paper>
						<S.OptionsList>
							{options?.map((option) => {
								const isSelected =
									!isNullOrUndefined(selectedOption) && selectedOption === option?.value;
								return (
									<div
										key={option.displayValue}
										style={
											({ width: '100%' },
											isSelected
												? {
														backgroundColor: theme.darkGrey,
														// color: theme.secondary,
														fontWeight: '600',
												  }
												: {})
										}
									>
										<S.PickerButton
											isSelected={isSelected}
											onClick={() => {
												onSelectOption(option);
												onCloseModal();
											}}
											className="leaderboard-period-picker_option"
										>
											{option.displayValue}
										</S.PickerButton>
									</div>
								);
							})}
						</S.OptionsList>
					</S.Paper>
				</Fade>
			</S.PickerModal>
		</ReactPortal>
	);
};

export default PickerModal;
