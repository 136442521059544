import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import useWindowResize from 'shared/hooks/useWindowResize';
import { useTheme } from 'styled-components';
import { AppSpinner } from '../../AppSpinner/AppSpinner';
import * as S from './HeaderTabs.styles';

const HeaderTabs = ({ selectedTab, routes, variant = 'fullWidth' }) => {
	const theme = useTheme();
	const { header } = useSelector(({ global }) => global);
	const { isMobile } = useWindowResize();

	return (
		<S.HeaderTabs
			TabIndicatorProps={{
				style: { height: 3, background: theme.secondary },
			}}
			// scrollButtons={false}
			allowScrollButtonsMobile
			value={selectedTab}
			variant={variant}
			// variant={fullWidth || isMobile ? 'scrollable' : 'standard'}
			aria-label="routes"
		>
			{!routes?.length ? (
				<AppSpinner />
			) : (
				routes.map(({ title, to, disabled, replace }) => {
					return (
						<S.HeaderTab
							style={{ whiteSpace: 'nowrap', color: theme.mobileHeader.headerTabs.inActiveColor }}
							$isMobile={isMobile}
							disabled={disabled}
							key={title}
							label={title}
							component={Link}
							to={
								replace
									? to.replace(' ', '-')
									: `/${header.page}/${to.replace(' ', '-')}`
							}
						/>
					);
				})
			)}
		</S.HeaderTabs>
	);
};

export default HeaderTabs;
