import { AxiosInstance } from 'axios';
import { useQuery } from 'react-query';

export const checkNickname = async (
	axiosInstance: AxiosInstance,
	nickname: string
) => {
	try {
		const { data } = await axiosInstance.get(
			`/api/check-nickname-unique?nickname=${nickname}`
		);
		return data;
	} catch (err) {
		console.log(err);
		throw err;
	}
};

const useCheckNickname = (axiosInstance: AxiosInstance, nickname: string) => {
	return useQuery(
		['nickname', nickname],
		() => checkNickname(axiosInstance, nickname),
		{
			enabled: Boolean(axiosInstance) && Boolean(nickname),
			onError: (err) => console.error('[useCheckNickname]: fetch failed ', err),
		}
	);
};

export default useCheckNickname;
