import * as S from './NicknameModal.styles';
import ReactPortal from '../../ReactPortal/ReactPortal';
import { useEffect, useRef, useState } from 'react';
import {
	useCheckNickname,
	useMutateNickname,
	useProfile,
	useWindowResize,
} from 'shared/hooks';
import { CancelToken } from 'axios';
import { Close as CloseIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { AppSpinner } from '../../AppSpinner/AppSpinner';
import { Backdrop, Fade, Modal, TextField } from '@mui/material';
import { setUserHandler } from 'shared/store/global/globalActions';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import PagesEnum from 'shared/Enum/pages';

export const NicknameModal = ({ setIsOpen, isOpen, leagueName }) => {
	// hooks
	const dispatch = useDispatch();
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const { axiosInstance } = useSelector(({ global }) => global);
	const lang = useSelector(({ lang }) => lang);
	const { isMobile } = useWindowResize();
	const timeoutRef = useRef(null);
	const reqCancelRef = useRef(CancelToken.source());

	// states
	const [nickname, setNickname] = useState(null);
	const [error, setError] = useState(null);
	const [isNicknameValid, setIsNicknameValid] = useState(null);

	// query hooks
	const {
		isLoading: nicknameCheckLoading,
		data,
		error: checkNicknameError,
	} = useCheckNickname(axiosInstance, nickname);
	const { mutateAsync: setNicknameRequest, isLoading: loadingNicknameSave } =
		useMutateNickname();

	// constants
	const username = sessionStorage.getItem('username');
	const user = queryClient.getQueryData(['user', username])?.user;
	const nicknameModalTexts = lang?.modals?.nickName;

	// functions
	const cancelLastNicknameCheck = () => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
		if (reqCancelRef.current?.cancel) {
			reqCancelRef.current.cancel();
			reqCancelRef.current = CancelToken.source();
		}
	};

	const submitNicknameHandler = async () => {
		cancelLastNicknameCheck();
		const { data: newNickname } = await setNicknameRequest({
			axiosInstance,
			nickname,
		});
		if (newNickname) {
			dispatch(setUserHandler({ ...user, nickname: newNickname }));
		}
		queryClient.refetchQueries({ active: true });
		setIsOpen(false);
		navigate(`/${PagesEnum.parlays}/${leagueName}`);
	};

	useEffect(() => {
		if (!nickname || error === 'Invalid nickname') return;
		cancelLastNicknameCheck();
		timeoutRef.current = setTimeout(async () => {
			if (data) {
				setIsNicknameValid(data.isNicknameUnique);
				if (data.isNicknameUnique === false) {
					setError(nicknameModalTexts?.existNickname);
				}
			}
		}, 500);
		return () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	}, [data, error, nickname, nicknameModalTexts?.existNickname]);

	return (
		<ReactPortal wrapperId="nickname-modal">
			<Modal
				disablePortal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={isOpen}>
					<S.Paper $isMobile={isMobile}>
						<S.ExitBtn onClick={() => setIsOpen(false)}>
							<CloseIcon />
						</S.ExitBtn>
						<S.Title>{nicknameModalTexts?.title}</S.Title>
						<S.Description>{nicknameModalTexts?.description}</S.Description>
						<S.TextInput
							autoFocus
							disabled={loadingNicknameSave}
							label="Nickname"
							variant="outlined"
							fullWidth
							error={!!error}
							value={nickname || ''}
							onChange={(e) => {
								setNickname(e.target.value);
								setIsNicknameValid(e.target.value === '' ? '' : null);
								!new RegExp(/^[A-Za-z][A-Za-z0-9]*$/).test(e.target.value)
									? setError(nicknameModalTexts?.invalidNickname)
									: setError(null);
							}}
							type="text"
						/>
						{nickname && (
							<S.StatusBoxWrapper>
								{nicknameCheckLoading || isNicknameValid === null ? (
									<AppSpinner color={'#353D49'} />
								) : (
									<S.StatusBoxContent error={!!error}>
										<span>
											{error ? <S.ErrorIcon /> : <S.ApprovedIcon />}
											{error && isNicknameValid !== null
												? error
												: nicknameModalTexts?.availableNickname}
										</span>
									</S.StatusBoxContent>
								)}
							</S.StatusBoxWrapper>
						)}
						<S.NoticeBox>
							<span>{nicknameModalTexts?.warning}</span>
						</S.NoticeBox>
						<S.SubmitBtn
							disabled={error || nicknameCheckLoading || isNicknameValid === null}
							onClick={() => {
								if (!nickname || error === 'Invalid nickname') return;
								submitNicknameHandler();
							}}
						>
							OK
						</S.SubmitBtn>
					</S.Paper>
				</Fade>
			</Modal>
		</ReactPortal>
	);
};

export default NicknameModal;
