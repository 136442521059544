import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as S from './Matches.styles';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { useDebounce, useMutateOdds, useMutateScreenshot } from 'shared/hooks';
import { EditIcon, DisabledEditIcon } from 'shared/assets/images/icons/header';
import RoundsEnum from 'shared/Enum/RoundsEnum';
import PagesEnum from 'shared/Enum/pages';
import {
	setShowLoginModal,
	setShowNicknameModal,
	toggleAskedForNicknameHandler,
	toggleEditModeHandler,
	toggleModifyModeHandler,
} from 'shared/store/global/globalActions';
import { createScreenshotCanvas } from 'shared/utils/share-predictions';
import { getDevice } from 'shared/utils/getDevice';
import isNullOrUndefined from 'shared/utils/is-null-or-undefined';
import { useMutatePredictions, useWindowResize } from 'shared/hooks';
import Button from '../Button';
import Match from '../Match';
import RoundIndicator from '../RoundIndicator';
import { Share } from '../Share/Share';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { AppSpinner } from '../AppSpinner/AppSpinner';
import CountDown from '../CountDown/CountDown';
import NicknameModal from '../Modals/NicknameModal/NicknameModal';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import RegisterModal from '../Modals/AuthModals/RegisterModal';
import moment from 'moment';
import 'moment/locale/es';
import LoginModal from '../Modals/AuthModals/LoginModal';

if (process.env.NX_Lang === 'es') {
	moment.updateLocale('es', {
		weekdaysShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
	});
}

export const Matches = ({
	leagueName,
	relevantRound,
	userPredictions,
	hideEditBtn = false,
	hidePredictionCounterOnMobile,
	disableOpacity,
	disableSubmitBtnBorder,
	submitBgColor,
	submitTextColor,
}) => {
	// hooks
	const location = useLocation();
	const dispatch = useDispatch();
	const queryClient = useQueryClient();
	const {
		hasRoundStarted,
		editMode,
		sideNavToggle,
		savedPredictions,
		device,
		axiosInstance,
		screenshotRef,
		askedForNickname,
		showNicknameModal,
		showDisclaimerModal,
	} = useSelector(({ global }) => global);
	const countDownRef = useRef();
	const navigate = useNavigate();
	const theme = useTheme();
	const langState = useSelector(({ lang }) => lang);
	const mutatePredictions = useMutatePredictions();
	const { width, isMobile, isXsmall, isSmall, isTablet, isLarge, isXlarge } =
		useWindowResize();
	// Share Predictions Snapshot
	const {
		mutateAsync: mutateScreenshot,
		data,
		isSuccess,
		isError,
	} = useMutateScreenshot();
	const { mutateAsync: mutateOdds } = useMutateOdds();

	//states
	const [shareLoading, setShareLoading] = useState(false);
	// const [showNicknameModal, setShowNicknameModal] = useState(false);
	const [disableSubmitBtn, setDisableSubmitBtn] = useState(true);
	const [disableEditBtn, setDisableEditBtn] = useState(true);
	const [image, setImage] = useState(null);
	const [showShareModal, setShowShareModal] = useState(false);
	const [blob, setBlob] = useState({});
	const [showCountDown, setShowCountDown] = useState(true);
	const [pageIndicator, setPageIndicator] = useState();

	const [isShareHandlerFlag, setIsShareHandlerFlag] = useState(false);

	// constants
	const predictionsLang = langState?.pages?.predictions;
	const username = sessionStorage.getItem('username');
	const user = queryClient.getQueryData(['user', username])?.user;
	const jwt = localStorage.getItem('jwt-token');
	const leagues = queryClient.getQueryData(['leagues', jwt]);
	const matches = useMemo(
		() =>
			relevantRound.round.matches.sort(
				(a, b) => new Date(a.match_start) - new Date(b.match_start)
			),
		[relevantRound.round.matches]
	);
	// const campaignRounds = useMemo(() => {
	// 	console.log({ leagues });
	// 	return Object.keys(leagues).map((league) => {
	// 		const relevantRound = leagues[league].relevantRound;
	// 		const returnedObj = {
	// 			leagueName: league,
	// 			campaignRoundId: relevantRound.round.id,
	// 		};
	// 		if (relevantRound.submission?.id) {
	// 			returnedObj.submissionId = relevantRound.submission.id;
	// 		}
	// 		return returnedObj;
	// 	});
	// }, [leagues]);
	const pendingUserPredictions = useMemo(
		() => userPredictions?.filter((pred) => pred.status !== 'pending'),
		[userPredictions]
	);
	const checkedUserPredictions = useMemo(
		() =>
			userPredictions?.map(({ matchId, home_score, away_score }) => ({
				matchId,
				home_score,
				away_score,
			})),
		[userPredictions]
	);

	// functions

	//this function invokes from the native to let us know if the postMessage was successful
	window.receivedPostMsg = () => {
		setShareLoading(false);
	};

	const shareHandler = useCallback(async () => {
		try {
			if (sideNavToggle) return;
			setShareLoading(true);
			let canvas = await createScreenshotCanvas(screenshotRef, isMobile);
			if (canvas) {
				const base = canvas.toDataURL('image/png');
				if (device) {
					device === 'ios'
						? window.webkit.messageHandlers.observer.postMessage(
							JSON.stringify({
								data: {
									link: base,
									text: 'http://demo.football.hook-apps.com',
								},
							})
						)
						: window.Android.postMessage(
							JSON.stringify({
								url: 'shareScreenshot',
								data: {
									link: base,
									text: 'http://demo.football.hook-apps.com',
								},
							})
						);
				} else {
					canvas.toBlob(async (blob) => {
						const file = new FormData();
						file.append('screenshot', blob, 'screenshot.jpg');
						file.append('page', PagesEnum.predictions);
						file.append('roundId', relevantRound?.round?.id);
						const data = await mutateScreenshot({
							axiosInstance,
							body: {
								file,
							},
						});
						if (data.data) {
							const { url } = data.data;
							setBlob(blob);
							setShareLoading(false);
							setShowShareModal(true);
							setImage(url);
						}
						//TODO: error modal
						else {
							setShareLoading(false);
							alert('You are not logged in');
						}
					});
				}
			}
		} catch (err) {
			console.log('[Matches: shareHnadler] = error');
			return err;
		}
	}, [
		axiosInstance,
		device,
		isMobile,
		mutateScreenshot,
		relevantRound?.round?.id,
		screenshotRef,
		sideNavToggle,
	]);

	const submitPredictionsHandler = useDebounce(async () => {
		setDisableSubmitBtn(true);
		// if (!user) {
		// 	navigate('/login', {
		// 		state: {
		// 			roundId: relevantRound?.round?.id,
		// 			predictions: savedPredictions[leagueName],
		// 		},
		// 	});
		// 	return;
		// }
		const externalLoginUrl = process.env.NX_loginUrl;
		if (!user) {
			switch (externalLoginUrl) {
				case 'modal':
					dispatch(setShowLoginModal(true));
					break;
				case 'page':
					navigate('/login', {
						state: {
							roundId: relevantRound?.round?.id,
							predictions: savedPredictions[leagueName],
						},
					});
					break;
				default:
					window.location.assign(externalLoginUrl);
					window.top.location.href = externalLoginUrl;
					window.parent.location = externalLoginUrl;
					window.location.replace(externalLoginUrl);
					break;
			}
			// if (externalLoginUrl === 'modal') {
			// 	dispatch(setShowLoginModal(true));
			// }
			return;
		}

		if (checkedUserPredictions?.length) {
			// setErrorMessage("Please do not leave an empty prediction.");
			console.log('Please do not leave an empty prediction.');
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			});
		}
		const status = await mutatePredictions.mutateAsync({
			axiosInstance,
			body: {
				roundId: relevantRound?.round?.id,
				predictions: savedPredictions[leagueName],
			},
			isEdit: Boolean(userPredictions?.length),
		});
		if (status?.data) {
			if (JSON.parse(process.env.NX_GA)) {
				const device = getDevice();
				ReactGA.event({
					category: `Submit Predictions - ${device}`,
					action: `${username} - Submit Predictions`,
				});
			}
			await queryClient.refetchQueries({ active: true });
			dispatch(toggleEditModeHandler(false));
			dispatch(toggleModifyModeHandler(false));
			if (!user.nickname) {
				dispatch(setShowNicknameModal(true));
			} else {
				navigate(`/${PagesEnum.parlays}/${leagueName}`);
			}
			setDisableSubmitBtn(false);
		}
	});

	// life cycle

	useEffect(() => {
		if (user && !user.nickname && askedForNickname && !showDisclaimerModal) {
			dispatch(setShowNicknameModal(true));
			// dispatch(toggleAskedForNicknameHandler(false));
		}
	}, [dispatch, askedForNickname, user, showDisclaimerModal]);

	useEffect(() => {
		const checkedPredictions = savedPredictions[leagueName]?.filter(
			(prediction) =>
				!isNullOrUndefined(prediction.home_score) &&
				!isNullOrUndefined(prediction.away_score)
		);
		const numOfMatches = matches?.filter(
			(match) => match.status_code !== 4
		)?.length;
		setDisableSubmitBtn(numOfMatches !== checkedPredictions?.length);
		setDisableEditBtn(
			editMode &&
			JSON.stringify(checkedPredictions) !==
			JSON.stringify(checkedUserPredictions)
		);
	}, [checkedUserPredictions, editMode, leagueName, matches, savedPredictions]);

	useEffect(() => {
		if (isShareHandlerFlag) {
			shareHandler()
				.then(() => {
					setIsShareHandlerFlag(false);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}, [isShareHandlerFlag, shareHandler]);

	// jsx
	return (
		<>
			<S.Matches
				$isMobile={isMobile}
				$showCountDown={showCountDown}
				$countDownHeight={countDownRef.current?.clientHeight}
				id="matchesContainer"
			>
				{relevantRound.key === RoundsEnum.futureRound &&
					!!pendingUserPredictions?.length && (
						<RoundIndicator submissionResults={userPredictions} matches={matches} />
					)}

				{/* {!hidePredictionCounterOnMobile &&
					showCountDown &&
					leagues[leagueName]?.countDownData && (
						<CountDown
							ref={countDownRef}
							enableClose
							counterPosition={'top'}
							countDownData={leagues[leagueName]?.countDownData}
							onClose={() => setShowCountDown(false)}
							counterFontFamily={counterFontFamily}
						/>
					)} */}

				{userPredictions && !hasRoundStarted && !hideEditBtn && (
					<S.EditBtnContainer
						hidePredictionCounterOnMobile={hidePredictionCounterOnMobile}
					>
						{isMobile && <div>{relevantRound?.round?.name}</div>}
						<S.EditButton
							toggle={editMode}
							onClick={() => dispatch(toggleEditModeHandler())}
							title={predictionsLang?.editBtn}
							SvgIcon={editMode ? DisabledEditIcon : EditIcon}
							disabled={disableEditBtn}
						/>
					</S.EditBtnContainer>
				)}

				{/* // TODO: Add predictions banner */}
				{/* {relevantRound.key === RoundsEnum.currentRound && (
				<div
					onClick={redirectBanner}
					style={{ marginTop: isTablet && !isLarge && '3.75rem' }}
				>
					<img
						style={{ width: '100%', cursor: 'pointer' }}
						src={user ? currentUserBanner : newUserBanner}
						alt="predictions banner"
					/>
				</div>
			)} */}
				{!matches ? (
					<h2 className="text-center">{predictionsLang?.noMatchesDataText}</h2>
				) : (
					<Row className="pb-3">
						{matches.map((match) => (
							<Match
								key={match.id}
								match={match}
								userPredictions={userPredictions}
								roundStatus={relevantRound.key}
								leagueName={leagueName}
								isMobile={isMobile}
								disableOpacity={disableOpacity}
							/>
						))}
					</Row>
				)}
				{showShareModal && (
					<Share
						blob={blob}
						open={showShareModal}
						title="Share your predictions"
						imageUrl={image}
						onClose={() => setShowShareModal(false)}
					/>
				)}
				<div>
					{userPredictions && !editMode ? (
						<Button
							bgColor={submitBgColor}
							disableBorder={disableSubmitBtnBorder}
							textColor={submitTextColor}
							title={
								shareLoading ? (
									<AppSpinner color={theme.black} />
								) : (
									predictionsLang?.sharePredictionsBtn
								)
							}
							disabled={sideNavToggle || shareLoading}
							onClick={() => setIsShareHandlerFlag(true)}
							style={{
								width: '100%',
								height: '3rem',
								padding: '0.7rem 1rem',
								margin: '1.5rem auto 0',
							}}
						/>
					) : (
						relevantRound.key === RoundsEnum.futureRound && (
							<Button
								title={
									queryClient.isFetching() ? <AppSpinner /> : predictionsLang?.submitBtn
								}
								bgColor={submitBgColor}
								disableBorder={disableSubmitBtnBorder}
								textColor={submitTextColor}
								onClick={submitPredictionsHandler}
								disabled={disableSubmitBtn}
								style={{
									width: '100%',
									height: '3rem',
									maxHeight: '100%',
									padding: '0.7rem 4rem',
									margin: '1.5rem auto 0',
								}}
							/>
						)
					)}
				</div>
			</S.Matches>
			{/* //TODO: put on commit */}
			<NicknameModal
				setIsOpen={(bool) => dispatch(setShowNicknameModal(bool))}
				isOpen={showNicknameModal}
				leagueName={leagueName}
			/>
		</>
	);
};
export default Matches;
