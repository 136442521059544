import styled from 'styled-components';

export const Paper = styled.div`
	position: relative;
	max-width: 60%;
	display: flex;
	align-items: center;
	gap: 2.5rem;
	flex-direction: column;
	border-radius: 1.25rem;
	border: 1px solid #000;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	padding: 2rem;
	background: ${({ theme }) => theme.shareModalPalette.backgroundColor};
`;

export const ExitBtn = styled.button`
	position: absolute;
	top: 0.5rem;
	right: 0.5rem;
	background-color: transparent;
	color: ${({ theme }) => theme.shareModalPalette.exitButtonColor};
	border: none;
`;

export const Title = styled.h6`
	color: ${({ theme }) => theme.shareModalPalette.titleColor};
	white-space: pre-line;
	margin-top: 1rem;
	font-size: 1.25em;
	line-height: 1.875rem;
	align-items: center;
	text-align: center;
`;

export const CopyLinkBox = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 1.25rem;
	align-items: center;
`;

export const Url2Share = styled.div`
	background-color: ${({ theme }) => theme.shareModalPalette.link.backgroundColor};
	border: ${({ theme }) => `${theme.shareModalPalette.link.borderColor} 1px solid`};
	border-radius: 1.25rem;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
`;

export const Link = styled.a`
	color: ${({ theme }) => theme.shareModalPalette.link.textColor.unHovered};
	text-decoration: underline;
	padding: 0.75rem;
	white-space: nowrap;
	text-align: left;
	&:hover {
		cursor: pointer;
		color: ${({ theme }) => theme.shareModalPalette.link.textColor.hover};
	}
`;

export const CopyBtn = styled.div`
	width: 6.25rem;
	height: 3.125rem;
	border: 0;
	padding: 0.7rem 2rem;
	border-radius: 1.25rem;
	color: ${({ theme }) => theme.shareModalPalette.copyBtn.textColor};
	background: ${({ theme }) => theme.shareModalPalette.copyBtn.backgroundColor};
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;

export const SnapshotBox = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`;

export const ImgContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 80%;
`;

export const Image = styled.img`
	max-height: 100%;
	max-width: 100%;
	// If the background color is set to transparent the width of the BlackSlip becomes a little bigger than the ImageContainer.
	// This is a workaround to solve this issue - that sets the border to none if the color is 'transparent'.
	border: ${({ theme }) =>
		theme.shareModalPalette.screenShotBorderColor === 'transparent'
			? 'none'
			: `1px solid ${theme.shareModalPalette.screenShotBorderColor}`};
	box-sizing: border-box;
	filter: drop-shadow(0px 4px 10px rgba(175, 175, 175, 0.4));
	border-radius: 20px;
	object-fit: contain;
`;

export const BlackSlip = styled.div`
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 25%;
	background-color: ${({ theme }) =>
		theme.shareModalPalette.downloadOverlay.backgroundColor};
	border-radius: 0px 0px 20px 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	&:hover {
		cursor: pointer;
	}
`;

export const BlackSlipText = styled.div`
	font-weight: 400;
	font-size: 0.75em;
	line-height: 0.87em;
	text-align: center;
	color: ${({ theme }) => theme.shareModalPalette.downloadOverlay.textColor};
`;
