import * as S from './Team.styles';

const Team = ({ image, TeamName, color }) => {
	return (
		<S.Team>
			<div>
				<S.StyledImage
					crossOrigin={'anonymous'}
					style={{ width: '55px', height: '45px' }}
					src={`${image}?not-from-cache-please`}
					alt="shirt"
				/>
			</div>
			<S.TeamName>
				<S.Text color={color}>{TeamName}</S.Text>
			</S.TeamName>
		</S.Team>
	);
};
export default Team;
