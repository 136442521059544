import { useSelector } from 'react-redux';
import SuccessPoints from 'shared/Enum/success-points';
import * as S from './RoundSummary.styles';

const RoundSummary = ({ submission }) => {
  const langState = useSelector(({ lang }) => lang);
	const submissionSuccessPoints = submission?.matchPredictions?.map(
		(mp) => mp?.successPoints ?? 0
	);
  const lang = langState?.pages?.results?.sections?.roundSummary;
	return (
		<S.RoundSummaryWrapper data-html2canvas-ignore>
			<S.Title>{lang?.title}</S.Title>
			<S.RoundSummaryContainer>
				<S.RoundSummaryHead>
					<S.RoundSummaryCell />
					<S.RoundSummaryCell className="text-center">#</S.RoundSummaryCell>
					<S.RoundSummaryCell className="text-center">
						{lang?.points}
					</S.RoundSummaryCell>
				</S.RoundSummaryHead>
				<S.RoundSummaryRow>
					<S.RoundSummaryCell className="justify-content-start">
						{lang?.correctResult}
					</S.RoundSummaryCell>
					<S.RoundSummaryCell className="text-center">
						{
							submissionSuccessPoints?.filter(
								(sp) => sp === SuccessPoints.CorrectResult
							)?.length
						}
					</S.RoundSummaryCell>
					<S.RoundSummaryCell className="text-center">
						{submissionSuccessPoints
							?.filter((sp) => sp === SuccessPoints.CorrectResult)
							?.reduce((prev, curr) => prev + curr, 0)}
					</S.RoundSummaryCell>
				</S.RoundSummaryRow>
				<S.RoundSummaryRow>
					<S.RoundSummaryCell className="justify-content-start">
						{lang?.correctScore}
					</S.RoundSummaryCell>
					<S.RoundSummaryCell className="text-center">
						{
							submissionSuccessPoints?.filter(
								(sp) => sp === SuccessPoints.CorrectScore
							)?.length
						}
					</S.RoundSummaryCell>
					<S.RoundSummaryCell className="text-center">
						{submissionSuccessPoints
							?.filter((sp) => sp === SuccessPoints.CorrectScore)
							?.reduce((prev, curr) => prev + curr, 0)}
					</S.RoundSummaryCell>
				</S.RoundSummaryRow>
				<S.RoundSummaryRow>
					<S.RoundSummaryCell className="justify-content-start">
						{lang?.total}
					</S.RoundSummaryCell>
					<S.RoundSummaryCell className="text-center">
						{submissionSuccessPoints?.filter((sp) => sp).length}
					</S.RoundSummaryCell>
					<S.RoundSummaryCell className="text-center">
						{submissionSuccessPoints?.reduce((prev, curr) => prev + curr, 0)}
					</S.RoundSummaryCell>
				</S.RoundSummaryRow>
			</S.RoundSummaryContainer>
		</S.RoundSummaryWrapper>
	);
};

export default RoundSummary;
