import * as S from './WorldCupBracket.styles';
import { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BackArrow } from 'shared/assets/images/Arrows';
import PagesEnum from 'shared/Enum/pages';
import { useWindowResize } from 'shared/hooks';
import { setHeader } from 'shared/store/global/globalActions';

export const WorldCupBracket = ({ iframeURL }) => {
	const dispatch = useDispatch();
	const { isMobile } = useWindowResize();
	const { initialLeagues } = useSelector(({ global }) => global);
	const isLeaderboard = iframeURL.includes('leaderboard');
	useLayoutEffect(() => {
		const header = isLeaderboard
			? { hideHeader: true }
			: {
					title: 'World Cup 2022',
					leagues: [...initialLeagues],
					page: PagesEnum.predictions,
					hideHowToPlay: true,
			  };
		dispatch(setHeader(header));
	}, [dispatch, initialLeagues, isLeaderboard]);

	return (
		<S.WorldCupWrapper>
			{isLeaderboard && (
				<S.WorldCupHeader $isMobile={isMobile}>
					<S.StyledLink $isMobile={isMobile} to={`/bracket`}>
						<BackArrow fill={'#fff'} />
					</S.StyledLink>
				</S.WorldCupHeader>
			)}
			<S.Iframe
				src={iframeURL}
				title="bracket"
				frameBorder="0"
				// scrolling="auto"
				// width={'100%'}
				// height={'100%'}
			/>
		</S.WorldCupWrapper>
	);
};

export default WorldCupBracket;
