import { PeriodTypeEnum } from 'shared/Enum/PeriodTypeEnum';
import { TLanguageConfig } from 'shared/store/lang/typesDefinition';

export const appFontFamily = 'Prompt';

export const languageConfig: TLanguageConfig = {
	language: 'es',
	pages: {
		login: {
			title: 'BIENVENIDO,',
			secondaryTitle: 'Iniciar sesión',
			inputs: [
				{
					name: 'email',
					id: 'email',
					label: 'Correo electrónico',
					placeHolder: 'Confirmar correo electrónico',
					type: 'email',
				},
				{
					name: 'password',
					id: 'password',
					label: 'Contraseña',
					placeHolder: 'Confirmar contraseña',
					type: 'password',
				},
			],
			switchText: 'Recordarme',
			forgotPassBtn: '¿Has olvidado la contraseña?',
			loginBtn: 'Iniciar sesión',
		},
		results: {
			title: 'RESULTADOS',
			sections: {
				nextRound: {
					title: 'Próxima jornada',
				},
				summary: {
					title: 'Resumen',
					yourTotalScore: 'Mi puntuación',
					leaderboardBtn: 'Clasificación',
				},
				roundSummary: {
					title: 'Resumen de la jornada',
					points: 'Puntos',
					correctResult: 'Resultado Correcto',
					correctScore: 'Resultado Exacto',
					total: 'Total',
				},
				currentRound: {
					title: 'Jornada actual',
				},
				previouseRounds: {
					title: 'Jornada anterior',
					totalPoints: 'Puntuación',
				},
			},
		},
		predictions: {
			title: 'Pronóstico',
			noMatchesDataText: 'Actualmente no hay predicciones para mostrar',
			editBtn: 'Editar',
			submitBtn: 'Enviar',
			sharePredictionsBtn: 'Compartir mi predicción',
		},
		leaderboard: {
			title: 'CLASIFICACIÓN',
			myRankTitle: 'My Rank',
			tableFilters: [
				{
					displayName: 'Fase De Grupos',
					name: PeriodTypeEnum.GROUP,
					hasPicker: false,
				},
				{
					displayName: 'Torneo',
					name: PeriodTypeEnum.SEASON,
					hasPicker: false,
				},
			],
			table: {
				sumOfMembersText: 'Usuario',
				rankColumnTitle: 'Ranking',
				usernameColumnTitle: 'Nombre de usuario',
				pointsColumnTitle: 'Puntos',
			},
		},
		parlays: {
			title: 'Apuesta',
			bet: 'Apuesta',
			betNowBtn: 'Apostar Ahora',
			potentialGain: 'Ganancia potencial:',
			accumulator: 'Cuota total',
			returns: 'Ganancias',
			currencySymbol: '€',
			marketNames: {
				1: '1x2',
				2: 'MÁS/MENOS 2.5 GOLES',
				3: 'AMBOS MARCAN',
			},
		},
		termsAndConditions: 'T&C',
		howToPlay: '¿Cómo se juega?',
		inviteAfriend: 'Invitar a un amigo',
		errorPages: {
			pageNotFound: {
				title: 'Página no encontrada',
				description: 'La página que buscas no existe o ha ocurrido otro error.',
				btnTitle: 'Volver a intentar',
			},
			warningPage: {
				title: '¡Algo no funciona!',
				description: 'Por favor, ve al paso anterior o refresca la página',
				btnTitle: 'Volver a intentar',
			},
			oddsNotFound: {
				title: 'Esta liga no tiene cuotas actualmente o todavía no existen',
				description: 'Por favor, ve al paso anterior o comprueba la información',
				btnTitle: 'Volver a intentar',
			},
		},
	},
	components: {
		header: {
			description: 'Por favor, introduce 4 pronósticos',
			howToPlay: '¿Cómo se juega?',
		},
		roundIndicator: {
			correctPredictions: 'Pronósticos correctos:',
			outOf: 'salir',
		},
		match: {
			postponed: 'Aplazado',
			matchIndicator: {
				title: 'Puntos',
				exact: 'Exacto',
				correct: 'Correcto',
			},
			predictionText: 'Predicción',
			resultsText: 'Resultado final',
			matchLiveStatus: {
				halftime: 'Descanso',
				liveScore: 'Resultado en directo',
			},
		},
		countDown: {
			title: '',
			description: '',
			timePeriod_long: ['DÍAS', 'HORAS', 'MINUTOS', 'SEGUNDOS'],
			timePeriod_short: ['DÍAS', 'HRS', 'MIN', 'SEC'],
		},
	},
	modals: {
		nickName: {
			title: 'Selecciona un nombre de usuario',
			description:
				'Nombre de usuario para la clasificación, este nombre lo verán el resto de usuarios:',
			invalidNickname: 'Nombre de usuario incorrecto',
			existNickname: 'Nombre de usuario no disponible',
			availableNickname: 'Nombre de usuario disponible',
			warning: `* Necesitas un nombre de usuario para poder formar parte de la clasificación`,
			btn: 'OK',
		},
		warn: {
			title: '¿Deseas salir de la página?',
			description: 'Perderás toda la información actual.',
			stayBtn: 'PERMANECER',
			leaveBtn: 'SALIR',
		},
		submit: {
			description: '¿Estás seguro de los cambios?',
			yesBtn: 'SÍ',
			noBtn: 'NO',
		},
		share: {
			inviteFriend: {
				title: 'Comparte con tus amigos',
				urlToShare: 'https://www.versus.es/mundial2022',
				copyBtn: 'Copiar',
			},
			sharePredictions: {
				title: 'Compartir Pronósticos',
				downloadBtn: 'Descargar',
			},
		},
	},
};

// const EnglishlanguageConfig: TLanguageConfig = {
// 	pages: {
// 		login: {
// 			title: 'Welcome Back,',
// 			secondaryTitle: 'Log In!',
// 			inputs: [
// 				{
// 					name: 'email',
// 					id: 'email',
// 					label: 'Email Address',
// 					placeHolder: 'Email Address',
// 					type: 'email',
// 				},
// 				{
// 					name: 'password',
// 					id: 'password',
// 					label: 'Password',
// 					placeHolder: 'Password',
// 					type: 'password',
// 				},
// 			],
// 			switchText: 'Remember Me',
// 			forgotPassBtn: 'Forgot password?',
// 			loginBtn: 'Log-in',
// 		},
// 		results: {
// 			title: 'RESULTS',
// 			sections: {
// 				nextRound: {
// 					title: 'Next Round',
// 				},
// 				summary: {
// 					title: 'Summary',
// 					yourTotalScore: 'Your Total Score',
// 					leaderboardBtn: 'Leaderboard',
// 				},
// 				currentRound: {
// 					title: 'Current Round',
// 				},
// 				previouseRounds: {
// 					title: 'Previouse Rounds',
// 					totalPoints: 'Total Points',
// 				},
// 			},
// 		},
// 		predictions: {
// 			title: 'PREDICTIONS',
// 			noMatchesDataText: 'There are currently no predictions to display',
// 			editBtn: 'Edit',
// 			submitBtn: 'Submit',
// 			sharePredictionsBtn: 'Share Your Predictions',
// 		},
// 		leaderboard: {
// 			title: 'LEADERBOARD',
// 			myRankTitle: 'My Rank',
// 			tableFilters: [
// 				{
// 					displayName: 'Group Stage',
// 					name: PeriodTypeEnum.GROUP,
// 					hasPicker: false,
// 				},
// 				{
// 					displayName: 'Tournament',
// 					name: PeriodTypeEnum.SEASON,
// 					hasPicker: false,
// 				},
// 			],
// 			table: {
// 				sumOfMembersText: 'Members',
// 				rankColumnTitle: 'Rank',
// 				usernameColumnTitle: 'User Name',
// 				pointsColumnTitle: 'Points',
// 			},
// 		},
// 		parlays: {
// 			title: 'PARLAYS',
// 			bet: 'Bet',
// 			betNowBtn: 'Bet Now',
// 		},
// 		termsAndConditions: 'T&C',
// 		howToPlay: 'How to play',
// 		inviteAfriend: 'Invite a friend',
// 		errorPages: {
// 			pageNotFound: {
// 				title: 'Page not found',
// 				description:
// 					'The page you are looking for doesn’t exist or an other error occurred.',
// 				btnTitle: 'Try Again',
// 			},
// 			warningPage: {
// 				title: 'Something went wrong!',
// 				description: 'Please go back or try to refresh the page',
// 				btnTitle: 'Retry',
// 			},
// 			oddsNotFound: {
// 				title: 'Current league odds are not available or still not exist',
// 				description: 'Please go back or try to refetch the data',
// 				btnTitle: 'Retry',
// 			},
// 		},
// 	},
// 	components: {
// 		header: {
// 			description: 'please enter 6 predictions',
// 			howToPlay: 'How To Play',
// 		},
// 		roundIndicator: {
// 			correctPredictions: 'Correct Predictions:',
// 			outOf: 'out of',
// 		},
// 		match: {
// 			postponed: 'Postponed',
// 			matchIndicator: {
// 				title: 'Points',
// 				exact: 'exact',
// 				correct: 'correct',
// 			},
// 			predictionText: 'Predicted',
// 			resultsText: 'Final Score',
// 			matchLiveStatus: {
// 				halftime: 'Halftime',
// 				liveScore: 'Live Score',
// 			},
// 		},
// 		countDown: {
// 			title: '',
// 			description: '',
// 			timePeriod_long: [],
// 			timePeriod_short: [],
// 		},
// 	},
// 	modals: {
// 		nickName: {
// 			title: 'Choose Nickname',
// 			description:
// 				'Enter your nickname for Leaderboard that other players will see:',
// 			invalidNickname: 'Nickname is invalid',
// 			existNickname: 'Nickname already exists',
// 			availableNickname: 'Nickname is available',
// 			warning: `* If you don"t select a nickname you will not be the part of the Leaderboard`,
// 			btn: 'OK',
// 		},
// 		warn: {
// 			title: 'Quit from the page?',
// 			description: 'All progress in this page will be lost.',
// 			stayBtn: 'STAY',
// 			leaveBtn: 'LEAVE',
// 		},
// 		submit: {
// 			description: 'Are you sure you want to make changes?',
// 			yesBtn: 'YES',
// 			noBtn: 'NO',
// 		},
// 		share: {
// 			inviteFriend: {
// 				title: 'Share the MVP to your friends',
// 				urlToShare: 'https://versus.hook-apps.com',
// 				copyBtn: 'Copy',
// 			},
// 			sharePredictions: {
// 				title: 'Share Your Predictions',
// 				downloadBtn: 'Download',
// 			},
// 		},
// 	},
// };
