import { AxiosInstance } from 'axios';
import { useMutation, useQuery } from 'react-query';

type TBody = {
	formData: {
		firstName: string;
		lastName: string;
		email: string;
		password: string;
	}
}

type TParams = {
	axiosInstance?: AxiosInstance;
	body?: TBody;
};

type TError = {
	response: {
		data: {
			error: string
		}
	}
}

export const submitRegister = async (params: TParams) => {
	try {
		const { axiosInstance, body } = params;
		const data = await axiosInstance?.post(`auth/register`, body?.formData);
		return data;
	} catch (err) {
		let _e = err as TError;
		let errorMessage = _e.response.data.error

		// throw err;
		return errorMessage
	}
};

const useMutateRegister = () => {
	return useMutation(submitRegister, { mutationKey: 'mutateRegister' });
};

export default useMutateRegister;
