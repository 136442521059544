import { AppSpinner } from '../AppSpinner/AppSpinner';
import * as S from './ErrorPage.styles';

export const ErrorPage = ({
	ErrorSvg,
	title = 'Page not found',
	description = 'The page you are looking for doesn’t exist or an other error occurred.',
	btnTitle = 'Back to predictions',
	btnIcon,
	clickHandler = () => {
		console.log('click');
	},
	isFetching = false,
}) => {
	return (
		<S.Layout>
			<S.ErrorBox>
				<ErrorSvg />
				<S.Title>{title}</S.Title>
				<S.Description>{description}</S.Description>
				<S.ActionBtn
					icon={isFetching ? null : btnIcon}
					onClick={clickHandler}
					title={btnTitle}
				>
					{isFetching && <AppSpinner />}
				</S.ActionBtn>
			</S.ErrorBox>
		</S.Layout>
	);
};

export default ErrorPage;
