import * as S from './FormSelect.styles';
import { Controller, useFormState } from 'react-hook-form';
import { useTheme } from 'styled-components';

const FormSelect = ({
	name,
	label,
	variant = 'outlined',
	rule,
	selectList,
}) => {
	const theme = useTheme();
	const formState = useFormState();
	const selectItems = selectList.map((selectItem) => (
		<S.SelectListItem
			key={selectItem.value || selectItem}
			value={selectItem.value || selectItem}
		>
			{selectItem.label || selectItem}
		</S.SelectListItem>
	));
	return (
		<Controller
			name={name}
			render={({ field: { onChange, onBlur, value, ref } }) => (
				<>
					<S.Label
						sx={{
							color: theme.formPalette.labelColor,
							fontSize: '0.8rem',
							margin: '0.5rem 0 0.4rem 0',
						}}
					>
						{label}
					</S.Label>
					<S.FormContainer variant={variant}>
						{!value && (
							<S.PlaceHolder disableAnimation shrink={false} focused={false}>
								{label}
							</S.PlaceHolder>
						)}
						<S.SelectInput
							onChange={onChange}
							onBlur={onBlur}
							value={value || ''}
							ref={ref}
							variant={variant}
							fullWidth
							sx={{
								'& legend': { display: 'none' },
								'& fieldset': { top: 0 },
								borderRadius: '1.85rem',
							}}
							error={Boolean(formState.errors[name])}
							helpertext={formState.errors[name]?.message}
						>
							{selectItems}
						</S.SelectInput>
					</S.FormContainer>
					{Boolean(formState.errors[name]) && (
						<S.Label
							sx={{
								color: theme.formPalette.errorColor,
								fontSize: '0.7rem',
								margin: '0.3rem 0 0.4rem',
							}}
						>
							{formState.errors[name]?.message}
						</S.Label>
					)}
				</>
			)}
			rules={rule}
		/>
	);
};

export default FormSelect;
