import './TermsConditionsComponent.css';

const hideContent = false;

const TermsConditionsComponent = () => {
	if (hideContent) return null;
	return (
		<div className="tnc__main-container">
			<ul className="bottom-margin_large">
				<li>
					Estos términos están sujetos a su vez a los Términos y Condiciones
					Generales de VERSUS.{' '}
				</li>
				<li>
					Esta promoción no es compatible con otras promociones, así, no se podrán
					combinar con ninguna otra oferta, promoción o bonificación ofrecida por
					VERSUS.
				</li>
				<li>
					Los clientes de VERSUS que participen en la promoción aceptan los términos
					y condiciones y reconocen que el incumplimiento de estos puede provocar la
					retirada del premio.{' '}
				</li>
				<li>
					Esta promoción es válida una vez por usuario dentro del periodo
					promocional.
				</li>
				<li>
					La caducidad de las Freebets entregadas será de 7 días desde su
					recibimiento, se puede usar en cuotas de 1.5 a 3.5.
				</li>
				<li>
					-Para resolver cualquier duda o incidencia, no dudes en contactar con
					nuestro servicio de atención al cliente en: ayuda@versus.es.
				</li>
			</ul>
		</div>
	);
};

export default TermsConditionsComponent;
