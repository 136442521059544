import {
	Backdrop,
	Fade,
	Modal,
	Checkbox,
	Box,
	Button as MuiButton,
} from '@mui/material';
import ReactGA from 'react-ga';
import ReactPortal from '../../../ReactPortal/ReactPortal';
import * as S from './LoginModal.styles';
import Form from '../../../Form';
import { useEffect, useState } from 'react';
import AuthModalHeader from '../AuthModalHeader';
import AuthModalFooter from '../AuthModalFooter';
import { useTheme } from 'styled-components';
import axios from 'axios';
import { useCreateMember, useLoginMember } from 'shared/hooks/delaSport';
import { useDispatch, useSelector } from 'react-redux';
import FormField from '../../../Form/forms/components/FormField';
import Button from '../../../Button';
import { Link } from 'react-router-dom';
import {
	setShowLoginModal,
	setShowRegisterModal,
} from 'shared/store/global/globalActions';
import { AppSpinner } from '../../../AppSpinner/AppSpinner';
import { useAuth } from 'shared/hooks';
import { getDevice } from 'shared/utils/getDevice';

const loginInputs = {
	userName: '',
	password: '',
};

export const LoginModal = ({ isOpen, setIsOpen }) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const { axiosInstance } = useSelector(({ global }) => global);

	// states
	const [errorMessage, setErrorMessage] = useState(null);
	const [checked, setChecked] = useState(false);
	const [successUsername, setSuccessUsername] = useState(null);

	const { mutateAsync: mutateLoginMember, isLoading } = useLoginMember();
	const { isLoading: authLoading, data: authData } = useAuth(
		axiosInstance,
		process.env.NX_serverKey,
		successUsername,
		process.env.NX_originServer
	);


	const firstStepValidationHandler = (formData) => {
		return !formData?.email || !formData?.userName || !formData?.password;

		//!some validation here
	};

	const onFooterBtnClick = () => {
		return;
	};

	const openRegister = () => {
		dispatch(setShowRegisterModal(true));
		dispatch(setShowLoginModal(false));
	};

	const loginMember = async (formData) => {
		try {
			setErrorMessage(null);
			const { data } = await mutateLoginMember({
				axiosInstance,
				body: {
					formData,
				},
			});
			if (data?._status === 'success') {
				if (JSON.parse(process.env.NX_GA)) {
					const device = getDevice();
					ReactGA.event({
						category: `Login Success - ${device}`,
						action: `${formData?.email || formData?.userName} - Logged In`,
					});
				}
				setErrorMessage(null);
				return data;
			} else {
				setErrorMessage(data?.payload?._message);
			}
		} catch (error) {
			console.log('loginMember error', error);
		}
	};

	const handleSubmit = async (formData) => {
		const member = await loginMember(formData);
		if(member?._status === 'success') {
			sessionStorage.setItem('username', formData.userName);
			setSuccessUsername(formData.userName);
			setIsOpen(false)
		} else {
			setSuccessUsername(null);
		}
	};

	const handleError = (err) => {
		console.log(err);
	};

	return (
		<ReactPortal wrapperId={'login-modal'}>
			<Modal
				disablePortal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					overflowY: 'scroll',
				}}
				open={isOpen}
				onClose={() => setIsOpen(false)}
			>
				<Fade in={isOpen}>
					<S.Paper>
						<S.RootContainer>
							<AuthModalHeader
								titleText={'ACCOUNT LOGIN'}
								onClose={() => setIsOpen(false)}
							/>
							<Form
								formDefaultValues={loginInputs}
								handleSubmit={handleSubmit}
								handleError={handleError}
							>
								<FormField
									name={'userName'}
									label={'User Name'}
									variant="outlined"
									type="text"
									rule={{ required: 'This Field Is Required' }}
								/>
								<FormField
									name="password"
									label="Password"
									variant="outlined"
									type="password"
									rule={{ required: 'This Field Is Required', minLength: 6 }}
								/>
								<div style={{ width: '100%' }}>
									<Checkbox
										sx={{
											alignSelf: 'flex-start',
											color: theme.secondary,
											'&.Mui-checked': {
												color: theme.secondary,
											},
										}}
										checked={checked}
										onChange={() => setChecked((val) => !val)}
									/>
									<p
										style={{
											display: 'inline',
											fontSize: 12,
											marginLeft: 5,
											color: theme.white,
										}}
									>
										Remember Me
									</p>
								</div>
								{statusIndicator(errorMessage, isLoading)}
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'flex-end',
										flexDirection: 'column',
										width: '100%',
									}}
								>
									<Button
										variant="contained"
										type="submit"
										width={'100%'}
										sx={{ mt: 3, ml: 1, marginRight: '600px', pl: 3 }}
									>
										LOG IN
									</Button>
								</Box>
								<MuiButton
									sx={{ color: theme.white, fontSize: '10px', pt: 3, pb: 3 }}
									variant="text"
								>
									forgotten password
								</MuiButton>
							</Form>
						</S.RootContainer>
						<AuthModalFooter column bgColor={theme.modalPalette.authFooterBgColor}>
							<S.NewAccountText>
								New Customer?
								<MuiButton
									onClick={openRegister}
									sx={{ color: theme.secondary, textDecoration: 'underline' }}
									variant="text"
								>
									Open New Account
								</MuiButton>
							</S.NewAccountText>
							<S.FooterText>
								In order to place bet(s) you must be a registered user
							</S.FooterText>
						</AuthModalFooter>
					</S.Paper>
				</Fade>
			</Modal>
		</ReactPortal>
	);
};

const statusIndicator = (errorMessage, isLoading) => {
	if (isLoading) {
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					padding: '10px 0',
				}}
			>
				<AppSpinner />
			</div>
		);
	}

	if (errorMessage) {
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<div
					style={{
						margin: '0.5rem 0',
						color: 'red',
						width: '85%',
						textAlign: 'center',
						whiteSpace: 'nowrap',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					* {errorMessage}
				</div>
			</div>
		);
	}

	return null;
};

export default LoginModal;
