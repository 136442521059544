import * as S from './AuthModalFooter.styles';

const AuthModalFooter = ({ children, marginTop, bgColor, column }) => {
	return (
		<S.FooterContainer margintop={marginTop} bgcolor={bgColor} column={column}>
			{children}
		</S.FooterContainer>
	);
};

export default AuthModalFooter;
