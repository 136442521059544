import * as S from './Header.styles';
import MobileHeader from './MobileHeader/MobileHeader';
import DesktopHeader from './DesktopHeader';
import { useWindowResize } from 'shared/hooks';

export const Header = (props) => {
	const { hideHeader } = props;

	const { isMobile } = useWindowResize();

	if(hideHeader) {
		return null;
	}

	return (
		<S.Header fluid isMobile={isMobile}>
			{isMobile ? <MobileHeader {...props} /> : <DesktopHeader {...props} />}
		</S.Header>
	);
};

export default Header;
