import PagesEnum from 'shared/Enum/pages';
import * as S from './FooterNavItemsComponent.styles';
import {
	LeaderboardIcon,
	ResultsIcon,
	ParlaysIcon,
	PredictionsIcon,
} from 'shared/assets/images/menu';
import { NavItem, WarnModal } from '@hook-apps-client/ui';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	toggleEditModeHandler,
	toggleModifyModeHandler,
} from 'shared/store/global/globalActions';
import { useTheme } from 'styled-components';

const FooterNavItemsComponent = ({ defaultColor, activeColor }) => {
	// hooks
	const queryClient = useQueryClient();
	const dispatch = useDispatch();
	const location = useLocation();
	const { selectedLeagueTab } = useSelector(({ global }) => global);
	const navigate = useNavigate();
	const langState = useSelector(({ lang }) => lang);
	const { modifyMode } = useSelector(({ global }) => global);
	const theme = useTheme();

	// states
	const [showWarneModal, setShowWarneModal] = useState(false);
	const [selectedPath, setSelectedPath] = useState(false);

	// constans
	const jwt = localStorage.getItem('jwt-token');
	const leagues = queryClient.getQueryData(['leagues', jwt]);
	const warnModalTexts = langState?.modals?.warn;
	const pagesLang = langState?.pages;
	const leagueRoute = location.pathname.includes('bracket')
		? 'bracket'
		: leagues && Object.keys(leagues)[selectedLeagueTab]?.replace(' ', '-');
	const username = sessionStorage.getItem('username');
	const user = queryClient.getQueryData(['user', username])?.user;

	// functions
	const disableLinkHandler = (e, path, disabled) => {
		if (disabled) {
			e.preventDefault();
			return;
		}
		if (modifyMode) {
			e.preventDefault();
			setSelectedPath(path);
			setShowWarneModal(true);
		}
	};

	const onStayHandler = () => {
		setShowWarneModal(false);
	};

	const onLeaveHandler = () => {
		dispatch(toggleModifyModeHandler(false));
		dispatch(toggleEditModeHandler(false));
		setShowWarneModal(false);
		navigate(selectedPath);
	};

	const disabled = leagueRoute === PagesEnum.bracket;

	if (disabled) return null;

	//jsx
	return (
		<S.FooterNavItems id="footerNav">
			<div style={{ flex: 4 }}>
				<NavItem
					activeBg={theme.gold}
					onClick={disableLinkHandler}
					activeColor={activeColor}
					SvgIcon={ResultsIcon}
					to={`/${PagesEnum.results}/${leagueRoute}`}
					text={pagesLang?.results?.title}
					defaultColor={
						!user || !leagues[leagueRoute]?.past_round?.rounds?.length
							? 'grey'
							: defaultColor
					}
					disabled={!user || !leagues[leagueRoute]?.past_round?.rounds?.length}
				/>
			</div>
			<div style={{ flex: 4 }}>
				<NavItem
					activeBg={theme.gold}
					onClick={disableLinkHandler}
					defaultColor={defaultColor}
					activeColor={activeColor}
					SvgIcon={PredictionsIcon}
					to={`/${PagesEnum.predictions}/${leagueRoute}`}
					text={pagesLang?.predictions?.title}
				/>
			</div>
			<div style={{ flex: 4 }}>
				<NavItem
					activeBg={theme.gold}
					onClick={disableLinkHandler}
					defaultColor={defaultColor}
					activeColor={activeColor}
					SvgIcon={LeaderboardIcon}
					stroke
					to={`/${PagesEnum.leaderboard}/${leagueRoute}`}
					text={pagesLang?.leaderboard?.title}
				/>
			</div>
			<div style={{ flex: 4 }}>
				<NavItem
					activeBg={theme.gold}
					onClick={disableLinkHandler}
					defaultColor={defaultColor}
					activeColor={activeColor}
					SvgIcon={ParlaysIcon}
					to={`/${PagesEnum.parlays}/${leagueRoute}`}
					text={pagesLang?.parlays?.title}
				/>
			</div>
			{showWarneModal && (
				<WarnModal
					texts={warnModalTexts}
					open={showWarneModal}
					onStay={onStayHandler}
					onLeave={onLeaveHandler}
				/>
			)}
		</S.FooterNavItems>
	);
};

export default FooterNavItemsComponent;
