import { Link } from 'react-router-dom';
import * as S from './HowToPlayLink.styles';
import { BlackHowToPlay } from 'shared/assets/images/icons/header';
import PagesEnum from 'shared/Enum/pages';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';

export const HowToPlayLink = (props) => {
	const theme = useTheme();
	const langState = useSelector(({ lang }) => lang);
	const howToPlayText = langState?.components?.header?.howToPlay;
	const { color } = props;

	return (
		<Link
			to={`/${PagesEnum.howToPlay}`}
			style={{
				color: props.howToPlayColor ? theme.secondary : theme.black,
				textDecoration: 'none',
			}}
		>
			<S.HowToPlay>
				<BlackHowToPlay fill={props.howToPlayColor} />
				<span>{howToPlayText}</span>
			</S.HowToPlay>
		</Link>
	);
};

export default HowToPlayLink;
