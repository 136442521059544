import * as S from './RoundIndicator.styles';
import { hasMatchEndedYet } from 'shared/utils/match-status';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';


const RoundIndicator = ({
	submissionResults,
	matches,
	hideText = false,
	width = 21,
	height = 13,
}) => {
	const theme = useTheme()
	// const {
	//   submissionResults,
	//   matches,
	//   hideText = false,
	//   width = 21,
	//   height = 13,
	// } = props;
	const langState = useSelector(({ lang }) => lang);
	const comonentTexts = langState?.components?.roundIndicator;
	const greenIndicator = {
		width,
		height,
		background: theme.roundIndicator.backgroundColor.exact,
		InnerShadow: 'rgba(255, 255, 255, 0.25)',
		boxShadow: 'inset 0px 4px 4px rgba(255, 255, 255, 0.25)',
		borderRadius: 5,
		marginRight: 3,
		marginTop: 5,
	};

	const pendingIndicator = {
		...greenIndicator,
		background: theme.roundIndicator.backgroundColor.pending,
	};

	const redIndicator = {
		...greenIndicator,
		background: theme.roundIndicator.backgroundColor.wrong,
	};

	const greenLightIndicator = {
		...greenIndicator,
		background: theme.roundIndicator.backgroundColor.correct,
	};

	const checkedPredictions = submissionResults?.reduce(
		(acc, curr) => acc + (curr.status !== 'pending' ? 1 : 0),
		0
	);
	const submissionSuccess = submissionResults?.filter((match) => {
		return match.status === 'success';
	});
	return (
		<div>
			{hideText ? (
				<S.Text>
					{submissionSuccess?.length || 0} / {matches?.length || 6}
				</S.Text>
			) : (
				<S.Text>
					{comonentTexts?.correctPredictions} {submissionSuccess?.length}{' '}
					{comonentTexts?.outOf}&nbsp;
					{checkedPredictions}
				</S.Text>
			)}
			<S.Indicator>
				{submissionResults &&
					submissionResults
						.sort(
							(a, b) => new Date(a.match.match_start) - new Date(b.match.match_start)
						)
						.map((singleMatch) => {
							if (
								singleMatch.status === 'pending' ||
								!matches?.find((m) => m.id === singleMatch?.matchId)?.matchScore
							) {
								return (
									<div key={singleMatch.id.toString()} style={pendingIndicator}></div>
								);
							} else if (singleMatch.successPoints === 5) {
								return (
									<div key={singleMatch.id.toString()} style={greenIndicator}></div>
								);
							} else if (singleMatch.successPoints === 2) {
								return (
									<div key={singleMatch.id.toString()} style={greenLightIndicator}></div>
								);
							} else if (singleMatch.successPoints === 0) {
								return <div key={singleMatch.id.toString()} style={redIndicator}></div>;
							} else {
								return [];
							}
						})}
			</S.Indicator>
		</div>
	);
};

export default RoundIndicator;
