import styled from 'styled-components';
import { desktopBg, mobileBg } from 'shared/assets/images/CountDown';

export const CountDownContainer = styled.div`
	width: 100%;
	max-width: ${({ atBottom }) => (atBottom ? '' : '40rem')};
	height: ${({ atBottom }) => (atBottom ? '3.75rem' : '')};
	padding: 0.4rem 0.4rem;
	gap: ${({ atBottom }) => (atBottom ? '0.5rem' : '')};
	position: relative;
	display: flex;
	flex-direction: ${({ atBottom }) => (atBottom ? 'row-reverse' : 'column')};
	align-items: center;
	justify-content: center;
	background-size: cover !important;
	background-repeat: no-repeat;
	background: ${({ counter, $isMobile, entriesUntilOrFrom, theme }) =>
		entriesUntilOrFrom === 'until'
			? $isMobile
				? `url(${counter?.mobileBg ? counter?.mobileBg : mobileBg})`
				: `url(${counter?.desktopBg ? counter?.desktopBg : desktopBg})`
			: theme?.counterPalette?.gradient};
	border-radius: ${({ $isMobile }) => ($isMobile ? '' : `0.6rem`)};
	font-family: ${({ counter }) => (counter?.font ? counter.font : 'inherit')};
`;

export const Xicon = styled.div`
	position: absolute;
	${(props) => props.$isMobile && { width: '.5rem' }}
	top: .1rem;
	right: 0.1rem;
	&:hover {
		cursor: pointer;
	}
`;

export const RightLittleSquere = styled.div`
	position: absolute;
	top: 45%;
	right: 0;
	width: ${({ atBottom }) => (atBottom ? '0.1rem' : '0.125rem')};
	height: ${({ atBottom }) => (atBottom ? '0.35rem' : '0.75rem')};
	background-color: ${({ theme }) => theme.white};
`;
export const LeftLittleSquere = styled.div`
	position: absolute;
	top: 45%;
	left: 0;
	width: ${({ atBottom }) => (atBottom ? '0.1rem' : '0.125rem')};
	height: ${({ atBottom }) => (atBottom ? '0.35rem' : '0.75rem')};
	background-color: ${({ theme }) => theme.white};
`;

export const Title = styled.div`
	display: flex;
	justify-content: center;
	color: ${({ theme }) => theme.counterPalette.title};
	font-size: ${({ $isMobile }) => ($isMobile ? '1em' : '1.5em')};
	line-height: ${({ $isMobile }) => ($isMobile ? '1.18em' : '1.8em')};
	font-weight: ${({ $isMobile }) => ($isMobile ? '500' : '400')};
`;

export const Description = styled.div`
	display: flex;
	justify-content: center;
	font-weight: ${({ atBottom }) => (atBottom ? '500' : '400')};
	font-size: ${({ atBottom }) => (atBottom ? ' 0.87em' : '1.25em')};
	line-height: ${({ atBottom }) => (atBottom ? ' 1rem' : '1.4rem')};
	color: ${({ theme }) => theme.counterPalette.description};
`;

export const ClockContainer = styled.div`
	display: flex;
`;

export const TimerItemBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	/* font-family: 'Roboto-Condensed'; */
`;

export const TimeLeft = styled.div`
	background: ${({ theme }) => theme.counterPalette.timePeriod};
	font-weight: ${({ atBottom }) => (atBottom ? '200' : '300')};
	font-size: ${({ atBottom }) => (atBottom ? '1.6em' : '3em')};
	line-height: ${({ atBottom }) => (atBottom ? '2rem' : '5.1rem')};
	margin: 0.3rem;
	width: ${({ atBottom, $isMobile }) =>
		$isMobile ? (atBottom ? '' : ' 4.5rem') : '5rem'};
	display: flex;
	align-items: center;
	justify-content: center;
	letter-spacing: -0.05em;
	color: ${({ theme }) => theme.counterPalette.clockNumbers};
	border-radius: 0.25rem;
	border: 0.06rem solid white;
	box-sizing: border-box;
	padding: 0 0.3rem;
	position: relative;
	font-family: ${({ fontFamily }) =>
		fontFamily?.light ? fontFamily.light : fontFamily};
`;
