export const INIT_STATE = 'INIT_STATE';
export const LOGOUT = 'LOGOUT';
export const TOGGLE_ASKED_FOR_NICKNAME = 'TOGGLE_ASKED_FOR_NICKNAME';
export const SET_AXIOS_INSTANCE = 'SET_AXIOS_INSTANCE';
export const TOGGLE_SIDE_NAV = 'TOGGLE_SIDE_NAV';
export const TOGGLE_EDIT_MODE = 'TOGGLE_EDIT_MODE';
export const TOGGLE_MODIFY_MODE = 'TOGGLE_MODIFY_MODE';
export const TOGGLE_HAS_ROUND_STARTED = 'TOGGLE_HAS_ROUND_STARTED';
export const TOGGLE_MUTATE_ODDS_FLAG = 'TOGGLE_MUTATE_ODDS_FLAG';
export const SET_USER = 'SET_USER';
export const SET_SAVED_PREDICTIONS = 'SET_SAVED_PREDICTIONS';
export const UPDATE_SAVED_PREDICTIONS = 'UPDATE_SAVED_PREDICTIONS';
export const SET_BANNERS = 'SET_BANNERS';
export const SET_DEVICE = 'SET_DEVICE';
export const SET_HEADER = 'SET_HEADER';
export const UPDATE_HEADER = 'UPDATE_HEADER';
export const SET_INITIAL_LEAGUES = 'SET_INITIAL_LEAGUES';
export const SET_SELECTED_LEAGUE_TAB = 'SET_SELECTED_LEAGUE_TAB';
export const SET_SELECTED_MARKET_TAB = 'SET_SELECTED_MARKET_TAB';
export const SET_SELECTED_PARLAYS = 'SET_SELECTED_PARLAYS';
export const SET_SAVED_ODDS = 'SET_SAVED_ODDS';
export const SET_SCREENSHOT_REF = 'SET_SCREENSHOT_REF';
export const SET_COUNTER = 'SET_COUNTER';
export const SET_SHOW_DISCLAIMER = 'SET_SHOW_DISCLAIMER';
export const SET_SHOW_NICKNAME = 'SET_SHOW_NICKNAME';
export const SET_SHOW_REGISTER = 'SET_SHOW_REGISTER';
export const SET_SHOW_LOGIN = 'SET_SHOW_LOGIN';

export const initState = () => {
	return { type: INIT_STATE };
};

export const logoutHandler = () => {
	return { type: LOGOUT };
};

export const setDevice = (device = null) => {
	return { type: SET_DEVICE, payload: device };
};

export const toggleSideNavHandler = (toggle = null) => {
	return { type: TOGGLE_SIDE_NAV, payload: toggle };
};

export const setScreenshotRef = (ref = null) => {
	return { type: SET_SCREENSHOT_REF, payload: ref.current };
};
export const toggleAskedForNicknameHandler = (askedForNickname = null) => {
	return { type: TOGGLE_ASKED_FOR_NICKNAME, payload: askedForNickname };
};

export const toggleEditModeHandler = (editMode = null) => {
	return { type: TOGGLE_EDIT_MODE, payload: editMode };
};
export const toggleModifyModeHandler = (modifyMode = null) => {
	return { type: TOGGLE_MODIFY_MODE, payload: modifyMode };
};

export const toggleMutateOdds = (flag = null) => {
	return { type: TOGGLE_MUTATE_ODDS_FLAG, payload: flag };
};

export const toggleHasRoundStarterHandler = (hasRoundStarted = null) => {
	return { type: TOGGLE_HAS_ROUND_STARTED, payload: hasRoundStarted };
};

export const setUserHandler = (user) => {
	return { type: SET_USER, payload: user };
};

export const setSavedPredictionsHandler = (
	leagueName,
	savedPredictions = []
) => {
	return {
		type: SET_SAVED_PREDICTIONS,
		payload: { leagueName, savedPredictions },
	};
};

export const updateSavedPredictionsHandler = (leagueName, prediction = {}) => {
	return {
		type: UPDATE_SAVED_PREDICTIONS,
		payload: { leagueName, prediction },
	};
};

export const setBannersHandler = (banners = {}) => {
	return { type: SET_BANNERS, payload: banners };
};

export const setCounter = (counterConfig = null) => {
	return { type: SET_COUNTER, payload: counterConfig };
};

export const setHeader = (headerConfig = {}) => {
	return { type: SET_HEADER, payload: headerConfig };
};

export const updateHeader = (headerProp = {}) => {
	return { type: UPDATE_HEADER, payload: headerProp };
};

export const setInitialLeagues = (leagues = []) => {
	return { type: SET_INITIAL_LEAGUES, payload: leagues };
};

export const setSelectedLeagueTab = (tab = 0) => {
	return { type: SET_SELECTED_LEAGUE_TAB, payload: tab };
};

export const setSelectedMarketTab = (tab = 0) => {
	return { type: SET_SELECTED_MARKET_TAB, payload: tab };
};

export const setSelectedParlays = (parlays = []) => {
	return { type: SET_SELECTED_PARLAYS, payload: parlays };
};

export const setSavedOdds = (league = '', parlays = []) => {
	return { type: SET_SAVED_ODDS, payload: { league, parlays } };
};

export const setAxiosInstance = (axiosInstance = null) => {
	return { type: SET_AXIOS_INSTANCE, payload: axiosInstance };
};

export const setShowNicknameModal = (showNicknameModal) => {
	return { type: SET_SHOW_NICKNAME, payload: showNicknameModal };
};

export const setShowDisclaimerModal = (showDisclaimerModal) => {
	return { type: SET_SHOW_DISCLAIMER, payload: showDisclaimerModal };
};

export const setShowRegisterModal = (showRegisterModal) => {
	return { type: SET_SHOW_REGISTER, payload: showRegisterModal };
};

export const setShowLoginModal = (showLoginModal) => {
	return { type: SET_SHOW_LOGIN, payload: showLoginModal };
};
