import * as S from './CountDown.styles';
import React from 'react';
import './CountDown.css';
import { useSelector } from 'react-redux';

const shortTimeFormat = ['DAYS', 'HRS', 'MIN', 'SEC'];
const longTimeFormat = ['DAYS', 'HOURS', 'MINUTES', 'SECONDS'];

export const Timer = ({
	clockItem,
	index,
	timerTimeLeft,
	isMobile,
	atBottom,
	fontFamily,
}) => {
	const langState = useSelector(({ lang }) => lang);
	const shortTimePeriod = langState?.components?.countDown?.timePeriod_short
		? langState?.components?.countDown?.timePeriod_short
		: shortTimeFormat;
	const longTimePeriod = langState?.components?.countDown?.timePeriod_long
		? langState?.components?.countDown?.timePeriod_long
		: longTimeFormat;
	return (
		<S.TimerItemBox key={index}>
			<S.TimeLeft atBottom={atBottom} $isMobile={isMobile} fontFamily={fontFamily}>
				{timerTimeLeft[clockItem]}
				<S.RightLittleSquere atBottom={atBottom} />
				<S.LeftLittleSquere atBottom={atBottom} />
			</S.TimeLeft>
			<div className="clock-timeText__CountDown">
				{isMobile ? shortTimePeriod[index] : longTimePeriod[index]}
			</div>
		</S.TimerItemBox>
	);
};

export default React.memo(Timer);
