import styled from 'styled-components';

export const Image = styled.img`
	max-width: 3rem;
	max-height: 3rem;
`;

export const Text = styled.p`
	color: ${({ theme }) => theme.secondary};
	/* color: ${(props) => (props.color ? props.color : 'rgb(53,140,246)')}; */
	font-size: 0.75em;
	text-align: center;
	margin-bottom: 0;
`;
