const MarketNames = {
	1: { name: '1x2', answers: ['Home', 'Draw', 'Away'] },
	2: {
		name: 'O-U 2.5 Goals',
		answers: ['Over', 'Under'],
	},
	3: { name: 'BTTS', answers: ['Yes', 'No'] },
};

module.exports = MarketNames;
