import * as S from './ParlyasMatch.styles';
import { Col } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import { Vi, ViCircle } from 'shared/assets/images/Icons/parlays';
import { useTheme } from 'styled-components';
import { useState } from 'react';
import { useWindowResize } from 'shared/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMutateOdds } from 'shared/store/global/globalActions';

const ParlaysMatch = ({
	odd,
	market,
	leagueName,
	isLast,
	factorHandler,
	campaignRounds,
}) => {
	// hooks
	const dispatch = useDispatch();
	const theme = useTheme();
	const queryClient = useQueryClient();
	const { isMobile } = useWindowResize();
	const { mutateOddsFlag } = useSelector(({ global }) => global);

	// states
	const [homeTeamImage, setHomeTeamImage] = useState(odd.homeTeam.shirt_img);
	const [awayTeamImage, setAwayTeamImage] = useState(odd.awayTeam.shirt_img);

	// functions
	/**
	 * locating the specific outcomes array and manipulate the cached query data
	 */
	const clickParlayHandler = (value) => {
		/*
		 * turns off mutate odds fetching
		 * if custom parlays are selected
		 */
		if (mutateOddsFlag) {
			dispatch(toggleMutateOdds(false));
		}
		queryClient.setQueryData(
			['odds', campaignRounds, process.env.NX_oddsEndpoint],
			(oldOdds) => {
				if (oldOdds && oldOdds[leagueName]) {
					let leagueOdds = oldOdds[leagueName];
					const oddIndex = oldOdds[leagueName].findIndex(
						(cachedOdd) => cachedOdd.id === odd.id
					);
					const marketIndex = leagueOdds[oddIndex].markets.findIndex(
						(cachedMarket) => cachedMarket.marketType === market.marketType
					);
					leagueOdds[oddIndex].markets[marketIndex].possibleOutcomes = leagueOdds[
						oddIndex
					].markets[marketIndex].possibleOutcomes.map((outcome) => {
						if (outcome.id === value.outcomeId) {
							outcome.isSelected = !outcome.isSelected;
						} else {
							outcome.isSelected = false;
						}
						return outcome;
					});

					oldOdds[leagueName] = leagueOdds;
					return oldOdds;
				}
			}
		);
		factorHandler();
	};

	// jsx
	return (
		<S.ParlaysMatch>
			<Col xs={4} md={5}>
				<S.TeamsRowWrapper>
					<S.Team>
						<S.SmallShirt
							src={`${homeTeamImage}?not-from-cache-please`}
							onError={() => setHomeTeamImage(odd.homeTeam.flag_img)}
							alt="small shirt"
						/>
						<S.TeamName isMobile={isMobile}>{odd.homeTeam.displayName}</S.TeamName>
					</S.Team>
					<S.Team>
						<S.SmallShirt
							src={`${awayTeamImage}?not-from-cache-please`}
							onError={() => setAwayTeamImage(odd.awayTeam.flag_img)}
							alt="small shirt"
						/>
						<S.TeamName isMobile={isMobile}>{odd.awayTeam.displayName}</S.TeamName>
					</S.Team>
				</S.TeamsRowWrapper>
			</Col>
			{market?.possibleOutcomes?.map((outcome) => (
				<Col key={outcome.id}>
					<S.OddButton
						isMobile={isMobile}
						onClick={() =>
							clickParlayHandler({
								id: odd.id,
								outcomeId: outcome.id,
								market: market.marketType,
							})
						}
					>
						{outcome.isSelected ? (
							<S.ViBox>
								<ViCircle fill={theme.checkedSvgPalette.circleFillColor} style={{ position: 'absolute' }} />
								<Vi fill={theme.checkedSvgPalette.vFillColor} style={{ position: 'absolute' }} />
							</S.ViBox>
						) : (
							outcome.odds
						)}
					</S.OddButton>
				</Col>
			))}
			{!isLast && <S.StyledHr $cancelMargins />}
		</S.ParlaysMatch>
	);
};

export default ParlaysMatch;
