import { ErrorPage } from '@hook-apps-client/ui';
import { ErrorBoundary } from 'react-error-boundary';
import { useQueryErrorResetBoundary } from 'react-query';
import { Warnning } from 'shared/assets/images/ErrorPage';
import retryIcon from 'shared/assets/images/ErrorPage/retry.svg';

const Auth = ({ children }) => {
	const { reset } = useQueryErrorResetBoundary();
	return (
		<ErrorBoundary
			onReset={reset}
			fallbackRender={({ resetErrorBoundary }) => (
				<ErrorPage
					ErrorSvg={Warnning}
					title={'Page not found'}
					description={
						'The page you are looking for doesn’t exist or an other error occurred.'
					}
					btnTitle={'Try Again'}
					btnIcon={retryIcon}
					clickHandler={resetErrorBoundary}
				/>
			)}
		>
			{children}
		</ErrorBoundary>
	);
};

export default Auth;
