import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	gap: 2.5rem;
	margin-top: 1rem;
`;

export const IconsWrapper = styled.div`
	cursor: pointer;
	background-color: ${({ theme }) =>
		theme.shareModalPalette.socialButton.backgroundColor};
	border: ${({ theme }) =>
		`${theme.shareModalPalette.socialButton.borderColor} 1px solid`};
	box-sizing: border-box;
	box-shadow: 0px 1px 4px rgba(150, 150, 150, 0.15);
	border-radius: 0.75rem;
	padding: 0.4rem 1rem;
`;

export const Icon = styled.img`
	width: 1.87rem;
	height: 1.87rem;
`;

export const Text = styled.p`
	font-size: 0.75rem;
	color: #353d49;
	margin-top: 0.5rem;
	text-align: center;
	color: ${({ theme }) => theme.shareModalPalette.socialButton.textColor};
`;
