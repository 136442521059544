import { color } from '@mui/system';

const colors = {
	transparent: 'transparent',
	primaryWhite: '#ffffff',
	secondaryWhite: '#DADADA',
	fadedWhite: '#ffffff4d',
	primaryGreen: '#8bd606',
	secondaryGreen: '#40AC25',
	primaryYellow: '#ffd600',
	primaryPink: '#EA336A',
	primaryOrange: '#F8A300',
	primaryBrown: '#674003',
	primaryRed: '#AF1C1C',
	primaryBlue: '#75FBDA',
	primaryBlack: '#000000',
	fadedBlack: '#00000099',
	primaryGrey: '#343434b3',
	secondaryGrey: '#1F1F1F',
	thirdGrey: '#202020',
	fourthGrey: '#4B4B4B',
	fadedGrey: '#70707066',
	primaryLightGrey: '#ffffff80',
	secondaryBlack: '#353535',
	lightGrey: '#c2c2c2',
	secondaryLightGray: '#AFAFAF',
	thirdLightGray: '#b7b7b7',
	forthLightGray: '#D6D6D6',
	fifthLightGray: '#34343499',
	sixthLightGrey: '#2C2C2C',
	lightGreen: '#73E9CC',
	darkYellow: '#ae7400',
	darkBlue: '#75FBDA',
	darkGrey: '#ffffff33',
	secondaryDarkGrey: '#282828',
	darkGreen: '#17715B',
	primaryGold: '#B4A669',
	fadedOrange: 'rgba(248, 163, 0, 0.05)',
	fadedGold: '#A89D62',
	greenGradient: 'linear-gradient(135.17deg, #17715B 0.03%, #125545 99.73%)',
	counterGradient: 'linear-gradient(99.83deg, #84263c 0.44%, #48121f 93.75%)',
	timePeriodGradient:
		'linear-gradient(224.23deg, #DCCF93 0%, #ECDFA3 18.84%, #F4E8B0 40.95%, #D1C689 65.43%, #B3A76C 85.93%, #968B51 100.88%)',
	counterNumbers: '#067765',
	goldGradient:
		'linear-gradient(224.23deg, #DCCF93 0%, #ECDFA3 18.84%, #F4E8B0 40.95%, #D1C689 65.43%, #B3A76C 85.93%, #968B51 100.88%)',
	secondGoldGradient:
		'linear-gradient(217.16deg, #E0D397 0.75%, #E8DDA0 24.01%, #F3E6AD 50.37%, #CCC085 79.32%, #A1965B 100%)',
	backdropColor: 'transparent',
	secondGreenGradient:
		'linear-gradient(135.17deg, #73E9CC 0.21%, #17715B 99.91%)',
};
type TDesktopSideNavItems = {
	defaultColor: string;
	activeColor: string;
};

type TModal = {
	bgColor: string;
	backButtonFillColor: string;
	authFooterBgColor: string;
};

type TVsContainer = {
	timeColor: string;
	vsColor: string;
};

type TPostponed = {
	color: string;
	background: string;
	border: string;
};

type TBetController = {
	arrowFillColor: string;
};

type TForm = {
	inputBgColor: string;
	inputPlaceholderColor: string;
	inputColor: string;
	labelColor: string;
	errorColor: string;
	headerButtonTextColor: string;
	unCheckedRadioBtnColor: string;
	checkedRadioBtnColor: string;
	radioBtnLabelColor: string;
	formLink: string;
	formTextColor: string;
};

type TFooter = {
	backgroundColor: string;
	defaultColor: string;
	activeColor: string;
};

type TDesktopHeader = {
	backgroundColor: string;
	title: string;
	description: string;
	howtoplayLink: string;
};

type TMobileHeader = {
	backgroundColor: string;
	headerTabs: {
		inActiveColor: string;
		activeColor: string;
	};
};

type TResultsDetailsHeader = {
	backgroundColor: string;
	backgroundColorMobile: string;
	title: string;
	titleMobile: string;
	description: string;
	descriptionMobile: string;
	backArrow: string;
};

type TResults = {
	summarySection: {
		backgroundColor: string | false;
	};
};

type TMatchIndicator = {
	backgroundColor: {
		exact: string;
		correct: string;
	};
	textColor: {
		pointsNum: string;
		pointsText: string;
		correctText: string;
	};
};
type TRoundIndicator = {
	backgroundColor: {
		exact: string;
		correct: string;
		wrong: string;
		pending: string;
	};
};
type TRoundSummary = {
	backgroundColor: string;
	titleColor: string;
	textColor: string;
	border: {
		borderColor: string;
		borderWidth: string;
	};
};

type TLeaderboardPicker = {
	backgroundColor: string;
	activeBackgroundColor: string;
	defaultColor: string;
	activeColor: string;
	pickerModal: {
		backDropBgColor: string;
		scrollBarColor: string;
	};
};

type TLeaderboardTable = {
	titles: string;
	titlesHr: string;
	content: string;
	contentHr: string;
};
type TScoreBox = {
	scoreDefaultColor: string;
	boxBgColor: string;
	boxBorderColor: string;
	wrongScoreColor: string;
	correctScoreColor: string;
};

type TCheckedSvg = {
	circleFillColor: string;
	vFillColor: string;
};

type TCounter = {
	title: string;
	description: string;
	clockNumbers: string;
	timePeriod: string;
	gradient: string;
};

type TStepperReg = {
	labelColor: string;
	activeColor: string;
	completedColor: string;
};

type TEditBtn = {
	textColor: string;
	disabledTextColor: string;
	bgColor: string;
	disabledBgColor: string;
};

type TSubmitButton = {
	backgroundColor: {
		active: string;
		inActive: string;
	};
	textColor: {
		active: string;
		inActive: string;
	};
};

type TLeaderBoardButton = {
	backgroundColor: string;
	textColor: string;
	border: {
		borderWidth: string;
		borderColor: string;
		borderRadius: string;
	};
};

type TNickNameModal = {
	backgroundColor: string;
	titleColor: string;
	subtitleColor: string;
	submitButton: {
		backgroundColor: {
			active: string;
			inActive: string;
		};
		textColor: {
			active: string;
			inActive: string;
		};
	};
	warningTextColor: string;
	input: {
		inputFocusedBorderColor: string;
		labelFocusedTextColor: string;
		inputTextColor: string;
		labelTextColor: string;
		inputBorderColor: string;
	};
	approvedNicknameIndicator: {
		approvedIconColor: string;
		approvedTextColor: string;
		approvedIconBackgroundColor: string;
	};
	exitButtonColor: string;
};

type TWarningModal = {
	border: {
		renderBorder: boolean;
		borderColor: string;
		borderWidth: string;
	};
	backgroundColor: string;
	titleColor: string;
	subtitleColor: string;
	submitButton: {
		backgroundColor: {
			unHovered: string;
			hover: string;
		};
		textColor: {
			unHovered: string;
			hover: string;
		};
	};
};
type TShareModal = {
	backgroundColor: string;
	titleColor: string;
	socialButton: {
		backgroundColor: string;
		textColor: string;
		borderColor: string;
	};
	screenShotBorderColor: string;
	exitButtonColor: string;
	downloadOverlay: {
		textColor: string;
		svgFillColor: string;
		backgroundColor: string;
	};
	link: {
		backgroundColor: string;
		textColor: {
			hover: string;
			unHovered: string;
		};
		borderColor: string;
	};
	copyBtn: {
		backgroundColor: string;
		textColor: string;
		borderColor: string;
	};
};

type TAccumulator = {
	numbers: string;
	potentialGain: string;
	accumulator: string;
	returns: string;
	currencySymbol: string;
};

type TPallete = {
	primary: string;
	secondary: string;
	appBg: string;
	appSectionsBg: string;
	styledHr: string;
	desktopNavBg: string;
	desktopNavItems: TDesktopSideNavItems;
	howToPlayIcon: string;
	modalPalette: TModal;
	vsContainerPalette: TVsContainer;
	postponed: TPostponed;
	betControllerPalette: TBetController;
	formPalette: TForm;
	footerPalette: TFooter;
	desktopHeader: TDesktopHeader;
	mobileHeader: TMobileHeader;
	leaderboardPicker: TLeaderboardPicker;
	leaderboardTable: TLeaderboardTable;
	scoreBoxPalette: TScoreBox;
	checkedSvgPalette: TCheckedSvg;
	resultsDetailsHeader: TResultsDetailsHeader;
	resultsPalette: TResults;
	matchIndicator: TMatchIndicator;
	roundIndicator: TRoundIndicator;
	roundSummary: TRoundSummary;
	nickNameModalPalette: TNickNameModal;
	warningModalPalette: TWarningModal;
	shareModalPalette: TShareModal;
	resultsDetailsIndicatorBg: string;
	mainTypography: string;
	mainTypographyDisabled: string;
	secondaryTypography: string;
	thirdlyTypography: string;
	btnDisabled: string;
	editBtn: TEditBtn;
	submitButton: TSubmitButton;
	leaderBoardButton: TLeaderBoardButton;
	counterPalette: TCounter;
	stepperRegistrationPalette: TStepperReg;
	primaryDark: string;
	black: string;
	white: string;
	grey: string;
	lightGrey: string;
	darkGrey: string;
	secondaryBlack: string;
	green: string;
	red: string;
	gold: string;
	accumulator: TAccumulator;
};

export const palette: TPallete = {
	primary: colors.primaryBlack,
	secondary: colors.primaryWhite,
	appBg: colors.primaryBlack,
	appSectionsBg: colors.primaryGrey,
	styledHr: colors.fourthGrey,
	mainTypography: colors.primaryWhite,
	mainTypographyDisabled: colors.darkGrey,
	secondaryTypography: colors.primaryWhite,
	thirdlyTypography: colors.thirdLightGray,
	btnDisabled: colors.secondaryBlack,
	editBtn: {
		textColor: colors.primaryWhite,
		disabledTextColor: colors.primaryWhite,
		bgColor: colors.primaryBlack,
		disabledBgColor: colors.primaryBlack,
	},
	submitButton: {
		backgroundColor: {
			active: colors.primaryBlue,
			inActive: colors.secondaryBlack,
		},
		textColor: {
			active: colors.primaryBlack,
			inActive: colors.primaryBlack,
		},
	},
	leaderBoardButton: {
		backgroundColor: colors.primaryBlue,
		textColor: colors.primaryBlack,
		border: {
			borderWidth: '0',
			borderColor: colors.transparent,
			borderRadius: '.625rem',
		},
	},
	counterPalette: {
		title: colors.primaryBlack,
		description: colors.primaryWhite,
		clockNumbers: colors.counterNumbers,
		timePeriod: colors.timePeriodGradient,
		gradient: colors.counterGradient,
	},
	accumulator: {
		numbers: colors.primaryBlue,
		potentialGain: colors.primaryLightGrey,
		accumulator: colors.primaryWhite,
		returns: colors.primaryWhite,
		currencySymbol: colors.primaryWhite,
	},
	stepperRegistrationPalette: {
		activeColor: colors.primaryYellow,
		completedColor: colors.primaryYellow,
		labelColor: colors.primaryWhite,
	},
	desktopNavBg: colors.primaryBlack,
	desktopNavItems: {
		activeColor: colors.primaryBlack,
		defaultColor: colors.primaryWhite,
	},
	howToPlayIcon: colors.primaryBlue,
	modalPalette: {
		bgColor: colors.primaryLightGrey,
		backButtonFillColor: colors.lightGrey,
		authFooterBgColor: colors.secondaryGrey,
	},
	vsContainerPalette: {
		timeColor: colors.primaryBlue,
		vsColor: colors.primaryBlue,
	},
	postponed: {
		background: colors.fadedOrange,
		color: colors.primaryOrange,
		border: colors.primaryOrange,
	},
	betControllerPalette: {
		arrowFillColor: colors.primaryBlue,
	},
	formPalette: {
		inputBgColor: colors.secondaryGrey,
		inputPlaceholderColor: colors.lightGrey,
		inputColor: colors.primaryWhite,
		labelColor: colors.primaryWhite,
		errorColor: colors.primaryRed,
		headerButtonTextColor: colors.primaryYellow,
		unCheckedRadioBtnColor: colors.lightGrey,
		checkedRadioBtnColor: colors.primaryYellow,
		radioBtnLabelColor: colors.primaryWhite,
		formTextColor: colors.primaryWhite,
		formLink: colors.primaryYellow,
	},
	footerPalette: {
		activeColor: colors.primaryBlack,
		backgroundColor: colors.primaryBlack,
		defaultColor: colors.primaryWhite,
	},
	desktopHeader: {
		backgroundColor: colors.secondaryGrey,
		title: colors.primaryWhite,
		description: colors.lightGrey,
		howtoplayLink: colors.primaryBlue,
	},
	mobileHeader: {
		backgroundColor: colors.secondaryGrey,
		headerTabs: {
			inActiveColor: colors.primaryLightGrey,
			activeColor: colors.primaryWhite,
		},
	},
	leaderboardPicker: {
		backgroundColor: colors.primaryBlack,
		activeBackgroundColor: colors.thirdGrey,
		activeColor: colors.primaryBlue,
		defaultColor: colors.secondaryLightGray,
		pickerModal: {
			backDropBgColor: colors.backdropColor,
			scrollBarColor: colors.primaryBlue,
		},
	},
	leaderboardTable: {
		titles: colors.primaryWhite,
		titlesHr: colors.fourthGrey,
		content: colors.primaryWhite,
		contentHr: colors.primaryBlack,
	},
	scoreBoxPalette: {
		boxBgColor: colors.transparent,
		scoreDefaultColor: colors.primaryWhite,
		boxBorderColor: colors.transparent,
		wrongScoreColor: colors.primaryRed,
		correctScoreColor: colors.darkGreen,
	},
	checkedSvgPalette: {
		circleFillColor: colors.primaryBlue,
		vFillColor: colors.primaryBlack,
	},
	resultsDetailsHeader: {
		backgroundColor: colors.primaryBlack,
		backgroundColorMobile: colors.primaryBlack,
		title: colors.secondaryWhite,
		titleMobile: colors.secondaryWhite,
		description: colors.primaryWhite,
		descriptionMobile: colors.primaryWhite,
		backArrow: colors.primaryWhite,
	},
	resultsPalette: {
		summarySection: {
			backgroundColor: colors.fifthLightGray,
		},
	},
	matchIndicator: {
		backgroundColor: {
			exact: colors.greenGradient,
			correct: colors.secondGreenGradient,
		},
		textColor: {
			pointsNum: colors.primaryWhite,
			pointsText: colors.primaryWhite,
			correctText: colors.primaryBlack,
		},
	},
	roundIndicator: {
		backgroundColor: {
			exact: colors.darkGreen,
			correct: colors.lightGreen,
			wrong: colors.primaryRed,
			pending: colors.forthLightGray,
		},
	},
	roundSummary: {
		backgroundColor: colors.primaryBlack,
		titleColor: colors.primaryWhite,
		textColor: colors.primaryWhite,
		border: {
			borderColor: colors.sixthLightGrey,
			borderWidth: '1px',
		},
	},
	nickNameModalPalette: {
		backgroundColor: colors.goldGradient,
		titleColor: colors.primaryBlack,
		subtitleColor: colors.primaryBlack,
		submitButton: {
			backgroundColor: {
				active: colors.primaryWhite,
				inActive: colors.fadedWhite,
			},
			textColor: {
				active: colors.primaryBlack,
				inActive: colors.fadedGold,
			},
		},
		warningTextColor: colors.primaryBlack,
		input: {
			inputFocusedBorderColor: colors.primaryBlack,
			inputBorderColor: colors.primaryBlack,
			labelFocusedTextColor: colors.primaryBlack,
			labelTextColor: colors.primaryBlack,
			inputTextColor: colors.primaryBlack,
		},
		approvedNicknameIndicator: {
			approvedIconColor: colors.primaryWhite,
			approvedTextColor: colors.secondaryGreen,
			approvedIconBackgroundColor: colors.secondaryGreen,
		},
		exitButtonColor: colors.primaryBlack,
	},
	warningModalPalette: {
		border: {
			renderBorder: true,
			borderColor: colors.primaryWhite,
			borderWidth: '2px',
		},
		backgroundColor: colors.goldGradient,
		titleColor: colors.primaryWhite,
		subtitleColor: colors.primaryBlack,
		submitButton: {
			backgroundColor: {
				unHovered: colors.transparent,
				hover: colors.primaryWhite,
			},
			textColor: {
				unHovered: colors.primaryWhite,
				hover: colors.primaryGold,
			},
		},
	},
	shareModalPalette: {
		backgroundColor: colors.secondGoldGradient,
		titleColor: colors.primaryBlack,
		socialButton: {
			backgroundColor: colors.primaryWhite,
			textColor: colors.primaryBlack,
			borderColor: colors.transparent,
		},
		screenShotBorderColor: colors.transparent,
		exitButtonColor: colors.primaryBlack,
		downloadOverlay: {
			textColor: colors.primaryWhite,
			svgFillColor: colors.primaryWhite,
			backgroundColor: colors.fadedBlack,
		},
		link: {
			backgroundColor: colors.fadedGrey,
			textColor: {
				hover: colors.primaryWhite,
				unHovered: colors.primaryBlack,
			},
			borderColor: colors.primaryBlack,
		},
		copyBtn: {
			backgroundColor: colors.primaryWhite,
			textColor: colors.primaryBlack,
			borderColor: colors.transparent,
		},
	},
	resultsDetailsIndicatorBg: colors.secondaryDarkGrey,
	primaryDark: colors.darkYellow,
	black: colors.primaryBlack,
	white: colors.primaryWhite,
	grey: colors.primaryGrey,
	lightGrey: colors.lightGrey,
	darkGrey: colors.darkGrey,
	secondaryBlack: colors.secondaryBlack,
	green: colors.primaryGreen,
	red: colors.primaryRed,
	gold: colors.goldGradient,
};
