import styled from 'styled-components';

export const Image = styled.div`
	max-width: 3.125rem;
	max-height: 3.125rem;
	width: 3.125rem;
	height: 3.125rem;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	&:hover {
		cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	}
`;

export const Text = styled.p`
	color: ${(props) => (props.color ? props.color : 'rgb(53,140,246)')};
	font-size: 0.75em;
	text-align: center;
	margin-bottom: 0;
`;
