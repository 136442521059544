import { useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as S from './LoginScreen.styles';
import { useDispatch, useSelector } from 'react-redux';
import { setHeader, setUserHandler } from 'shared/store/global/globalActions';
import { useMutateLogin } from 'shared/hooks';
import PagesEnum from 'shared/Enum/pages';
import { useQueryClient } from 'react-query';
import Form from '../Form';
import LoginForm from '../Form/forms/LoginForm';
import ReactGA from "react-ga";

export const LoginScreen = ({ clientLogo, isMobile }) => {
	// hooks

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const queryClient = useQueryClient();
	const {
		mutateAsync: mutateLogin,
		isLoading,
		isSuccess,
		error,
	} = useMutateLogin();
	const langState = useSelector(({ lang }) => lang);
	const { axiosInstance, selectedLeagueTab, device } = useSelector(
		({ global }) => global
	);

	// states
	const [errorMessage, setErrorMessage] = useState(null);

	// Constans
	const loginTexts = langState?.pages?.login;
	const loginFormDefaultValues = {
		email: '',
		password: '',
	};
	const jwt = localStorage.getItem('jwt-token');
	const leagues = queryClient.getQueryData(['leagues',jwt]);
	const leagueRoute = location.pathname.includes('bracket')
		? 'bracket'
		: leagues && Object.keys(leagues)[selectedLeagueTab]?.replace(' ', '-');

	// functions
	const inputsValidationHandler = (formData) => {
		//!some validation here
	};

	const handleSubmit = async (formData) => {
		inputsValidationHandler();
		setErrorMessage(null);
		const data = await mutateLogin({
			axiosInstance,
			body: {
				formData,
			},
		});
		if (typeof data === 'string') {
			//means the response returned with error message
			setErrorMessage(data);
			return;
		}
		if (data?.user) {
			// ReactGA.event({
			// 	category: `Login Success - ${device}`,
			// 	action: `${data.user?.email} - Logged in`,
			// });
			dispatch(setUserHandler(data.user));
			await queryClient.refetchQueries({ active: true });
			navigate(`/${PagesEnum.predictions}/${leagueRoute}`);
			// setIsLoading(false);
		}
	};

	// Life cycle
	useLayoutEffect(() => {
		dispatch(setHeader({ hideHeader: true }));
	}, [dispatch]);

	// jsx
	return (
		<S.Container isMobile={isMobile}>
			<S.LogoContainer isMobile={isMobile}>
				<S.LogoImage src={clientLogo} />
			</S.LogoContainer>
			<S.TextsBox>
				<S.H2>{loginTexts?.title}</S.H2>
				<S.H1>{loginTexts?.secondaryTitle}</S.H1>
			</S.TextsBox>
			<Form
				isMobile={isMobile}
				handleSubmit={handleSubmit}
				formDefaultValues={loginFormDefaultValues}
			>
				<LoginForm
					isLoading={(isLoading || isSuccess) && !errorMessage}
					error={errorMessage}
				/>
			</Form>
		</S.Container>
	);
};
export default LoginScreen;
