import styled from 'styled-components';
import { Container, StepLabel, Stepper, Typography } from '@mui/material';

export const RegistrationStepper = styled(Stepper)`
	margin-top: 2rem;
	.MuiSvgIcon-root {
		opacity: 0.6;
	}
	.MuiSvgIcon-root.Mui-active {
		opacity: 1;
		border-width: 2px;
		border-style: solid;
		border-color: ${({ theme }) => theme.stepperRegistrationPalette.activeColor};
		border-radius: 50%;
		color: transparent;
	}
	.MuiSvgIcon-root.Mui-completed {
		opacity: 1;
		color: ${({ theme }) => theme.stepperRegistrationPalette.completedColor};
	}
	.MuiStep-root {
		padding: 0;
	}
	.MuiStepLabel-iconContainer {
		padding: 0;
	}
`;

export const StepperLabelText = styled.h5`
	text-align: center;
	font-size: 0.7rem;
	margin: 0.6rem 0 0;
`;

export const RegStepLabel = styled(StepLabel)`
	flex-direction: column;
	padding: 0;
	.MuiStepLabel-label,
	.MuiStepLabel-label.Mui-completed,
	.MuiStepLabel-label.Mui-active {
		color: ${({ theme }) => theme.stepperRegistrationPalette.labelColor};
	}

	.MuiStepLabel-label.Mui-disabled {
		opacity: 0.6
	}
`;

export const RootContainer = styled(Container)`
	width: 85%;
	margin: 0;
	padding: 0;
`;

export const ButtonContainer = styled.div``;
