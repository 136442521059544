import axios, { AxiosInstance } from 'axios';
import { useMutation, useQuery } from 'react-query';

type TBody = {
	formData: {
		userName: string;
	};
};

type TParams = {
	axiosInstance?: AxiosInstance;
	body?: TBody;
};

type TError = {
	response: {
		data: {
			error: string;
		};
	};
};

export const checkUsername = async (params: TParams) => {
	try {
		const { axiosInstance, body } = params;
		const data = await axiosInstance?.post(`auth/check-delasport-username`, {
			formData: { username: body?.formData?.userName },
		});
		return data;
	} catch (err) {
		let _e = err as TError;
		let errorMessage = _e.response.data.error;
		return errorMessage;
	}
};

const useCheckUsername = () => {
	return useMutation(checkUsername, { mutationKey: 'mutateCheckUsername' });
};

export default useCheckUsername;
