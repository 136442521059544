import * as S from './BetController.styles';
import {
	DisabledDownArrow,
	DisabledUpArrow,
	DownArrow,
	UpArrow,
} from 'shared/assets/images/Arrows';
import isNullOrUndefined from 'shared/utils/is-null-or-undefined';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModifyModeHandler } from 'shared/store/global/globalActions';
import { useTheme } from 'styled-components';

const BetController = ({
	setGoals,
	goals,
	disabled = false,
	postponed = false,
}) => {
	// hooks
	const dispatch = useDispatch();
	const { editMode, modifyMode } = useSelector(({ global }) => global);
	const theme = useTheme();

	// functions
	const increment = () => {
		setGoals(!isNullOrUndefined(goals) ? goals + 1 : 0);
		if (editMode && !modifyMode) {
			dispatch(toggleModifyModeHandler(true));
		}
	};

	const decrement = () => {
		goals > 0 && setGoals(goals - 1);
		if (editMode && !modifyMode) {
			dispatch(toggleModifyModeHandler(true));
		}
	};

	// jsx
	return (
		<S.BetController>
			{!postponed && (
				<>
					<S.ArrowButton disabled={disabled} onClick={increment}>
						<UpArrow
							width={12}
							height={12}
							fill={
								disabled
									? theme.mainTypographyDisabled
									: theme.betControllerPalette.arrowFillColor
							}
						/>
					</S.ArrowButton>
					<S.Prediction disabled={disabled}>
						<S.PredictionNumber>{goals}</S.PredictionNumber>
					</S.Prediction>
					<S.ArrowButton disabled={disabled || !goals} onClick={decrement}>
						<DownArrow
							fill={
								disabled
									? theme.mainTypographyDisabled
									: theme.betControllerPalette.arrowFillColor
							}
						/>
					</S.ArrowButton>
				</>
			)}
		</S.BetController>
	);
};

export default BetController;
