import axios from 'axios';
// import { environment } from '../environments/environment';

export const axiosInstance = axios.create({
	baseURL: process.env.NX_baseUrl,
	headers: {
		common: {
			'jwt-token': localStorage.getItem('jwt-token'),
		},
	},
});

axiosInstance.interceptors.request.use(
	(req) => {
		req.headers['app-api-key'] = process.env.NX_appApiKey;
		if (
			localStorage.getItem('jwt-token') &&
			req.url !== '/auth/server-login' &&
			req.url !== '/server-api/authenticate-user'
		) {
			req.headers.common['jwt-token'] = localStorage.getItem('jwt-token');
		}
		return req;
	},
	(err) => {
		console.log(err);
		return Promise.reject(err);
	}
);
