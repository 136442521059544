import styled from 'styled-components';
import Button from '../../Button';

export const Paper = styled.div`
	position: relative;
	width: auto;
	min-width: 40%;
	display: flex;
	align-items: center;
	gap: 2.5rem;
	flex-direction: column;
	border-radius: 1.25rem;
	border: 1px solid #000;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	padding: 2rem;
	background: ${({ theme }) => theme.warningModalPalette.backgroundColor};
`;

export const Title = styled.h4`
	text-align: center;
	color: ${({ theme }) => theme.warningModalPalette.titleColor};
`;

export const Description = styled.p`
	text-align: center;
	color: ${({ theme }) => theme.warningModalPalette.subtitleColor};
`;

export const Row = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-around;
`;
export const ModalBtn = styled(Button)`
	background-color: ${({ theme }) =>
		theme.warningModalPalette.submitButton.backgroundColor.unHovered};
	color: ${({ theme }) =>
		theme.warningModalPalette.submitButton.textColor.unHovered};
	&:hover {
		cursor: pointer;
		background-color: ${({ theme }) =>
			theme.warningModalPalette.submitButton.backgroundColor.hover};
		color: ${({ theme }) =>
			theme.warningModalPalette.submitButton.textColor.hover};
	};
	border-width: ${({ theme }) =>
		theme.warningModalPalette.border.renderBorder ? theme.warningModalPalette.border.borderWidth : 0};
	border-color: ${({ theme }) =>
		theme.warningModalPalette.border.borderColor};
`;
