import { AxiosInstance } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';

type TParams = {
	axiosInstance?: AxiosInstance;
	nickname: string;
};

type TError = {
	response: {
		data: {
			error: string
		}
	}
}

export const setNickname = async (params: TParams) => {
	try {
		const { axiosInstance, nickname } = params;
		const data = await axiosInstance?.post(`api/set-nickname-to-user`, {
			nickname,
		});
    return data;
	} catch (err) {
		let _e = err as TError;
		let errorMessage = _e.response.data.error

		// throw err;
		return errorMessage
	}
};

const useMutateNickname = () => {
	const queryClient = useQueryClient();
	return useMutation(setNickname, { mutationKey: 'mutateNickname' });
};

export default useMutateNickname;
