import FormField from '../../components/FormField';
import FormSelect from '../../components/FormSelect';
import countries from './utils/countries.json';
import currencies from './utils/currencies.json';
import * as S from './SecondStep.styles';
import RadioBtns from '../../components/RadioBtns';
import FormPhoneInput from '../../components/FormPhone';
import FormDatePicker from '../../components/ FormDatePicker';
import { Checkbox } from '@mui/material';
import { useTheme } from 'styled-components';
import { Controller } from 'react-hook-form';

const genderArr = ['Male', 'Female'];

const SecondStep = () => {
	const theme = useTheme();

	return (
		<div style={{ marginTop: '20px' }}>
			<S.Gender>
				<RadioBtns
					name={'gender'}
					label={'gender'}
					type={'radio'}
					radioInputs={genderArr}
					inputType="genderRadio"
				/>
			</S.Gender>
			<div>
				<FormField
					label={'First Name (same as your ID)'}
					name={'firstName'}
					type={'text'}
					variant="outlined"
					rule={{ required: 'This Field Is Required' }}
				/>
				<FormField
					label={'Last Name (same as your ID)'}
					name={'lastName'}
					type={'text'}
					variant="outlined"
					rule={{ required: 'This Field Is Required' }}
				/>
			</div>
			<S.SelectRow>
				<S.FormSelectContainer>
					<FormSelect
						label={'Country'}
						name={'countries'}
						variant="outlined"
						rule={{ required: 'This Field Is Required' }}
						selectList={countries}
					/>
				</S.FormSelectContainer>
				<S.FormSelectContainer>
					<FormSelect
						label={'Currency'}
						name={'currencies'}
						variant="outlined"
						rule={{ required: 'This Field Is Required' }}
						selectList={currencies}
						value={'a'}
					/>
				</S.FormSelectContainer>
			</S.SelectRow>
			<FormPhoneInput
				label={'Cell Phone'}
				name={'cellPhone'}
				variant="outlined"
				rule={{
					required: 'This Field Is Required',
					pattern: {
						value: /^[0-9 ()+]+$/,
						message: 'invalid phone number',
					},
				}}
			/>
			<Controller
				name={'sms'}
				render={({ field: { onChange, onBlur, value, ref } }) => (
					<>
						<Checkbox
							sx={{
								alignSelf: 'flex-start',
								color: theme.secondary,
								'&.Mui-checked': {
									color: theme.secondary,
								},
							}}
							defaultChecked={true}
							checked={value}
							onChange={onChange}
							ref={ref}
							onBlur={onBlur}
							required={false}
						></Checkbox>
						<p
							style={{
								display: 'inline',
								fontSize: 12,
								marginLeft: 5,
								color: theme.white,
							}}
						>
							Send me Newsletters and SMS
						</p>
					</>
				)}
			/>
			{/* <FormDatePicker
				label={'Birthday'}
				name={'birthday'}
				variant="outlined"
				rule={{ required: 'This Field Is Required' }}
			/> */}
			<S.TncText>
				{'By clicking "Create new account" I confirm that I read and accept the '}
				<S.TncLink>{'Terms & Conditions '}</S.TncLink>
				{'and '}
				<S.TncLink>{'Privacy Policy'}</S.TncLink>
			</S.TncText>
		</div>
	);
};

export default SecondStep;
