import * as S from './FormDatePicker.styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Controller, useFormState } from 'react-hook-form';
import { TextField } from '@mui/material';
import { useTheme } from 'styled-components';

const FormDatePicker = ({ name, label, variant = 'outlined', type, rule }) => {
	const formState = useFormState();
	const theme = useTheme();

	return (
		<Controller
			name={name}
			render={({ field: { onChange, onBlur, value, ref } }) => (
				<>
					<S.Label
						sx={{
							color: theme.formPalette.labelColor,
							fontSize: '0.8rem',
							margin: '0.5rem 0 0',
						}}
					>
						{label}
					</S.Label>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<S.DatePicker
							renderInput={(params) => (
								<TextField
									{...params}
									fullWidth
									sx={{
										'& legend': { display: 'none' },
										'& fieldset': { top: 0 },
										svg: { color: theme.formPalette.inputPlaceholderColor },
										margin: '0.4rem 0 0.2rem 0'
									}}
								/>
							)}
							onChange={onChange}
							onBlur={onBlur}
							value={value}
							inputFormat="DD/MM/YYYY"
							ref={ref}
							variant={variant}
							placeholder={label}
							inputProps={{ sx: { color: theme.formPalette.inputPlaceholderColor } }}
							error={Boolean(formState.errors[name])}
							helpertext={formState.errors[name]?.message}
						/>
					</LocalizationProvider>
					{Boolean(formState.errors[name]) && (
						<S.Label
							sx={{
								color: theme.formPalette.errorColor,
								fontSize: '0.7rem',
								margin: '0 0 0.4rem',
							}}
						>
							{formState.errors[name]?.message}
						</S.Label>
					)}
				</>
			)}
			rules={rule}
		/>
	);
};

export default FormDatePicker;
