import { useEffect, useState } from 'react';
import * as S from './MobileShare.styles';
import { Modal, Slide } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { AppSpinner } from '../../AppSpinner/AppSpinner';
import ReactPortal from '../../ReactPortal/ReactPortal';
import CopyAnimation from '../CopyAnimation/CopyAnimation';
import SocialIcons from '../SocialIcons/SocialIcons';

const MobileShare = ({
	open,
	onClose,
	linkUrl,
	imageUrl,
	platforms = [],
	texts,
}) => {
	const [isCopied, setIsCopied] = useState(false);

	useEffect(() => {
		if (!open) {
			setTimeout(() => {
				setIsCopied(false);
			}, 300);
		}
	}, [open]);

	const copyToClipBoard = async () => {
		try {
			await navigator.clipboard.writeText(linkUrl);
			setIsCopied(true);
		} catch (err) {
			console.log(err);
		}
	};

	if (!linkUrl && !imageUrl) {
		return (
			<ReactPortal wrapperId={'mobile-share'}>
				<Slide direction="up" in={open}>
					<S.Container>
						<AppSpinner />
					</S.Container>
				</Slide>
			</ReactPortal>
		);
	}
	return (
		<ReactPortal wrapperId={'mobile-share'}>
			<Modal open={open} onClose={onClose}>
				<Slide direction="up" in={open}>
					<S.Container>
						<S.ExitBtn onClick={onClose}>
							<CloseIcon />
						</S.ExitBtn>
						<S.Title>{texts?.title}</S.Title>
						{linkUrl && (
							<S.CopyLinkSection>
								<S.Url2Share id="transition-modal-description">
									<S.Link href={linkUrl} target="_blank" rel="noreferrer">
										{linkUrl}
									</S.Link>
								</S.Url2Share>
								<S.CopyBtn onClick={copyToClipBoard} className="copyBtn__MobileShare">
									{isCopied ? <CopyAnimation /> : <div>{texts?.copyBtn}</div>}
								</S.CopyBtn>
							</S.CopyLinkSection>
						)}

						{imageUrl && (
							<S.SnapshotContainer className="snapShot_Box__MobileShare">
								<S.ImageWrapper className="img_box__MobileShare">
									<S.Screenshot src={imageUrl} alt="predictions snapshot" />
								</S.ImageWrapper>
							</S.SnapshotContainer>
						)}
						<SocialIcons payload={linkUrl ?? imageUrl} platforms={platforms} />
					</S.Container>
				</Slide>
			</Modal>
		</ReactPortal>
	);
};

export default MobileShare;
