import styled from 'styled-components';

export const Container = styled.div`
	position: absolute;
	z-index: 200;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	width: 100%;
	max-height: 31.25rem;
	min-height: 18.75rem;
	background: ${({ theme }) => theme.shareModalPalette.backgroundColor};
	box-shadow: 0px 4px 10px rgba(153, 153, 153, 0.25);
	border-radius: 0.7rem;
	padding: 2rem 6px;
	text-align: center;
	bottom: 0;
`;

export const ExitBtn = styled.button`
	position: absolute;
	top: 0.5rem;
	right: 0.5rem;
	background-color: transparent;
	color: ${({ theme }) => theme.shareModalPalette.exitButtonColor};
	border: none;
`;

export const Title = styled.h6`
	font-weight: bold;
	font-size: 1.15rem;
	color: ${({ theme }) => theme.shareModalPalette.titleColor};
`;

export const CopyLinkSection = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 0.3rem;
	align-items: center;
`;

export const Url2Share = styled.div`
	background-color: ${({ theme }) => theme.shareModalPalette.link.backgroundColor};
	border: ${({ theme }) => `${theme.shareModalPalette.link.borderColor} 1px solid`};
	border-radius: 1.25rem;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	width: 80%;
`;

export const CopyBtn = styled.div`
	width: 20%;
	height: 3rem;
	border: 0;
	padding: 0.7rem 2rem;
	border-radius: 1.25rem;
	color: ${({ theme }) => theme.shareModalPalette.link.textColor.unHovered};
	background: ${({ theme }) => theme.shareModalPalette.copyBtn.backgroundColor};
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Link = styled.a`
	color: ${({ theme }) => theme.shareModalPalette.link.textColor.unHovered};
	text-decoration: underline;
	margin: 0.7rem 0.4rem 0.7rem 1rem;
	text-align: left;
`;

export const SnapshotContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	overflow-y: scroll;
`;

export const ImageWrapper = styled.div`
	width: 90%;
	padding: 1rem;
`;

export const Screenshot = styled.img`
	max-width: 100%;
	max-height: 100%;
	border: 1px solid #ffffff;
	box-sizing: border-box;
	filter: drop-shadow(0px 4px 10px rgba(175, 175, 175, 0.4));
	border-radius: 1.25rem;
`;
