import * as S from './Time.styles';
import Moment from 'react-moment';

const Time = (props) => {
  const { match_hour } = props;
  return (
    <S.Time>
      <Moment format="LT">{match_hour}</Moment>
    </S.Time>
  );
};

export default Time;
