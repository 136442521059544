export { ReactComponent as ShareIcon } from './ShareIcon.svg';
export { ReactComponent as LaptopIcon } from './laptop.svg';
export { ReactComponent as LeaderboardIcon } from './LeaderboardIcon.svg';
export { ReactComponent as LeaderboardSvg } from './LeaderboardSvg.svg';
export { ReactComponent as ParlaysIcon } from './ParlaysIcon.svg';
export { ReactComponent as PredictionsIcon } from './PredictionsIcon.svg';
export { ReactComponent as ResultsIcon } from './ResultsIcon.svg';
export { ReactComponent as HowToPlayIcon } from './HowToPlayIcon.svg';
export { ReactComponent as HamburgerIcon } from './HamburgerIcon.svg';
export { ReactComponent as QuestionMarkIcon } from './QuestionMarkSvg.svg';
export { ReactComponent as LogoutIcon } from './LogoutIcon.svg';
export { ReactComponent as TermsAndCond } from './Terms.svg';
export { ReactComponent as CloseIcon } from './CloseIcon.svg';
