import { AxiosInstance } from 'axios';
import { useQuery, useQueryClient } from 'react-query';

type TParams = {
	clientId: number;
	period: string;
	type: string;
};

export const fetchLeaderboard = async (
	axiosInstance: AxiosInstance,
	params: TParams
) => {
	try {
		const { data } = await axiosInstance.get(`/api/get-client-leaderboards`, {
			params,
		});
		return data;
	} catch (err) {
		console.log(err);
		throw err;
	}
};

const useLeaderboard = (axiosInstance: AxiosInstance, params: TParams) => {
	return useQuery(
		['leaderboard', params],
		() => fetchLeaderboard(axiosInstance, params),
		{
			enabled: Boolean(params?.clientId && params?.period && params?.type),
			onError: (err) => console.error('[useLeaderboard]: fetch failed ', err),
		}
	);
};
export default useLeaderboard;
