import styled from 'styled-components';
import { Container } from 'react-bootstrap';

export const FooterNavItems = styled.div`
	display: flex;
	height: inherit;
	/* padding: 0 5px; */
	/* justify-content: space-evenly; */
	align-items: center;
	/* flex-direction: column; */
	background-color: ${({ theme }) => theme.footerPalette.backgroundColor};
	box-shadow: 0px 1px 6px rgba(150, 150, 150, 0.3);
`;
