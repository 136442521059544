import { AxiosInstance } from 'axios';
import { useMutation, useQuery } from 'react-query';

type TParams = {
	axiosInstance?: AxiosInstance;
	body?: any;
};

export const fetchScreenshot = async (params: TParams) => {
	try {
		const { axiosInstance, body } = params;
		return await axiosInstance?.post(`/api/create-screenshot-url`, body?.file);
	} catch (err) {
		console.log(err);
		// throw err;
		return err
	}
};

const useMutateScreenshot = () => {
	return useMutation(fetchScreenshot, { mutationKey: 'mutateScreenshot' });
};

export default useMutateScreenshot;
