import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import RoundIndicator from '../RoundIndicator';
import * as S from './RoundsResults.styles';
import * as R from './Results.styles';
import { RightArrow } from 'shared/assets/images/Arrows';
import { useWindowResize } from 'shared/hooks';
import { useTheme } from 'styled-components';

export const RoundResults = ({
	round,
	submission,
	isLast,
	totalPointsText,
}) => {
	const { isMobile } = useWindowResize();
	const theme = useTheme();

	return (
		<S.RoundResults>
			<Row style={{ display: 'flex', alignItems: 'center' }}>
				{isMobile ? (
					<>
						<Col xs={6} style={{ textAlign: 'start' }}>
							<S.MobileRoundName>{round.name}</S.MobileRoundName>
							<S.MobileRoundDate>
								{moment(round.roundStart)?.format('D MMM YYYY')}
							</S.MobileRoundDate>
							<S.MobileRoundPts>
								{totalPointsText}: {submission?.successPoints}
							</S.MobileRoundPts>
						</Col>
						<Col xs={4}>
							<RoundIndicator
								submissionResults={submission?.matchPredictions}
								matches={round.matches}
								hideText
								width={19}
								height={10}
							/>
						</Col>
						<Col xs={2}>
							<RightArrow fill={theme.mainTypography} />
						</Col>
					</>
				) : (
					<>
						<Col md={2}>{round.name}</Col>
						<Col md={2}>{moment(round.roundStart)?.format('D MMM YYYY')}</Col>
						<Col md={3}>
							{totalPointsText}: {submission?.successPoints}
						</Col>
						<Col md={4}>
							<RoundIndicator
								submissionResults={submission?.matchPredictions}
								matches={round.matches}
								hideText
								width={19}
								height={10}
							/>
						</Col>
						<Col md={1}>
							<RightArrow fill={theme.mainTypography} />
						</Col>
					</>
				)}
				{!isLast && <R.StyledHr $cancelMargins />}
			</Row>
		</S.RoundResults>
	);
};

export default RoundResults;
