import { AppSpinner, Button } from '@hook-apps-client/ui';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setShowRegisterModal } from 'shared/store/global/globalActions';
import { useTheme } from 'styled-components';
import * as S from './ThirdStep.styles';

const ThirdStep = ({
	isLoading,
	errorMessage,
	handleBack,
	setStepperProps,
}) => {
	const theme = useTheme();
	const dispatch = useDispatch();

	// control completed on third step
	useEffect(() => {
		setStepperProps({ completed: true });
		setTimeout(() => {
			dispatch(setShowRegisterModal(false));
		}, 2000);
		return () => setStepperProps({});
	}, []);

	return (
		<div
			style={{
				marginTop: '25px',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<h3
				style={{
					color: theme.formPalette.labelColor,
					whiteSpace: 'nowrap',
					textAlign: 'center',
				}}
			>
				Successfuly <br /> Created !
			</h3>
		</div>
	);
};

export default ThirdStep;
