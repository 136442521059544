import React, { useEffect, useState } from 'react';
import * as S from './DesktopSideNavComponent.styles';
import { TncIcon } from '../../assets/images/SideNav';
import {
	LeaderboardIcon,
	ResultsIcon,
	ParlaysIcon,
	PredictionsIcon,
	ShareIcon,
	TermsAndCond,
} from 'shared/assets/images/menu';
import PagesEnum from 'shared/Enum/pages';
import { AppSpinner, NavItem, Share, WarnModal } from '@hook-apps-client/ui';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import {
	toggleEditModeHandler,
	toggleModifyModeHandler,
} from 'shared/store/global/globalActions';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

const DesktopSideNavComponent = ({ defaultColor, activeColor }) => {
	// hooks
	const queryClient = useQueryClient();
	const langState = useSelector(({ lang }) => lang);
	const { selectedLeagueTab, modifyMode } = useSelector(({ global }) => global);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const theme = useTheme();
	const location = useLocation();

	// states
	const [showShareModal, setShowShareModal] = useState(false);
	const [showWarneModal, setShowWarneModal] = useState(false);
	const [selectedPath, setSelectedPath] = useState(false);

	// constants
	const jwt = localStorage.getItem('jwt-token');
	const leagues = queryClient.getQueryData(['leagues', jwt]);
	const shareModalTexts = langState?.modals?.share?.inviteFriend;
	const warnModalTexts = langState?.modals?.warn;
	const pagesLang = langState?.pages;
	const leagueRoute = location.pathname.includes('bracket')
		? 'bracket'
		: leagues && Object.keys(leagues)[selectedLeagueTab]?.replace(' ', '-');
	const username = sessionStorage.getItem('username');
	const user = queryClient.getQueryData(['user', username])?.user;
	//functions
	const disableLinkHandler = (e, to, disabled) => {
		if (disabled) {
			e.preventDefault();
			return;
		}
		if (modifyMode) {
			setShowWarneModal(true);
			setSelectedPath(to);
			e.preventDefault();
		}
	};

	const onStayHandler = () => {
		setShowWarneModal(false);
	};

	const onLeaveHandler = () => {
		dispatch(toggleModifyModeHandler(false));
		dispatch(toggleEditModeHandler(false));
		setShowWarneModal(false);
		navigate(selectedPath);
	};

	const isBracket = leagueRoute === PagesEnum.bracket;

	// jsx
	return (
		<>
			{!leagueRoute ? (
				<AppSpinner />
			) : (
				<>
					<NavItem
						activeBg={theme.gold}
						onClick={disableLinkHandler}
						activeColor={activeColor}
						SvgIcon={ResultsIcon}
						to={`/${PagesEnum.results}/${leagueRoute}`}
						text={pagesLang?.results?.title}
						defaultColor={
							isBracket || !user || !leagues[leagueRoute]?.past_round?.rounds?.length
								? 'grey'
								: defaultColor
						}
						disabled={
							isBracket || !user || !leagues[leagueRoute]?.past_round?.rounds?.length
						}
					/>
					<NavItem
						activeBg={theme.gold}
						onClick={disableLinkHandler}
						activeColor={activeColor}
						SvgIcon={PredictionsIcon}
						to={`/${PagesEnum.predictions}/${leagueRoute}`}
						text={pagesLang?.predictions?.title}
						defaultColor={isBracket ? 'grey' : defaultColor}
						disabled={isBracket}
					/>
					<NavItem
						activeBg={theme.gold}
						onClick={disableLinkHandler}
						activeColor={activeColor}
						defaultColor={defaultColor}
						SvgIcon={LeaderboardIcon}
						stroke
						to={
							isBracket
								? '/bracket/leaderboard'
								: `/${PagesEnum.leaderboard}/${leagueRoute}`
						}
						text={pagesLang?.leaderboard?.title}
					/>
					<NavItem
						activeBg={theme.gold}
						onClick={disableLinkHandler}
						activeColor={activeColor}
						SvgIcon={ParlaysIcon}
						to={`/${PagesEnum.parlays}/${leagueRoute}`}
						text={pagesLang?.parlays?.title}
						defaultColor={isBracket ? 'grey' : defaultColor}
						disabled={isBracket}
					/>
					<NavItem
						activeBg={theme.gold}
						onClick={disableLinkHandler}
						activeColor={activeColor}
						SvgIcon={TermsAndCond}
						to={`/${PagesEnum.termsAndCond}`}
						text={pagesLang?.termsAndConditions}
						defaultColor={isBracket ? 'grey' : defaultColor}
						disabled={isBracket}
					/>
					<NavItem
						activeBg={theme.gold}
						onClick={() => setShowShareModal(true)}
						text={pagesLang?.inviteAfriend}
						defaultColor={isBracket ? 'grey' : defaultColor}
						disabled={isBracket}
					>
						<ShareIcon
							fill={isBracket ? 'grey' : theme.desktopNavItems.defaultColor}
						/>
					</NavItem>
				</>
			)}
			{showWarneModal && (
				<WarnModal
					texts={warnModalTexts}
					open={showWarneModal}
					onStay={onStayHandler}
					onLeave={onLeaveHandler}
				/>
			)}
			{showShareModal && (
				<Share
					modalTexts={shareModalTexts}
					linkUrl={shareModalTexts?.urlToShare}
					open={showShareModal}
					onClose={() => setShowShareModal(false)}
				/>
			)}
		</>
	);
};
const ClickWrapper = ({ children, onClick }) => (
	<React.Fragment>
		{React.Children.map(children, (child) =>
			React.cloneElement(child, {
				onClick,
			})
		)}
	</React.Fragment>
);

export default React.memo(DesktopSideNavComponent);
