import styled from 'styled-components';

export const LoginLayout = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

export const Container = styled.div`
	padding: ${({ isMobile }) => (isMobile ? 'none' : '0 2rem')};
	/* max-width: 80%; */
	margin: 1.3rem auto;
	gap: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
`;

export const LogoContainer = styled.div`
	width: ${({ isMobile }) => (isMobile ? '100%' : '40%')};
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: center;
`;

export const LogoImage = styled.img`
	position: relative;
	width: 55%;
`;

export const TextsBox = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: start;
	padding: 0 2rem;
`;

export const H2 = styled.h2`
	font-weight: bold;
	font-size: 1.6em;
`;
export const H1 = styled.h2`
	font-weight: bold;
	font-size: 1.8em;
`;

export const Row = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const ForgotPassword = styled.button`
	background-color: transparent;
	color: inherit;
	border: none;
	padding: 0;
	font: 'inherit';
	cursor: pointer;
	outline: inherit;
`;
