import styled from 'styled-components';

export const Time = styled.div`
  display: flex;
  text-align: center;
  width: auto;
  margin-top: 0.5rem;
  font-size: .875em;
  line-height: 1rem;
  /* color: #353d49; */
  color: ${({theme})=>theme.vsContainerPalette.timeColor};
  margin-bottom: 0.5em;
  white-space: nowrap;
`;
