import { Button, ButtonBase } from '@mui/material';
import styled from 'styled-components';

export const Paper = styled.div`
	position: absolute;
	top: 20px;
	width: 75%;
	max-width: 400px;
	max-height: 90vh;
	overflow-y: scroll;
	display: flex;
	align-items: center;
	flex-direction: column;
	border-radius: 1.25rem;
	border: 1px solid #000;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	padding: 0.8rem 0 0;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: ${({ theme }) => theme.modalPalette.bgColor};
`;

export const Title = styled.h4`
	color: ${({ theme }) => theme.white};
	font-size: 1rem;
	margin: 0;
`;
export const FooterText = styled.h4`
	color: ${({ theme }) => theme.white};
	font-size: 0.6rem;
	margin: 0;
	width: 70%;
`;

export const Description = styled.p`
	text-align: center;
	color: ${({ theme }) => theme.mainTypography};
`;

export const Row = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-around;
`;
export const ModalBtn = styled(Button)`
	background-color: ${({ theme }) => theme.appSectionsBg};
	color: ${({ theme }) => theme.primary};
	&:hover {
		cursor: pointer;
		background-color: ${({ theme }) => theme.primary};
		color: ${({ theme }) => theme.appSectionsBg};
	}
`;

export const FooterButton = styled(ButtonBase)``;

export const FooterButtonText = styled.h4`
	color: ${({ theme }) => theme.formPalette.headerButtonTextColor};
	font-size: 0.6rem;
	margin: 0 1rem 0 auto;
`;
