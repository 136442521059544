import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

export const ParlaysMatch = styled(Row)`
	color: ${({ theme }) => theme.mainTypography};
	align-items: center;
	padding: unset;
	margin: unset;
	text-align: center;
`;

export const SmallShirt = styled.img`
	height: 1.125rem;
	width: 1rem;
`;

export const TeamsRowWrapper = styled(Row)`
	flex-direction: column;
	padding: 0.6rem 0;
	margin: unset;
	gap: 0.2rem;
`;

export const Team = styled.div`
	display: flex;
	align-items: center;
	gap: 0.6rem;
`;

export const OddCol = styled(Col)`
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const OddButton = styled.button`
	background: none;
	color: inherit;
	border: none;
	font: inherit;
	cursor: pointer;
	outline: inherit;
	/* ${({ isMobile }) => isMobile && { fontSize: '.75em' }} */
`;

export const StyledHr = styled.span`
	width: 100%;
	display: flex;
	padding: 0 1rem;
	flex-direction: row;
	text-align: center;
	color: ${({ theme }) => theme.mainTypography};
	&:before {
		content: '';
		flex: 1 1;
		border-bottom: ${({ theme }) => `${theme.leaderboardTable.titlesHr} solid 1px`};
		margin: auto;
		margin-right: ${(props) => (props.$cancelMargins ? 'unset' : '5px')};
	}
	&:after {
		content: '';
		flex: 1 1;
		border-bottom: ${({ theme }) => `${theme.leaderboardTable.titlesHr} solid 1px`};
		margin: auto;
		margin-left: ${(props) => (props.$cancelMargins ? 'unset' : '5px')};
	}
`;

export const ViBox = styled.div`
	height: 100%;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
`;

export const TeamName = styled.span`
	/* ${({ isMobile }) => isMobile && { fontSize: '.75em' }} */
	white-space: nowrap;
`;
