import html2canvas from 'html2canvas';

export const createScreenshotCanvas = async (ref, isMobile, page) => {
	const canvas = await html2canvas(ref, {
		logging: true,
		allowTaint: false,
		useCORS: true,
		scale: 2,
		// height: ref.clientHeight + 300,
		onclone: (cloneDoc) => {
			cloneDoc.getElementById('layout').style.height = 'auto';
			if (!isMobile) {
				cloneDoc.getElementById('layout').style.display = 'flex';
				cloneDoc.getElementById('layout').style.flexDirection = 'column';
			} else {
				cloneDoc.getElementById('header').style.position = 'relative';
			}
		},
	});
	return canvas;
};
