import './HowToPlayComponent.css';

const hideContent = false;

const HowToPlayComponent = () => {
	if (hideContent) return null;
	return (
		<div className="tnc__main-container tnc__main-font">
			<h3 className="tnc__secondary-headline bold-text centered-text">
				La Porra del Mundial de Qatar 2022
			</h3>
			<p className="bottom-margin_large">
				La Porra del Mundial es un juego en el que pones a prueba tu capacidad para
				predecir los resultados de los partidos. Consigues puntos por cada resultado
				correcto que aciertes, la suma de estos puntos será tu puntuación en la
				tabla clasificatoria.
			</p>
			<h3 className="tnc__secondary-headline bold-text">¿Cuesta dinero jugar?</h3>
			<h3 className="tnc__secondary-headline">
				<span className="bold-text">¡NO!</span> Jugar es gratis.
			</h3>
			<p className="bottom-margin_large">
				Para poder participar en todas las jornadas y que tus predicciones queden
				grabadas, deberás tener una cuenta en VERSUS. Si no es así, no tendrás
				acceso a poder participar.{' '}
			</p>
			<h3 className="tnc__secondary-headline bold-text">¿Cómo se juega?</h3>
			<p>
				El juego consiste en hacer una predicción del resultado correcto de los
				partidos del Mundial de Qatar 2022. Se juega en rondas de 4 partidos:
			</p>
			<ul className="bottom-margin_large">
				<li>12 partidos por Grupo, en jornadas de 4 partidos diarios.</li>
				<li>8 partidos en octavos de final, 2 jornadas de 4 partidos.</li>
				<li>4 partidos en cuartos de final, 1 jornada de 4 partidos.</li>
				<li>
					Final, 2 semifinales y 3º puesto, componen la última jornada de 4 partidos.
				</li>
			</ul>
			<h3 className="tnc__secondary-headline bold-text">
				¿Cuándo tengo que hacer La Porra?
			</h3>
			<p>
				Puedes realizar tus predicciones hasta 1 hora antes del comienzo del primer
				partido de cada ronda.
			</p>
			<p className="bottom-margin_large">
				Una vez hecha la predicción, puedes modificarla todas las veces que quieras.
				Hasta 1 hora antes del comienzo del primer partido.
			</p>
			<h3 className="tnc__secondary-headline bold-text">
				¿Cómo es el sistema de puntuación?
			</h3>
			<p>
				Cada partido tiene puntuación máxima de{' '}
				<span className="bold-text">7 puntos,</span> que se distribuyen:
			</p>
			<ul className="bottom-margin_large">
				<li>
					<span className="bold-text">5 puntos</span> por acertar el{' '}
					<span className="bold-text">resultado correcto</span> del partido.
				</li>
				<li>
					<span className="bold-text">2 puntos</span> por acertar el{' '}
					<span className="bold-text">ganador del partido,</span> independientemente
					del resultado.
				</li>
			</ul>
			<h3 className="tnc__secondary-headline bold-text">¿Cómo consigo premios?</h3>
			<p className="bottom-margin_large">
				Competirás en dos tablas de clasificación distintas. Cuantos más puntos
				consigas con cada partido, más alto subirás en la tabla de clasificación.
			</p>
			<h3 className="tnc__secondary-headline underline-text">
				TABLA CLASIFICACIÓN FASE DE GRUPOS:{' '}
			</h3>
			<p className="bottom-margin_large">
				En esta tabla de clasificación solo contarán los puntos que hayas conseguido
				durante la Fase de Grupos del Mundial de Qatar 20222. Los 10 jugadores con
				mayor puntación al final de la fase de grupos tendrán premio.
			</p>
			<h3 className="tnc__secondary-headline underline-text">
				TABLA CLASIFICACIÓN TOTAL:
			</h3>
			<p className="bottom-margin_large">
				En esta tabla de clasificación cuentan todos los partidos que se juegan
				durante el Mundial de Qatar 2022. Los 10 jugadores con mayor puntuación al
				final del torneo tendrán premio.
			</p>
			<h3 className="tnc__secondary-headline bold-text">¡PREMIOS!</h3>
			<h3 className="tnc__secondary-headline underline-text">
				TABLA CLASIFICACIÓN FASE DE GRUPOS:
			</h3>
			<div className="table container border tnc__main-font bottom-margin_small">
				<div className="d-flex">
					<div className="border p-2 col-6">1º</div>
					<div className="border p-2 col-6">FREEBET 25€ </div>
				</div>
				<div className="d-flex">
					<div className="border p-2 col-6">2º</div>
					<div className="border p-2 col-6">FREEBET 20€</div>
				</div>
				<div className="d-flex">
					<div className="border p-2 col-6">3º</div>
					<div className="border p-2 col-6">FREEBET 15€</div>
				</div>
				<div className="d-flex">
					<div className="border p-2 col-6">4º</div>
					<div className="border p-2 col-6">FREEBET 10€</div>
				</div>
				<div className="d-flex">
					<div className="border p-2 col-6">5º</div>
					<div className="border p-2 col-6">FREEBET 5€</div>
				</div>
				<div className="d-flex">
					<div className="border p-2 col-6">6º</div>
					<div className="border p-2 col-6">FREEBET 2€</div>
				</div>
				<div className="d-flex">
					<div className="border p-2 col-6">7º</div>
					<div className="border p-2 col-6">FREEBET 1€</div>
				</div>
				<div className="d-flex">
					<div className="border p-2 col-6">8º</div>
					<div className="border p-2 col-6">10 GOLDEN CHIPS</div>
				</div>
				<div className="d-flex">
					<div className="border p-2 col-6">9º</div>
					<div className="border p-2 col-6">5 GOLDEN CHIPS </div>
				</div>
				<div className="d-flex">
					<div className="border p-2 col-6">10º</div>
					<div className="border p-2 col-6">2 GOLDEN CHIPS </div>
				</div>
			</div>
			<h3 className="tnc__secondary-headline underline-text">
				TABLA CLASIFICACIÓN TOTAL:
			</h3>
			<div className="table container border tnc__main-font">
				<div className="d-flex">
					<div className="border p-2 col-6">1º</div>
					<div className="border p-2 col-6">FREEBET 100€ </div>
				</div>
				<div className="d-flex">
					<div className="border p-2 col-6">2º</div>
					<div className="border p-2 col-6">FREEBET 50€</div>
				</div>
				<div className="d-flex">
					<div className="border p-2 col-6">3º</div>
					<div className="border p-2 col-6">FREEBET 35€</div>
				</div>
				<div className="d-flex">
					<div className="border p-2 col-6">4º</div>
					<div className="border p-2 col-6">FREEBET 20€</div>
				</div>
				<div className="d-flex">
					<div className="border p-2 col-6">5º</div>
					<div className="border p-2 col-6">FREEBET 10€</div>
				</div>
				<div className="d-flex">
					<div className="border p-2 col-6">6º</div>
					<div className="border p-2 col-6">
						FREEBET POR DEPÓSITO 100% HASTA 100€
					</div>
				</div>
				<div className="d-flex">
					<div className="border p-2 col-6">7º</div>
					<div className="border p-2 col-6">FREEBET POR DEPÓSITO 100% HASTA 50€</div>
				</div>
				<div className="d-flex">
					<div className="border p-2 col-6">8º</div>
					<div className="border p-2 col-6">FREEBET POR DEPÓSITO 50% HASTA 50€</div>
				</div>
				<div className="d-flex">
					<div className="border p-2 col-6">9º</div>
					<div className="border p-2 col-6">FREEBET POR DEPÓSITO 20% HASTA 50€</div>
				</div>
				<div className="d-flex">
					<div className="border p-2 col-6">10º</div>
					<div className="border p-2 col-6">FREEBET POR DEPÓSITO 25% HASTA 25€</div>
				</div>
			</div>
		</div>
	);
};

export default HowToPlayComponent;
