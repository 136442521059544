import { AxiosInstance } from 'axios';
import { useMutation, useQuery } from 'react-query';

type TParams = {
	axiosInstance?: AxiosInstance;
	body?: any;
};

type TError = {
	response: {
		data: {
			error: string;
		};
	};
};

export const submitLogin = async (params: TParams) => {
	try {
		const { axiosInstance, body } = params;
		if (axiosInstance) {
			const data = await axiosInstance?.post(`auth/login`, body?.formData);
			const { jwtToken } = data?.data;
			if (jwtToken) {
				localStorage.setItem('jwt-token', jwtToken);
				axiosInstance.defaults.headers.common['jwt-token'] = jwtToken;
				const { data } = await axiosInstance.get('/auth/profile');
				sessionStorage.setItem('username', data.user.email);
				return data;
			} else {
				sessionStorage.removeItem('username');
			}
		}
	} catch (err) {
		let _e = err as TError;
		let errorMessage = _e.response.data.error;

		// throw err;
		return errorMessage;
	}
};

const useMutateLogin = () => {
	return useMutation(submitLogin, { mutationKey: 'mutateLogin' });
};

export default useMutateLogin;
