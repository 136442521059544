import { isAndroid, isIOS } from 'react-device-detect';

export const getDevice = () => {
	if (isIOS) {
		return 'ios';
	} else if (isAndroid) {
		return 'android';
	}
	return 'web';
};
