import * as S from './Banner.styles';
import { useWindowResize } from 'shared/hooks';

export const Banner = ({
  banner,
  pageName,
  user = false,
  links = { loggedIn: true, loggedOut: false },
  style,
}) => {
  const { isMobile } = useWindowResize();  

  const pressBannerHandler = () => {
    if (user) {
      window.location.assign(links.loggedIn);
      window.top.location.href = links.loggedIn;
      window.parent.location = links.loggedIn;
      return;
    }
    window.location.assign(links.loggedOut);
    window.top.location.href = links.loggedOut;
    window.parent.location = links.loggedOut;
    return;
  };

  return (
    <S.Banner style={style}>
      <S.Image
        isMobile={isMobile}
        src={banner}
        alt="banner"
        onClick={pressBannerHandler}
      />
    </S.Banner>
  );
};
export default Banner;
