import ReactPortal from '../../ReactPortal/ReactPortal';
import { Fade, Modal, Backdrop } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { Close as CloseIcon } from '@mui/icons-material';
import CopyAnimation from '../CopyAnimation/CopyAnimation';
import * as S from './DesktopShare.styles';
import { useEffect, useState } from 'react';
import SocialIcons from '../SocialIcons/SocialIcons';
import { DownloadIcon } from 'shared/assets/images/Icons/social';
import { saveAs } from 'file-saver';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));

const DesktopShare = ({
	open,
	onClose,
	blob,
	linkUrl,
	imageUrl,
	platforms = [],
	modalTexts,
}) => {
	const [isCopied, setIsCopied] = useState(false);
	const classes = useStyles();

	useEffect(() => {
		if (!open) {
			setTimeout(() => {
				setIsCopied(false);
			}, 300);
		}
	}, [open]);

	//CopyToClipBoard--> gets a parameter and copy it to the ClipBoard
	const copyToClipBoard = async () => {
		try {
			// Clipboard feature is available only in secure contexts (HTTPS), in some or all supporting browsers.
			await navigator.clipboard.writeText(linkUrl);
			setIsCopied('✓');
		} catch (err) {
			console.log(err);
		}
	};
	const downloadImage = (imageUrl) => {
		saveAs(blob, 'PredictionSnapshot.jpg');
	};
	return (
		<ReactPortal wrapperId={'share-modal'}>
			<Modal
				disablePortal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
				className={classes.modal}
				open={open}
				onClose={onClose}
			>
				<Fade in={open}>
					<S.Paper>
						<S.ExitBtn onClick={onClose}>
							<CloseIcon />
						</S.ExitBtn>
						<S.Title>{modalTexts?.title}</S.Title>
						{linkUrl && (
							<S.CopyLinkBox>
								<S.Url2Share id="transition-modal-description">
									<S.Link href={linkUrl} target="_blank" rel="noreferrer">
										{linkUrl}
									</S.Link>
								</S.Url2Share>

								<S.CopyBtn onClick={copyToClipBoard}>
									{isCopied ? <CopyAnimation /> : <div>{modalTexts?.copyBtn}</div>}
								</S.CopyBtn>
							</S.CopyLinkBox>
						)}

						{imageUrl && (
							<S.SnapshotBox className="snapShot_Box__DesktopShare">
								<S.ImgContainer className="img_box__DesktopShare">
									<S.Image
										className="snapshot__DesktopShare"
										src={imageUrl}
										alt="predictions snapshot"
										style={{ width: '100%', height: '100%' }}
									/>
									<S.BlackSlip
										onClick={() =>
											// data:image/jpg;base64,
											downloadImage(imageUrl)
										}
										className="blackSlip__DesktopShare"
									>
										<img
											src={DownloadIcon}
											alt="download snapshot"
											style={{ maxHeight: '50%', minWidth: '75px' }}
										/>
										<S.BlackSlipText className="blackSlipText__DesktopShare">
											Download
										</S.BlackSlipText>
									</S.BlackSlip>
								</S.ImgContainer>
							</S.SnapshotBox>
						)}
						<SocialIcons payload={linkUrl ?? imageUrl} platforms={platforms} />
					</S.Paper>
				</Fade>
			</Modal>
		</ReactPortal>
	);
};

export default DesktopShare;
