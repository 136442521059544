import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Results = styled(Container)`
	display: flex;
	flex-direction: column;
	height: fit-content;
	${(props) => !props.$isMobile && { padding: '0.6rem 3.12rem' }}
`;

export const StyledRow = styled(Row)`
	margin: unset;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	position: 'relative';
	/* padding: ${(props) => (props.$isMobile ? '10px' : '20px 0')}; */
	background-color: ${({ theme }) => theme.appSectionsBg};
	width: ${(props) => (props.$isMobile ? 'unset' : '100%')}; ;
`;

export const StyledHr = styled.span`
	width: 100%;
	display: flex;
	flex-direction: row;
	text-align: center;
	color: ${({ theme }) => theme.mainTypography};
	&:before {
		content: '';
		flex: 1 1;
		border-bottom: 1px solid;
		border-color: ${({ theme }) => theme.styledHr};
		margin: auto;
		margin-right: ${(props) => (props.$cancelMargins ? 'unset' : '5px')};
	}
	&:after {
		content: '';
		flex: 1 1;
		border-bottom: 1px solid;
		border-color: ${({ theme }) => theme.styledHr};
		margin: auto;
		margin-left: ${(props) => (props.$cancelMargins ? 'unset' : '5px')};
	}
`;

export const ResultItem = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	background-color: ${({ bgColor }) => (bgColor ? bgColor : '')};
	margin: ${(props) => (props.$cancelMargins ? 'unset' : '1.25rem')};
`;

export const Summary = styled.div`
	width: 100%;
	display: flex;
	color: ${({ theme }) => theme.mainTypography};
	justify-content: center;
	align-items: center;
	font-size: 0.87em;
	border: ${({ theme }) => `${theme.appBg} solid 1px`};
	border-radius: 10px;
	${(props) => !props.$isMobile && { padding: '1rem 1rem' }}
`;

export const StyledLink = styled(Link)`
	text-decoration: none;
	color: ${({ theme }) => theme.black};
	&:hover {
		text-decoration: none;
		color: ${({ theme }) => theme.black};
	}
`;
