import moment from 'moment';
import { useQuery } from 'react-query';

// type TParams = {
//   axiosInstance: AxiosInstance,
//   campaignRounds: Array<object>
// };

export const getOdds = async (params) => {
	try {
		const { axiosInstance, campaignRounds, oddsEndpoint } = params;
		if (!axiosInstance) return;
		const { data } = await axiosInstance.post(`/api${oddsEndpoint}`, {
			campaignRounds,
		});
		return data;
	} catch (err) {
		console.log(err);
		throw err;
	}
};

const useOdds = (axiosInstance, campaignRounds, oddsEndpoint) => {
	return useQuery(
		['odds', campaignRounds, oddsEndpoint],
		() => getOdds({ axiosInstance, campaignRounds, oddsEndpoint }),
		{
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			notifyOnChangeProps: ['data'],
			onError: (err) => console.error('[useOdds]: fetch failed ', err),
			onSuccess: (data) => {
				for (const league in data) {
					if (data[league]?.length) {
						data[league] = data[league]
							.filter(
								(odd) =>
									moment(odd.match_start).isAfter(moment()) &&
									!odd.markets.some((market) => market.possibleOutcomes.length <= 0)
							)
							.sort(
								(a, b) =>
									new Date(a.match_start).getTime() - new Date(b.match_start).getTime()
							);
					}
				}
				return data;
			},
		}
	);
};

export default useOdds;
