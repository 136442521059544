import { Link } from 'react-router-dom';
import * as S from './MobileSideNavItem.styles';
import { useTheme } from 'styled-components';
// import LogoutIcon from '@mui/icons-material/Logout';
import { LogoutIcon } from 'shared/assets/images/menu';
import StyledSvgIcon from '../../StyledSvgIcon/StyledSvgIcon';

export const MobileSideNavItem = ({
	className,
	to,
	onClick,
	src,
	SvgIcon,
	text,
	children,
	defaultColor,
	stroke,
}) => {
	const theme = useTheme();

	if (children) {
		return (
			<S.Li>
				<div className="nav-item" onClick={onClick}>
					{children}
				</div>
			</S.Li>
		);
	}

	return (
		<li className={`${className ? { ...className } : ''} nav-item`}>
			<Link onClick={onClick} to={to} className="nav-link">
				{SvgIcon ? (
					<StyledSvgIcon
						SvgIcon={SvgIcon}
						defaultColor={defaultColor}
						activeColor={defaultColor}
						stroke={stroke}
					/>
				) : (
					<S.Image src={src} alt={text} />
				)}
				<S.Text>{text.toLowerCase()}</S.Text>
			</Link>
		</li>
	);
};

export default MobileSideNavItem;
