import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const RoundResults = styled(Container)`
color: ${({theme})=> theme.secondaryTypography};
`;

export const MobileRoundName = styled.div`
	font-size: 0.875em;
	color: ${({ theme }) => theme.thirdlyTypography};
`;
export const MobileRoundDate = styled.div`
	font-size: 1em;
	color: ${({ theme }) => theme.secondaryTypography};
`;
export const MobileRoundPts = styled.div`
	font-size: 1em;
	color: ${({ theme }) => theme.secondary};
`;
