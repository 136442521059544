import axios, { AxiosInstance } from 'axios';
import { useMutation, useQuery } from 'react-query';

type TBody = {
	formData: {
		userName: string;
		password: string;
	};
};

type TParams = {
	axiosInstance?: AxiosInstance;
	body?: TBody;
};

type TError = {
	response: {
		data: {
			error: string;
		};
	};
};

export const submitLoginMember = async (params: TParams) => {
	try {
		const { axiosInstance, body } = params;
		const data = await axiosInstance?.post(`auth/login-delasport-member`, {
			formData: body?.formData,
		});
		return data;
	} catch (err) {
		let _e = err as TError;
		let errorMessage = _e.response.data.error;
		return errorMessage;
	}
};

const useLoginMember = () => {
	return useMutation(submitLoginMember, { mutationKey: 'mutateLoginMember' });
};

export default useLoginMember;
