import { Link } from 'react-router-dom';
import PagesEnum from 'shared/Enum/pages';
import { useTheme } from 'styled-components';
import { AppSpinner } from '../../../AppSpinner/AppSpinner';
import Button from '../../../Button/Button';
import FormField from '../components/FormField';
import * as S from './SignUpForm.styles';

export const SignUpForm = ({ isLoading = false, error }) => {
	//hooks
	const theme = useTheme();

	//jsx
	return (
		<S.Container>
			<S.H1>Let's get started!</S.H1>
			<S.H2>Create an account to continue</S.H2>
			<FormField
				name="firstName"
				label="First Name"
				variant="outlined"
				type="text"
				rule={{ required: 'This Field Is Required' }}
			/>
			<FormField
				name="lastName"
				label="Last Name"
				variant="outlined"
				type="text"
				rule={{ required: 'This Field Is Required' }}
			/>
			<FormField
				name="email"
				label="Email Address"
				variant="outlined"
				type="email"
				rule={{ required: 'This Field Is Required', minLength: 4 }}
			/>
			<FormField
				name="password"
				label="Password"
				variant="outlined"
				type="password"
				rule={{ required: 'This Field Is Required', minLength: 6 }}
			/>
			<S.ErrorMessage>{error}</S.ErrorMessage>
			<S.OptionRow>
				<span style={{ display: 'inline' }}>Already have an account?</span>
				<Link to={`/${PagesEnum.login}`} style={{ color: theme.primary }}>
					<span style={{ display: 'inline' }}>Login</span>
				</Link>
			</S.OptionRow>
			<S.OptionRow>
				<span style={{ display: 'inline' }}>or continue as a</span>
				<Link to={`/${PagesEnum.predictions}`} style={{ color: theme.primary }}>
					<span style={{ display: 'inline' }}>Guest</span>
				</Link>
			</S.OptionRow>
			<Button
				title={isLoading ? <AppSpinner color={theme.black} /> : 'Sign-Up'}
				bgColor={theme.primary}
				textColor={theme.white}
				width={'12rem'}
				style={{
					height: '3rem',
					padding: '0.7rem 4rem',
					margin: '.5rem auto 0',
				}}
				type="submit"
			/>
		</S.Container>
	);
};

export default SignUpForm;
