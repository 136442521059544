import { Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import Button from '../Button';

export const Matches = styled(Container)`
	/* padding: 1rem 1rem;  */
	display: flex;
	/* height: 80%; */
	flex-direction: column;
	align-items: center;
	@media only screen and (max-width: 767px) {
		padding-top: 1.5rem;
		margin: 0 auto;
	}
`;

export const EditBtnContainer = styled.div`
	margin: 10px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: flex-end;
	@media all and (max-width: 767px) {
		color: ${({ theme }) => theme.secondaryTypography};
		${({ hidePredictionCounterOnMobile }) =>
			!hidePredictionCounterOnMobile ? 'margin-top: 1.5rem' : 'return'};
		position: relative;
		align-self: center;
		width: 100%;
	}
`;

export const EditButton = styled(Button)`
	background-color: ${({ theme, toggle }) =>
		!toggle ? theme.editBtn.bgColor : theme.editBtn.disabledBgColor};
	display: flex;
	color: ${({ theme, toggle }) =>
		!toggle ? theme.editBtn.textColor : theme.editBtn.disabledTextColor};
	justify-content: space-evenly;
	@media all and (max-width: 767px) {
		position: absolute;
		right: 10px;
	}
`;

export const StyledRow = styled(Row)`
	/* gap: 30px; */
`;
