import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import styled from 'styled-components';

export const RadioBtns = styled(RadioGroup)`
	display: flex;
	flex-direction: row;
	height: 40px;

	.MuiRadio-root {
		color:  ${({ theme }) => theme.formPalette.unCheckedRadioBtnColor};
		/* background-color: ${({ theme }) => theme.formPalette.unCheckedRadioBtnColor}; */
	}
	.MuiRadio-root.Mui-checked {
		color:  ${({ theme }) => theme.formPalette.checkedRadioBtnColor};
	}
`;

export const RadioBtnsLabel = styled(FormControlLabel)`
	.MuiFormControlLabel-label {
		font-size: 0.7rem;
		color: ${({ theme }) => theme.formPalette.radioBtnLabelColor};
	}
`;

export const RadioBtn = styled(Radio)`
	/* color: ${({ theme }) => theme.secondary} !important; */
`;
