import styled from 'styled-components';

export const ScoreBox = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: ${({ $isMobile }) => ($isMobile ? '10px' : '15px')};
	width: 6.5em;
	padding: 10px 0;
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dadada;
	border-color: ${({theme}) => theme.scoreBoxPalette.boxBorderColor};
  box-sizing: border-box;
  border-radius: 7px;
  width: 2rem;
  height: 2.2rem;
  background: ${({theme})=> theme.scoreBoxPalette.boxBgColor};

`;

export const Text = styled.p`
	margin: unset;
	padding: unset;
	text-align: center;
	font-size: ${(props) => (props.size ? props.size + 'px' : '0.75em')};
	font-weight: ${(props) => (props.weight ? props.weight : 'normal')};
	color: ${({ theme }) => theme.scoreBoxPalette.scoreDefaultColor};
`;

export const DivText = styled.div`
  text-align: center;
  font-size: ${(props) => (props.size ? props.size + 'px' : '0.75em')};
  font-weight: ${(props) => (props.weight ? props.weight : 'normal')};
  color: ${({color, theme}) => (color ? color : theme.scoreBoxPalette.scoreDefaultColor )};
`;

export const BoxWrapper = styled.div`
	display: flex;
	justify-content: center;
	gap: 10px;
`;

export const SvgContainer = styled.div`
  width: 1rem;
  height: 1rem;
  margin-top: 5px;
`;
