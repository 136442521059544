import styled from 'styled-components';

export const Image = styled.img`
	width: 3.12rem;
	height: 2.8rem;
`;

export const Text = styled.p`
	color: ${({ theme }) => theme.secondary};
	font-size: 0.75em;
	text-align: center;
	margin-bottom: 0;
`;

export const InviteFriend = styled.li`
	width: 100%;
	display: flex;
	justify-content: center;
`;

export const Li =styled.li`
width: 100%;
display: flex;
justify-content: center;
`
