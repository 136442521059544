import styled from 'styled-components';

export const MatchIndicator = styled.div`
	z-index: 999;
	position: absolute;
	right: 0;
	top: 0;
	height: 8rem;
	width: 8rem;
	text-align: center;
	transform: translate(50%, -50%) rotate(45deg);
	transform-origin: 50% 50%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	color: white;
	line-height: 0.9rem;
	padding-bottom: 0.3rem;
	background: ${({isExact, theme}) =>
		isExact
			? theme.matchIndicator.backgroundColor.exact
			: theme.matchIndicator.backgroundColor.correct};
`;
