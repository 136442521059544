import { Select, InputLabel, FormControl, MenuItem } from '@mui/material';
import styled from 'styled-components';

export const SelectInput = styled(Select)`
	.MuiOutlinedInput-input:-webkit-autofill {
		border-radius: 1.85rem;
	}
	fieldset {
		border-radius: 1.85rem;
	}
	.MuiSelect-outlined {
		color: ${({ theme }) => theme.formPalette.inputPlaceholderColor};
	}
	border-radius: 1.85rem;
	background-color: ${({ theme }) => theme.formPalette.inputBgColor};
`;

export const Label = styled(InputLabel)``;

export const PlaceHolder = styled(InputLabel)`
	color: ${({ theme }) => theme.formPalette.inputPlaceholderColor}; ;
`;

export const SelectListItem = styled(MenuItem)``;

export const FormContainer = styled(FormControl)`
	width: 100%;
`;
