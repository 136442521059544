import useWindowResize from 'shared/hooks/useWindowResize';
import DesktopSideNavWrapper from './DesktopSideNav';
import MobileSideNavWrapper from './MobileSideNav/MobileSideNavWrapper';
import * as S from './SideNav.styles';

export const SideNav = (props) => {
	const { isMobile } = useWindowResize();

	if (isMobile) {
		return <MobileSideNavWrapper {...props} />;
	}
	return <DesktopSideNavWrapper {...props} />;
};

export default SideNav;
