import './styles.css';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'jquery/dist/jquery';
import 'bootstrap/dist/js/bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import {
	globalReducer,
	langReducer,
	leaderboardReducer,
	parlaysReducer,
} from 'shared/store';
import { QueryClient, QueryClientProvider } from 'react-query';
// import { ReactQueryDevtools } from 'react-query/devtools';
// import { Auth } from './components/Auth/Auth';
import { axiosInstance } from './app/axios';
import { ThemeProvider } from 'styled-components';
import { palette } from './assets/themes';
import App from './app';
import Auth from './components/Auth/Auth';
import { StyledEngineProvider } from '@mui/material/styles';
import ReactGA from 'react-ga';

// const Auth = React.lazy(() => import('./components/Auth/Auth'));

ReactGA.initialize('UA-204804134-7');

ReactGA.ga('create', 'UA-204804134-7', 'auto', {
	cookieFlags: 'SameSite=None; Secure',
});

ReactGA.pageview(window.location.pathname + window.location.search);

const rootReducer = combineReducers({
	global: globalReducer,
	leaderboard: leaderboardReducer,
	parlays: parlaysReducer,
	lang: langReducer,
});

const store = createStore(rootReducer);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			refetchOnmount: false,
			refetchOnReconnect: false,
			retry: 1,
			staleTime: 5 * 1000,
		},
	},
});

// Url params handler
const urlParams = new URLSearchParams(window.location.search);

const isTrueParam = (param) => {
	return param && +param !== 0 && param !== 'null' && param !== 'undefined';
};

// localStorage.setItem(
// 	'jwt-token',
// 	'eyJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6InRlc3Q1QG1zYXBwcy5tb2JpIiwiZXhwaXJlcyI6MTY3NTYxNzE2OTg3MH0.FdWcJjlM3PnoJ3K45Rg_GLyGCYdvx7fu5WTygn3RY5U'
// );
// const jwt = localStorage.getItem("jwt-token");
// jwt && (axiosInstance.defaults.headers.common['jwt-token'] = jwt);

const usernameParam = urlParams.get('username');
if (isTrueParam(usernameParam)) {
	sessionStorage.setItem('username', usernameParam);
} else {
	sessionStorage.removeItem('username');
	localStorage.setItem('jwt-token', null);
}

// if (!isTrueParam(usernameParam)) {
// 	localStorage.removeItem('jwt-token');
// 	axiosInstance.defaults.headers.common['jwt-token'] = null;
// }

// const languageParam = urlParams.get('language');
// languageParam && sessionStorage.setItem('language', languageParam);

const isMobileParam = urlParams.get('isMobile');
isMobileParam && sessionStorage.setItem('isMobile', isMobileParam);

const osParam = urlParams.get('os');

isMobileParam && sessionStorage.setItem('os', osParam);

const registerOrLoginParam = urlParams.get('register');

if (isTrueParam(registerOrLoginParam) && usernameParam) {
	ReactGA.event({
		category: `Registration Success - ${osParam ? osParam : 'web'}`,
		action: `${usernameParam} - Registered`,
	});
} else if (+registerOrLoginParam === 0 && usernameParam) {
	ReactGA.event({
		category: `Login Success - ${osParam ? osParam : 'web'}`,
		action: `${usernameParam} - Logged in`,
	});
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<StrictMode>
		<Router>
			<QueryClientProvider client={queryClient}>
				<Provider store={store}>
					<StyledEngineProvider injectFirst>
						<ThemeProvider theme={palette}>
							{/* <React.Suspense
							fallback={
								<Container
									style={{
										height: '100vh',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}
									fluid
								>
									<AppSpinner />
								</Container>
							}
						> */}
							<Auth>
								<App />
							</Auth>
							{/* </React.Suspense> */}
						</ThemeProvider>
					</StyledEngineProvider>
				</Provider>
				{/* <ReactQueryDevtools /> */}
			</QueryClientProvider>
		</Router>
	</StrictMode>
);
