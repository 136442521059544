import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: .5rem;
`;

export const H2 = styled.h2`
	font-weight: 500;
	color: ${({ theme }) => theme.primary};
	font-size: 1.1em;
`;
export const H1 = styled.h2`
	font-weight: bold;
	font-size: 1.8em;
`;

export const ErrorMessage = styled.div`
	text-align: center;
	font-size: 1rem;
	color: ${({ theme }) => theme.red};
`;

export const Row = styled.div`
	text-align: center;
`;

export const OptionRow = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.6rem;
`;

