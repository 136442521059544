import styled from 'styled-components';

export const Banner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img`
  /* height: ${(props) => (!props.isMobile ? '171px' : '82px')}; */
  border-radius: 0.7rem;
  cursor: pointer;
  width: 100%;
`;
