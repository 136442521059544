import * as S from './MobileSideNavComponent.styles';
import React, { useState } from 'react';
import { LeaderIcon, TncIcon } from '../../assets/images/SideNav';
import { LeaderboardIcon, TermsAndCond } from 'shared/assets/images/menu/';
import { useDispatch, useSelector } from 'react-redux';
import {
	logoutHandler,
	setAxiosInstance,
	toggleEditModeHandler,
	toggleModifyModeHandler,
	toggleSideNavHandler,
} from 'shared/store/global/globalActions';
import {
	MobileSideNavItem,
	Share,
	AppSpinner,
	WarnModal,
} from '@hook-apps-client/ui';
import PagesEnum from 'shared/Enum/pages';
import { ShareIcon } from 'shared/assets/images/menu';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useTheme } from 'styled-components';

import { LogoutIcon } from 'shared/assets/images/menu';

const MobileSideNavComponent = ({ showShare, toggleShowShare }, props) => {
	//hooks
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { device, modifyMode, axiosInstance } = useSelector(
		({ global }) => global
	);
	const langState = useSelector(({ lang }) => lang);
	const queryClient = useQueryClient();
	const theme = useTheme();

	// states
	const [shareIsLoading, setShareIsLoading] = useState(false);
	const [showWarneModal, setShowWarneModal] = useState(false);
	const [selectedAction, setSelectedAction] = useState(false);

	// constans
	const username = sessionStorage.getItem('username');
	const user = queryClient.getQueryData(['user', username])?.user;
	const pagesLang = langState?.pages;
	const inviteFriendModalTexts = langState?.modals?.share?.inviteFriend;
	const warnModalTexts = langState?.modals?.warn;
	const svgIconStyleObj = {
		width: '3rem',
		height: '2.8rem',
	};

	//functions
	const disableLinkHandler = (e, callback) => {
		dispatch(toggleSideNavHandler());
		if (modifyMode) {
			e.preventDefault();
			setShowWarneModal(true);
			setSelectedAction(() => callback);
		} else callback();
	};

	const onStayHandler = () => {
		setShowWarneModal(false);
	};

	const onLeaveHandler = () => {
		dispatch(toggleModifyModeHandler(false));
		dispatch(toggleEditModeHandler(false));
		setShowWarneModal(false);
		selectedAction();
	};

	const logOut = async () => {
		dispatch(toggleSideNavHandler());
		localStorage.setItem('jwt-token', null);
		sessionStorage.removeItem('username');
		dispatch(logoutHandler());
		queryClient.clear();
		axiosInstance.defaults.headers.common['jwt-token'] = null;
		dispatch(setAxiosInstance(axiosInstance));
		await queryClient.refetchQueries({ active: true });
		navigate(`/`);
	};

	const logOutHandler = async () => {
		if (modifyMode) {
			setShowWarneModal(true);
			setSelectedAction(() => logOut);
			dispatch(toggleSideNavHandler());
		} else logOut();
	};

	//this function invokes from the native to let us know if the postMessage was successfull
	window.receivedPostMsg = () => {
		setShareIsLoading(false);
	};

	const inviteFriendHandler = () => {
		setShareIsLoading(true);
		//TODO: Check the Post Message
		if (device) {
			device === 'ios'
				? window?.webkit?.messageHandlers?.observer?.postMessage(
						JSON.stringify({
							data: {
								title: inviteFriendModalTexts?.title,
								onelink: inviteFriendModalTexts?.urlToShare,
							},
						})
				  )
				: window.Android.postMessage(
						JSON.stringify({
							url: 'goToStore',
							data: {
								title: inviteFriendModalTexts?.title,
								onelink: inviteFriendModalTexts?.urlToShare,
							},
						})
				  );
		} else {
			setShareIsLoading(false);
			toggleShowShare();
		}
	};

	// jsx
	return (
		<>
			<div>
				{/* {!user && (
					<MobileSideNavItem
						onClick={(e) =>
							disableLinkHandler(e, () => navigate(`/${PagesEnum.login}`))
						}
					>
						<LaptopIcon style={svgIconStyleObj} fill={theme.secondary} />
						<S.Text>Account</S.Text>
					</MobileSideNavItem>
				)} */}

				{pathname.includes('bracket') && (
					<MobileSideNavItem
						onClick={(e) =>
							disableLinkHandler(e, () => navigate(`/bracket/leaderboard`))
						}
						SvgIcon={LeaderboardIcon}
						to={`/bracket/leaderboard`}
						text={'Leaderboard'}
						defaultColor={theme.secondary}
						stroke={theme.secondary}
					/>
				)}
				{!pathname.includes('bracket') && (
					<MobileSideNavItem
						onClick={(e) =>
							disableLinkHandler(e, () => navigate(`/${PagesEnum.howToPlay}`))
						}
						SvgIcon={LeaderboardIcon}
						to={`${PagesEnum.howToPlay}`}
						text={pagesLang?.howToPlay}
						defaultColor={theme.secondary}
						stroke={theme.secondary}
					/>
				)}
				{!pathname.includes('bracket') && (
					<MobileSideNavItem
						onClick={(e) =>
							disableLinkHandler(e, () => navigate(`/${PagesEnum.termsAndCond}`))
						}
						SvgIcon={TermsAndCond}
						to={`/${PagesEnum.termsAndCond}`}
						text={pagesLang?.termsAndConditions}
						defaultColor={theme.secondary}
						// stroke={theme.primary}
					/>
				)}
				<MobileSideNavItem onClick={inviteFriendHandler}>
					<ShareIcon style={svgIconStyleObj} fill={theme.secondary} />

					{shareIsLoading || queryClient.isFetching() ? (
						<AppSpinner />
					) : (
						<S.Text>{pagesLang?.inviteAfriend}</S.Text>
					)}
				</MobileSideNavItem>

				{user && (
					<MobileSideNavItem onClick={logOutHandler}>
						<LogoutIcon
							style={{ width: '2rem', marginTop: '1rem' }}
							fill={theme.secondary}
						/>
						<S.Text>Logout</S.Text>
					</MobileSideNavItem>
				)}
			</div>
			{/* //Modals */}
			{showWarneModal && (
				<WarnModal
					texts={warnModalTexts}
					open={showWarneModal}
					onStay={onStayHandler}
					onLeave={onLeaveHandler}
				/>
			)}
			{showShare && (
				<Share
					onClose={() => toggleShowShare()}
					linkUrl={inviteFriendModalTexts?.urlToShare}
					open={showShare}
					texts={inviteFriendModalTexts}
				/>
			)}
		</>
	);
};

export default React.memo(MobileSideNavComponent);
