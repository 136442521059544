import { AxiosInstance } from 'axios';
import { useQuery } from 'react-query';

type TParams = {
  campaignId: number;
  page: string;
};

export const fetchBanners = async (
  axiosInstance: AxiosInstance,
  params: TParams
) => {
  try {
    const { data } = await axiosInstance.post(`/api/get-banners`, params);
    return data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const useBanners = (axiosInstance: AxiosInstance, params: TParams) => {
  return useQuery('banners', () => fetchBanners(axiosInstance, params), {
			enabled: Boolean(axiosInstance),
			onError: (err) => console.error('[useBanners]: fetch failed ', err),
		});
};

export default useBanners;
