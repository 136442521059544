import { Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import Button from '../Button';

export const LeaderboardContainer = styled(Container)``;

export const StyledRow = styled(Row)`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 0 10px;
`;

export const PickersWrapper = styled.div`
	display: flex;
	align-items: center;
	height: 3rem;
	color: ${({ theme }) => theme.secondaryTypography};
	background-color: ${({ theme }) => theme.appSectionsBg};
	border-radius: 1.5rem;
	font-weight: 500;
	padding: 0 1.7rem;
`;

export const Picker = styled(Button)`
	width: unset;
	white-space: nowrap;
	color: ${({ theme, isSelected }) =>
		isSelected
			? theme.leaderboardPicker.activeColor
			: theme.leaderboardPicker.defaultColor};

	background-color: transparent;
	border: none;
	font-weight: inherit;
`;

export const ArrowWrapper = styled.span`
	width: 0;
	overflow: visible;
`;

export const UpDownImage = styled.div`
	/* width: 1rem;
  margin-left: 0.5rem; */
`;

export const MyRankTitle = styled.div`
	text-align: center;
	padding: 1.2rem 0;
	font-weight: 500;
	font-size: 1rem;
	color: ${({ theme }) => theme.mainTypography};
`;

export const LeaderboardTable = styled.div`
	position: relative;
	display: flex;
	flex-direction: ${(props) => (props.column ? 'column' : 'row')};
	align-items: center;
	justify-content: center;
	padding: 1rem;
	background-color: ${(props) =>
		props.bgColor ? props.bgColor : props.theme.appSectionsBg};
	border-radius: 7px;
	font-weight: 500;
	width: 100%;
	text-align: center;
`;

export const Members = styled.div`
	position: absolute;
	left: 0;
	color: ${({ theme }) => theme.thirdlyTypography};
	font-size: 0.9em;
`;
export const Season = styled.div`
	color: ${({ theme }) => theme.mainTypography};
`;

export const GreyTitle = styled.div`
	color: ${({ theme }) => theme.leaderboardTable.titles};
`;

export const StyledHr = styled.span`
	width: 100%;
	display: flex;
	flex-direction: row;
	text-align: center;
	color: ${({ theme }) => theme.black};
	&:before {
		content: '';
		flex: 1 1;
		border-bottom: 1px solid;
		border-color: ${({ theme }) => theme.leaderboardTable.titlesHr};
		margin: auto;
		margin-right: unset;
	}
	&:after {
		content: '';
		flex: 1 1;
		border-bottom: 1px solid;
		border-color: ${({ theme }) => theme.leaderboardTable.titlesHr};
		margin: auto;
		margin-left: unset;
	}
`;

export const UsersRankRow = styled(Row)`
	margin: unset;
	display: flex;
	width: 100%;
	color: ${({ theme }) => theme.leaderboardTable.content};
	padding: ${(props) => {
		if (props.$isFirst) {
			return '0 0 1rem 0';
		} else if (props.$isLast) {
			return '1rem 0 0';
		}
		return '1rem 0';
	}};
	border-bottom: ${(props) => (!props.$isLast ? '1px solid' : '')};
	border-color: ${({ theme }) => theme.leaderboardTable.contentHr};
`;

export const RankAndMedal = styled.div`
	display: flex;
	color: ${({ theme }) => theme.mainTypography};
	justify-content: space-between;
`;
export const Text = styled.div`
	color: ${({ theme }) => theme.mainTypography};
`;
