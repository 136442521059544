import { Tab, Tabs } from '@mui/material';
import styled from 'styled-components';

export const HeaderTabs = styled(Tabs)`
	background-color: ${({ theme }) => theme.desktopHeader.backgroundColor};
	font-weight: 550;
	font-size: 0.9em;
	.MuiTabs-scrollButtons.Mui-disabled {
		opacity: 0.3;
		// transition: width 0.1s ease-out;
	}
	// .MuiTabs-scrollButtons.Mui-enabled {
	// 	width: 10px;
	// 	transition: width 0.2s ease-in;
	.MuiTabs-scrollButtons {
		margin: unset;
		padding: unset;
		// width: 10px;
	}
	& .Mui-selected {
		color: ${({ theme }) => theme.mobileHeader.headerTabs.activeColor} !important;
	}
`;

export const HeaderTab = styled(Tab)`
	font-size: ${({ $isMobile }) => ($isMobile ? '0.8em' : '1em')} !important;
	text-decoration: none !important;
	&:hover {
		cursor: pointer;
		color: ${({ theme }) => theme.mobileHeader.headerTabs.activeColor} !important;
	}

	// inactive tab
	/* &.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
		color: ${({ theme }) => theme.secondaryTypography};
	} */
	// PARLAYES HEADER TAB
	&.MuiButtonBase-root .MuiTab-root {
		color: ${({ theme }) => theme.secondaryTypography};
	}
`;
