import { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setHeader } from 'shared/store/global/globalActions';
import * as S from './TnCWrapper.styles';

export const TnCWrapper = ({ ItemsComponent, TncTitle }) => {
	const dispatch = useDispatch();
	useLayoutEffect(() => {
		dispatch(setHeader({ hideHeader: true }));
	}, [dispatch]);
	return (
		<S.Wrapper>
			<S.Title>{TncTitle ? TncTitle : 'Terms And Conditions'}</S.Title>
			<ItemsComponent />
		</S.Wrapper>
	);
};

export default TnCWrapper;
