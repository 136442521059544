import { AxiosInstance } from 'axios';
import { useQuery } from 'react-query';

type TParams = {
    campaignId: number;
};

export const fetchLeaguePeriods = async (
    axiosInstance: AxiosInstance,
    params: TParams
) => {
    try {
        const { data } = await axiosInstance.get(`/api/get-client-league-periods`, {
            params
        });
        return data;
    } catch (err) {
        console.log(err);
        throw err;
    }
};

const useLeaguePeriods = (axiosInstance: AxiosInstance, params: TParams) => {
    return useQuery('leaguePeriods', () => fetchLeaguePeriods(axiosInstance, params),
        {
            onError: (err) => console.error("[useLeaguePeriods]: fetch failed ", err),
        });
};

export default useLeaguePeriods;
