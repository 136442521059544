import { AxiosInstance } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

type TBody = {
  roundId: string | number,
  predictions: Array<object>
};

type TParams = {
  axiosInstance?: AxiosInstance;
  body?: TBody;
  isEdit: boolean;
}

export const submitPredictions = async (
  params: TParams
) => {
  try {
    const { axiosInstance, body } = params;
    return params.isEdit ? await axiosInstance?.put(`/api/edit-predictions`, body) : await axiosInstance?.post(`/api/add-predictions`, body);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const useMutatePredictions = () => {
  const queryClient = useQueryClient()
  return useMutation(submitPredictions, {
    mutationKey: 'mutatePredictions',
    onSettled: () => {
      queryClient.refetchQueries({ active: true })
    }
  });
};

export default useMutatePredictions;
