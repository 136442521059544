import * as S from './StepperRegisteation.styles';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import Button from '../../../Button';
import Typography from '@mui/material/Typography';
import FirstStep from './steps/FirstStep';
import SecondStep from './steps/SecondStep';
import ThirdStep from './steps/ThirdStep';
import { useRef, useState } from 'react';
import { AppSpinner } from '@hook-apps-client/ui';

const steps = ['Account Details', 'Contact Dedails', 'Account Created'];

const StepperRegisteation = ({
	activeStep,
	handleNext,
	handleBack,
	isLoading,
	errorMessage,
}) => {
	const [stepperProps, setStepperProps] = useState({}); // to control completed prop
	const getStepContent = (step) => {
		switch (step) {
			case 0:
				return <FirstStep />;
			case 1:
				return <SecondStep />;
			case 2:
				return (
					<ThirdStep setStepperProps={setStepperProps} handleBack={handleBack} />
				);
			default:
				throw new Error('Unknown step');
		}
	};

	return (
		<S.RootContainer>
			<S.RegistrationStepper alternativeLabel id="stepp" activeStep={activeStep}>
				{steps.map((label) => (
					<Step id="step" key={label} {...stepperProps}>
						<S.RegStepLabel>
							<S.StepperLabelText>{label}</S.StepperLabelText>
						</S.RegStepLabel>
					</Step>
				))}
			</S.RegistrationStepper>
			{getStepContent(activeStep)}
			{statusIndicator(errorMessage, isLoading)}
			{activeStep !== 2 ? (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'flex-end',
						marginTop: errorMessage || isLoading ? 'unset' : '20px',
					}}
				>
					<Button
						variant="contained"
						type="submit"
						width={'100%'}
						sx={{ mt: 3, ml: 1, marginRight: '600px' }}
					>
						{activeStep > 0 ? 'CREATE NEW ACCOUNT' : 'NEXT'}
					</Button>
				</Box>
			) : null}
		</S.RootContainer>
	);
};

const statusIndicator = (errorMessage, isLoading) => {
	if (isLoading) {
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					padding: '10px 0',
				}}
			>
				<AppSpinner />
			</div>
		);
	}

	if (errorMessage) {
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<div
					style={{
						margin: '0.5rem 0',
						color: 'red',
						width: '85%',
						textAlign: 'center',
					}}
				>
					* {errorMessage}
				</div>
			</div>
		);
	}

	return null;
};

export default StepperRegisteation;
