import * as S from './DisclaimerModal.styles';
import { useWindowResize } from 'shared/hooks';
import { Backdrop, Fade, Modal } from '@mui/material';
import ReactPortal from '../../ReactPortal/ReactPortal';

export const DisclaimerModal = ({ isOpen, setIsOpen }) => {
	// hooks
	const { isMobile } = useWindowResize();
	return (
		<ReactPortal wrapperId="nickname-modal">
			<Modal
				disablePortal
				open={isOpen}
				// onClose={() => setIsOpen(false)}
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={isOpen}>
					<S.Paper $isMobile={isMobile}>
						<S.Title>NOTIFICACIÓN</S.Title>
						<S.Description>
							Según la normativa, la obtención de premios será exclusiva para usuarios
							registrados en VERSUS desde hace más de 30 días, con la cuenta
							verificada, sin retiradas pendientes, aumento de límites en un periodo
							menor a 30 días ni usuarios clasificados como “jugador intensivo”. El
							resto de los participantes lo harán únicamente de forma recreacional.
						</S.Description>
						<S.SubmitBtn onClick={() => setIsOpen(false)}>Aceptar</S.SubmitBtn>
					</S.Paper>
				</Fade>
			</Modal>
		</ReactPortal>
	);
};

export default DisclaimerModal;
