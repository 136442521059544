import * as S from './WarnModal.styles';
import ReactPortal from '../../ReactPortal/ReactPortal';
import { Fade, Modal, Backdrop } from '@mui/material';

export const WarnModal = ({ open, onStay, onLeave, texts }) => {
	return (
		<ReactPortal wrapperId={'warn-modal'}>
			<Modal
				disablePortal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
				open={open}
				onClose={onStay}
			>
				<Fade in={open}>
					<S.Paper>
						<S.Title> {texts?.title}</S.Title>
						<S.Description>{texts?.description}</S.Description>
						<S.Row>
							<S.ModalBtn onClick={onLeave} title={texts?.leaveBtn} />
							<S.ModalBtn onClick={onStay} title={texts?.stayBtn} />
						</S.Row>
					</S.Paper>
				</Fade>
			</Modal>
		</ReactPortal>
	);
};

export default WarnModal;
