import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
`;

export const ResultsDetailsHeader = styled.header`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1.2rem 0.6rem;
	background-color: ${({ theme, $isMobile }) =>
		$isMobile
			? theme.resultsDetailsHeader.backgroundColorMobile
			: theme.resultsDetailsHeader.backgroundColor};
`;

export const RoundName = styled.div`
	font-weight: 500;
	color: ${({ theme, $isMobile }) =>
		$isMobile
			? theme.resultsDetailsHeader.titleMobile
			: theme.resultsDetailsHeader.title};
`;

export const RoundDate = styled.div`
	font-weight: 500;
	font-size: 1em;
	color: ${({ theme, $isMobile }) =>
		$isMobile
			? theme.resultsDetailsHeader.descriptionMobile
			: theme.resultsDetailsHeader.description};
`;

export const StyledLink = styled(Link)`
	position: absolute;
	left: 0.6rem;
	/* ${(props) => props.$isMobile && { position: 'absolute', left: '10px' }}; */
`;

export const HowToPlayWrapper = styled.div`
	position: absolute;
	right: 0.6rem;
`;

export const Indicator = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${({ theme }) => theme.resultsDetailsIndicatorBg};
	border: ${({ theme }) => `${theme.appBg} 1px solid`};
`;
export const LeaderBoardBtnContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 30px 0 50px;
`;
